import makeStyles from '@mui/styles/makeStyles';
import theme from '../../theme';
import { isMobile } from '../../utils/device';

const signinUseStyles = makeStyles({
  root: {
    background:
      'linear-gradient(50deg, rgba(11,11,71,1) 0%, rgba(44,29,131,1) 200%)',
    width: '100%',
    height: '100dvh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  brfBackground: {
    background:
      'linear-gradient(200deg, rgba(116,43,99,1) 0%, rgba(202,40,51,1) 85%)'
  },
  mainButton: {
    textDecoration: 'none',
    fontWeight: 700,
    height: 48,
    fontSize: 16,
    backgroundColor: '#E9F2FC',
    color: theme.palette.primary.main,
    transition: 'opacity 500ms',
    '&:hover': {
      backgroundColor: '#E9F2FC'
    }
  },
  loginPaper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: isMobile ? 0 : 32,
    maxHeight: '100%',
    maxWidth: isMobile ? undefined : 375,
    height: isMobile ? '100%' : 'fit-content',
    padding: isMobile ? 32 : '48px 32px',
    backgroundColor: isMobile ? 'transparent' : '#00000040',
    marginBottom: isMobile ? '0' : '16dvh'
  },
  loginPaperCustom: {
    justifyContent: 'center',
    gap: 40,
    height: isMobile ? '100%' : 'fit-content',
    minHeight: 0
  },
  lowerDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16
  },
  enterButton: {
    color: 'white',
    borderColor: 'white',
    padding: '16px 32px',
    width: isMobile ? '100%' : '70%',
    maxWidth: isMobile ? 600 : 225,
    '&:hover': {
      borderColor: 'white',
      backgroundColor: '#ffffff26'
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    borderRadius: 0
  },
  textField: {
    borderRadius: 5,
    backgroundColor: '#E9F2FC',
    width: '100%',
    height: 46,
    padding: 16,
    paddingRight: 8,
    '&:focus-within': {
      boxShadow: `0 0 0 50px ${theme.palette.background.default} inset`,
      '& input:-webkit-autofill': {
        boxShadow: `0 0 0 50px ${theme.palette.background.default} inset`
      }
    },
    '& input': {
      fontSize: 16
    },
    '& input:-webkit-autofill': {
      boxShadow: '0 0 0 50px #E9F2FC inset',
      '&::focus': {
        boxShadow: `0 0 0 50px ${theme.palette.background.default} inset`
      }
    }
  },
  textFieldError: {
    boxShadow: `0 0 0 2px ${theme.palette.error.main}`,
    backgroundColor: '#fff2f2',
    '& input:-webkit-autofill': {
      boxShadow: '0 0 0 50px #fff2f2 inset'
    }
  },
  inputDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: 0,
    gap: 16,
    width: '100%'
  },
  header5: {
    fontSize: 24,
    lineHeight: '28.13px',
    fontWeight: 700,
    fontFamily: 'Roboto'
  },
  messageDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    height: '100%',
    marginBottom: 20
  },
  informativeText: {
    fontSize: 14,
    lineHeight: '16.41px',
    fontWeight: 400
  },
  localDivider: {
    borderColor: 'rgba(255,255,255,0.5)',
    flexGrow: 2
  },
  orDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  mainLogo: {
    width: '75%',
    filter: 'drop-shadow(0px 0px 12px #00000070)'
  },
  companyBannerDiv: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 16,
    color: 'white',
    justifyContent: 'center'
  },
  centerDiv: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 24
  },
  companyBannerCustom: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  companyBannerDefault: {
    padding: 8,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    paddingBottom: 32
  },
  companyBannerSso: {
    padding: 8,
    marginBottom: 96
  },
  companyBannerCustomSso: {
    marginTop: 32
  },
  buttonBotomDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
    height: '100%',
    paddingTop: 40
  },
  loginIcon: {
    position: 'absolute',
    right: 16,
    top: 12
  },
  forgotButton: {
    color: '#fff',
    fontWeight: 400,
    fontSize: 12,
    padding: 0
  },
  backDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    paddingTop: 40,
    justifyContent: 'flex-end'
  },
  portabilityButton: {
    color: '#fff',
    borderColor: '#fff',
    textDecoration: 'none',
    height: 48,
    width: '100%',
    fontSize: 16,
    transition: 'opacity 500ms',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  }
});
export default signinUseStyles;
