import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import launchScreenStyles from './styles';
import VorotechLogo from '../../images/logo.svg';

interface LaunchScreenProps {
  variant?: 'vanish' | 'pulse';
  disableBackdrop?: boolean;
}

export default function LaunchScreen(props: LaunchScreenProps) {
  /* Props */
  const animationType = props?.variant ?? 'vanish';
  const disableBackdrop = props?.disableBackdrop;
  /* Styles */
  const localClasses = launchScreenStyles();
  /* States */
  const [play, setPlay] = useState<boolean>(false);
  const [unmount, setUnmount] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<boolean>(false);
  /* Hooks */
  const splashRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  /* Consts */
  const animationClasses =
    animationType === 'vanish'
      ? {
          fade: localClasses.circleFade,
          logo: localClasses.logoVanish
        }
      : {
          fade: localClasses.circleFade,
          logo: localClasses.logoPulse
        };
  document.onreadystatechange = () => {
    if (visibility && document.readyState === 'complete') setPlay(true);
  };
  /* Effects */
  useEffect(() => {
    const appOpened = sessionStorage.getItem('appOpened');
    if (!appOpened) {
      setVisibility(true);
      sessionStorage.setItem('appOpened', 'true');
    }
    // Unmounts the launch screen if the animation gets stuck for some reason
    setTimeout(() => {
      setUnmount(true);
    }, 3000);
  }, []);
  useEffect(() => {
    /* Check if refs do exist */
    if (!wrapperRef.current || !splashRef.current) return;
    /* Event listeners */
    splashRef.current.addEventListener('animationstart', event => {
      // Starts to fade the backdrop
      if (wrapperRef.current && !disableBackdrop)
        wrapperRef.current.style.background = 'transparent';
    });
    wrapperRef.current.addEventListener('transitionend', event => {
      // "Unmounts" the component after the backdrop transition
      if (wrapperRef.current) wrapperRef.current.style.zIndex = '-1';
      setUnmount(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, splashRef]);

  return !unmount ? (
    <div
      className={localClasses.splashSuper}
      style={{ visibility: visibility ? 'visible' : 'hidden' }}
    >
      <div
        className={clsx(
          localClasses.splashWrapper,
          disableBackdrop && localClasses.noBackDrop
        )}
        ref={wrapperRef}
      >
        <div
          className={clsx(localClasses.splash, play && animationClasses.fade)}
          ref={splashRef}
        >
          <img
            src={VorotechLogo}
            alt="Logo"
            className={clsx(localClasses.logo, play && animationClasses.logo)}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
