import makeStyles from '@mui/styles/makeStyles';
import theme from '../../theme';
import { isMobile } from '../../utils/device';

const signinUseStyles = makeStyles({
  root: {
    background:
      'linear-gradient(50deg, rgba(11,11,71,1) 0%, rgba(44,29,131,1) 200%)',
    width: '100%',
    height: '100dvh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainButton: {
    textDecoration: 'none',
    fontWeight: 700,
    height: 48,
    fontSize: 20,
    backgroundColor: '#E9F2FC',
    color: theme.palette.primary.main,
    transition: 'opacity 500ms',
    '&:hover': {
      backgroundColor: '#E9F2FC'
    }
  },
  linkPasteDiv: {
    backgroundColor: 'transparent',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 5,
    border: '1px solid rgba(255,255,255,0.5)',
    padding: 0,
    alignItems: 'center'
  },
  loginPaper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: isMobile ? 0 : 8,
    maxHeight: '100%',
    maxWidth: isMobile ? undefined : 375,
    height: isMobile ? '100%' : 'fit-content',
    padding: isMobile ? 32 : '48px 32px',
    backgroundColor: isMobile ? 'transparent' : '#00000040',
    marginBottom: isMobile ? '0' : '16dvh'
  },
  pasteButton: {
    borderRadius: '5px 0px 0px 5px',
    padding: '4px 16px',
    width: 'fit-content',
    minWidth: 30,
    boxShadow: 'none',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      boxShadow: 'none'
    }
  },
  textField: {
    borderRadius: '0px 5px 5px 0px',
    backgroundColor: '#E9F2FC',
    width: '100%',
    height: 34,
    padding: 8,
    '&:focus-within': {
      boxShadow: `0 0 0 50px ${theme.palette.background.default} inset`,
      '& input:-webkit-autofill': {
        boxShadow: `0 0 0 50px ${theme.palette.background.default} inset`
      }
    },
    '& input': {
      fontSize: 14
    },
    '& input:-webkit-autofill': {
      boxShadow: '0 0 0 50px #E9F2FC inset',
      '&::focus': {
        boxShadow: `0 0 0 50px ${theme.palette.background.default} inset`
      }
    }
  },
  textFieldError: {
    boxShadow: `0 0 0 2px ${theme.palette.error.main}`,
    backgroundColor: '#fff2f2',
    '& input:-webkit-autofill': {
      boxShadow: '0 0 0 50px #fff2f2 inset'
    }
  },
  inputDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 0,
    gap: 16,
    width: '100%'
  },
  companyBannerDiv: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 16,
    color: 'white',
    justifyContent: 'center'
  },
  centerDiv: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 24
  },
  companyBannerCustom: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  companyBannerDefault: {
    padding: 8,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    paddingBottom: 32
  },
  companyBannerSso: {
    padding: 8,
    marginBottom: 96
  },
  companyBannerCustomSso: {
    marginTop: 32
  },
  buttonBotomDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
    height: '100%',
    paddingTop: 40
  },
  loginIcon: {
    position: 'absolute',
    right: 16,
    top: 12,
    fontSize: 24
  },
  backDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    paddingTop: 40,
    justifyContent: 'flex-end'
  },
  portabilityButton: {
    color: '#fff',
    borderColor: '#fff',
    textDecoration: 'none',
    height: 48,
    width: '100%',
    fontSize: 16,
    transition: 'opacity 500ms',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  }
});
export default signinUseStyles;
