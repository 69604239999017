import { makeStyles } from '@mui/styles';
import theme from '../../theme';

export const navbarHeights = {
  toolbar: 64,
  offlineAlert: 32
};

const navBarStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    padding: 0,
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,0.05)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  alert: {
    backgroundColor: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8,
    color: 'white',
    width: '100%',
    padding: '0px 8px',
    height: navbarHeights.offlineAlert
  },
  alertDivPadding: {
    width: '100%',
    height: 32
  },
  menuButton: {
    fontWeight: 'bold',
    color: theme.palette.common.white,
    height: 44,
    minWidth: 0,
    width: 44,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  closeButton: {
    fontWeight: 'bold',
    padding: 0,
    color: 'inherit',
    opacity: '50%'
  },
  backButton: {
    fontWeight: 'bold',
    color: 'inherit',
    height: 32,
    width: 32,
    borderRadius: 5,
    padding: 0
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 6
  },
  toolBar: {
    height: navbarHeights.toolbar,
    padding: '10px 18px 10px 18px',
    gap: 8,
    maxWidth: '600px',
    justifySelf: 'center',
    flexGrow: 2,
    width: '100%'
  }
}));

export default navBarStyles;
