import { makeStyles } from '@mui/styles';

const faStatusStyles = makeStyles(() => ({
  banner: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    padding: '20px 10px 20px 10px',
    borderRadius: 5,
    backgroundColor: 'rgba(255,255,255,0.5)'
  },
  '@keyframes spinning': {
    '0%': {
      transform: 'rotate(0deg) scaleX(-1)'
    },
    '50%': {
      transform: 'rotate(180deg) scaleX(-1)'
    },
    '100%': {
      transform: 'rotate(360deg) scaleX(-1)'
    }
  },
  '@keyframes pulsing': {
    '0%': {
      opacity: '100%'
    },
    '10%': {
      opacity: '100%'
    },
    '70%': {
      opacity: '0%'
    },
    '100%': {
      opacity: '100%'
    }
  },
  synchingIcon: {
    animation: '$pulsing 2250ms ease-in both infinite'
  },
  ongoingIcon: {
    color: '#C69D0E',
    animation: '$spinning 10s linear both infinite'
  },
  okIcon: {
    color: '#049153'
  },
  unknownIcon: {
    color: 'rgba(0,0,0,0.2)'
  },
  cancelledIcon: {
    color: '#AA4040'
  },
  nonExecutionIcon: {
    color: '#AA4040'
  },
  warningIcon: {
    color: '#C69D0E'
  },
  pausedBanner: {
    backgroundColor: '#C69D0E',
    flexWrap: 'wrap'
  }
}));

export default faStatusStyles;
