/**
 * Returns a promise with a timeout
 * @param promise any promise
 * @param timeout in milisseconds
 * @param promiseAlias an alias to show in the timeout error message
 * @returns a promise with a timeout
 * @author Douglas Flores
 */
export function timeoutPromise(
  promise: Promise<any>,
  timeout: number,
  promiseAlias?: string
) {
  // Timeout promise
  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(
      () => reject(new Error(`${promiseAlias ?? 'A'} promise timed out`)),
      timeout
    )
  );

  return Promise.race([promise, timeoutPromise]);
}
