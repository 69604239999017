/* Global imports */
import React, { useEffect, useState } from 'react';
import { Dialog, IconButton, Grid } from '@mui/material';
/* Icon imports */
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/Close';
/* Local imports */
import useStyles from '../../useStyles';
import theme from '../../theme';
import Camera, { CameraResponse } from '../Camera';

type FormCameraProps = {
  open: boolean;
  onChange?: (x: CameraResponse) => void;
  onClose?: () => void;
  startInSelfieMode?: boolean;
  silhouette?: boolean;
  testingMode?: boolean;
};

export default function FormCamera(props: FormCameraProps) {
  const classes = useStyles(theme);
  const { open } = props;
  const [currResponse, setCurrResponse] = useState<CameraResponse>();
  const [response, setResponse] = useState<CameraResponse>();
  /* Functions */
  const onChange = (x: CameraResponse) => props?.onChange && props.onChange(x);
  const close = () => props?.onClose && props.onClose();
  const accept = () => {
    setResponse(currResponse);
    close();
  };
  /* Use effects */
  useEffect(() => {
    if (!!response) onChange(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (open) setCurrResponse(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <React.Fragment>
      <Dialog open={open} fullScreen={true}>
        {currResponse?.base64 ? (
          <div className={classes.camBackground}>
            <div className={classes.camViewport}>
              <img
                src={currResponse?.base64}
                alt="preview"
                style={{ maxHeight: '100%', maxWidth: '100%' }}
              />
            </div>

            <Grid container style={{ height: 150 }}>
              <Grid item xs={6} style={{ display: 'flex' }}>
                <IconButton
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    margin: 'auto'
                  }}
                  id="btn-cam-reject"
                  onClick={() => setCurrResponse(undefined)}
                >
                  <CloseIcon style={{ fontSize: 40 }} />
                </IconButton>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                <IconButton
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    margin: 'auto'
                  }}
                  id="btn-cam-accept"
                  onClick={accept}
                >
                  <CheckRoundedIcon style={{ fontSize: 40 }} />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Camera
            onChange={val => {
              if (!props?.testingMode) setCurrResponse(val);
              else setResponse(val);
            }}
            onClose={close}
            startInSelfieMode={!!props?.startInSelfieMode}
            silhouette={!!props?.silhouette}
            testingMode={props?.testingMode}
          />
        )}
      </Dialog>
    </React.Fragment>
  );
}
