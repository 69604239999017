import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const pullToRefreshStyles = makeStyles(() => ({
  pullDownContainer: {
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    position: 'relative'
  },
  pullDownContent: {
    position: 'absolute',
    bottom: 36,
    height: 36,
    width: 36,
    left: 'calc(50% - 18px)',
    filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
    padding: 4,
    borderRadius: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pullDownHeader: {
    width: '100%',
    height: 0,
    overflow: 'hidden',
    position: 'fixed',
    backgroundColor: 'transparent',
    top: 0,
    zIndex: 2050
  },
  pullDownLayer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -128,
    filter: 'blur(1px)',
    backgroundColor: theme.palette.primary.main,
    visibility: 'hidden',
    touchAction: 'none'
  },
  pullDownContentIcon: {
    opacity: 0,
    display: 'inherit'
  },
  refreshIcon: {
    fontSize: 28,
    color: theme.palette.primary.light
  }
}));

export default pullToRefreshStyles;
