export function formatCPF(cpf: string) {
  // clamping undesired characters
  cpf = cpf.replace(/[^\d]/g, "");

  let len = cpf.length;

  // trunkate string if the input already have 11 digits
  if (len > 11) cpf = cpf.substring(0, 11);
  // formating...
  if (len > 3 && len <= 6) cpf = cpf.replace(/(\d{3})(\d{1})/, "$1.$2");
  else if (len > 6 && len <= 9) cpf = cpf.replace(/(\d{3})(\d{3})(\d{1})/, "$1.$2.$3");
  else if (len > 9 && len < 11) cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4")
  else cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")

  return cpf;
}

export function validateCPF(cpf: string){
  // clamping undesired characters
  cpf = cpf.replace(/[^\d]/g, "");
  
  if (cpf.length < 1) return 'O campo CPF é obrigatório';
  if (cpf.length !== 11) return 'O campo CPF deve possuir 11 dígitos';

  let digitsstr = Array.from(cpf);
  let digits = digitsstr.map((char) => {
    return parseInt(char);
  });

  // validating sequence of characters
  if (digitsstr.every(char => char === cpf[0])) return 'CPF inválido';

  // validating the first digit
  let acum = 0;
  digits.forEach((digit, index) => {
    if (index < 9)
      acum += digit * (10 - index) 
  });
  let module = (acum * 10) % 11;
  if (module === 10) module = 0;
  if (module !== digits[9]) return 'CPF inválido';

  // validating the second digit
  acum = 0;
  digits.forEach((digit, index) => {
    if (index < 10)
      acum += digit * (11 - index);
  });
  module = (acum * 10) % 11;
  if (module === 10) module = 0;
  if (module !== digits[10]) return 'CPF inválido';

  return 'success';
}