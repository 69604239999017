import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ScreenRotationRoundedIcon from '@mui/icons-material/ScreenRotationRounded';
import useBioSignatureStyles from './styles';
import { svg2png, svg2uri } from '../../services/svg';
import SVGCanvas from '../SVGCanvas';

export type BioSignatureProps = {
  width: number;
  height: number;
  onSubmit: (x: string | null) => void;
  saveFormat?: 'svg' | 'png';
};

export default function BioSignature({
  width,
  height,
  saveFormat = 'svg',
  onSubmit
}: BioSignatureProps) {
  const localClasses = useBioSignatureStyles();
  /* States */
  const [value, setValue] = useState<SVGSVGElement | null>(null);
  const [clearFlag, setClearFlag] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  /* Functions */
  const exitFullscreen = async () => {
    // Exit fullscreen
    if (!!document.fullscreenElement) {
      // Unlocking orientation
      window.screen.orientation.unlock();
      // Exiting fullscreen
      await document.exitFullscreen();
    }
  };
  const onConfirmClick = async () => {
    await exitFullscreen();
    if (saveFormat === 'svg') {
      const uriSvg = !!value ? svg2uri(value) : null;
      onSubmit(uriSvg);
    } else if (saveFormat === 'png') {
      const pngBase64 = await svg2png(value, 1);
      onSubmit(pngBase64);
    }
  };

  if (width < 10) return null;

  return (
    <div className={localClasses.main}>
      <div className={localClasses.orientationMessage}>
        <ScreenRotationRoundedIcon />
        <Typography variant="body1">
          Você pode virar seu dispositivo na horizontal para aumentar o campo de
          assinatura.
        </Typography>
      </div>
      <div className={localClasses.signContainer}>
        <SVGCanvas
          width={width}
          height={height}
          onChange={val => setValue(val)}
          clearFlag={clearFlag}
          setClearFlag={setClearFlag}
          setIsEmpty={val => setIsEmpty(val)}
        />
      </div>
      <div className={localClasses.buttonContainer}>
        <Button
          style={{ maxWidth: 160 }}
          onClick={() => setClearFlag(true)}
          disabled={clearFlag}
          startIcon={<DeleteRoundedIcon />}
        >
          Limpar
        </Button>
        <Button
          id="btn-save-signature"
          variant="contained"
          style={{ maxWidth: 180 }}
          onClick={onConfirmClick}
          startIcon={<SaveRoundedIcon />}
          disabled={isEmpty}
        >
          Salvar Assinatura
        </Button>
      </div>
    </div>
  );
}
