import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useCanvasStyles = makeStyles(() => ({
  drag: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    border: '1px solid',
    borderColor: theme.palette.primary.dark,
    borderRadius: 10
  },
  svg: {
    margin: 0,
    cursor: 'crosshair'
  },
}));

export default useCanvasStyles;