import { useEffect, useState } from 'react';
import { ulid } from 'ulid';
import db, { MutationCache } from '../services/db';
import { publish, subscribe, unsubscribe } from '../services/events';
import {
  BaseMutateOptions,
  MutationKey,
  UseMutationResult,
  UseMutationResultState,
  UseVoroMutationOptions
} from '../types/voromutation';
import { MutationEvent } from '../services/voroQueryClient';
import { VoroMutationStatus } from '../../vorotypes/types/voromutation';

/**
 * Main hook for mutations
 * @param options see type UseVoroMutationOptions for more details
 * @returns a hook to make mutations
 * @author Douglas Flores
 */
export function useVoroMutation<TData = unknown, TVariables = void>(
  options: UseVoroMutationOptions<TData, TVariables>
): UseMutationResult<TData, TVariables> {
  const [mounted, setMounted] = useState<boolean>(true);

  function reset() {
    setMutationResult(prev => Object({ ...prev, status: 'idle' }));
  }
  // TODO: see if it's needed to return this function as a useCallback
  async function mutate(
    variables: TVariables,
    mutateOptions?: BaseMutateOptions<TData>
  ) {
    let global_error, data;
    const mutation_ulid = ulid();
    const mutationKey = [mutation_ulid, ...options.mutationKey];
    try {
      if (!mounted) throw Error('Component unmounted!');
      setMutationResult({ ...mutationResult, status: 'mutating' });
      await db.mutationCache.put({
        key: mutationKey,
        data: variables,
        status: 'mutating',
        retry: 0
      });
      const mutationres = await options.mutationFn(variables);
      /* --- onSuccess --- */
      data = mutationres;
      await db.mutationCache.update(mutationKey, {
        status: 'localSuccess'
      });
      if (!mounted) throw Error('Component unmounted!');
      setMutationResult({ ...mutationResult, status: 'localSuccess' });
      publish('mutationpost');
      if (options?.onSuccess) options.onSuccess(mutationres, variables);
      if (mutateOptions?.onSuccess) mutateOptions.onSuccess(mutationres);
    } catch (error) {
      global_error = error;
      console.error(error);
      try {
        await db.mutationCache.update(mutationKey, {
          status: 'localError'
        });
        if (!mounted) throw Error('Component unmounted!');
        setMutationResult({ ...mutationResult, status: 'localError' });
        publish<MutationEvent>('mutationcacheupdated');
      } catch (error) {
        console.log(error);
      } finally {
        if (!!options?.onError) options.onError(error, variables);
        if (!!mutateOptions?.onError) mutateOptions.onError(error);
      }
    } finally {
      if (!!options?.onSettled)
        options.onSettled(data, global_error, variables);
      if (!!mutateOptions?.onSettled)
        mutateOptions.onSettled(data, global_error);
    }
  }
  /**
   * @deprecated used only for addMeasurement
   * @param variables data to be sent to the api
   * @returns a Promise with the mutationFn's result
   */
  async function mutateAsync(variables: TVariables): Promise<TData> {
    const mutation_ulid = ulid();
    const mutationKey = [mutation_ulid, ...options.mutationKey];
    try {
      setMutationResult({ ...mutationResult, status: 'mutating' });
      await db.mutationCache.put({
        key: mutationKey,
        data: variables,
        status: 'mutating',
        retry: 0
      });
      const mutationres = await options.mutationFn(variables);
      /* --- onSuccess --- */
      await db.mutationCache.update(mutationKey, {
        status: 'localSuccess'
      });
      setMutationResult({ ...mutationResult, status: 'localSuccess' });
      publish('mutationpost');
      return mutationres;
    } catch (error) {
      await db.mutationCache.update(mutationKey, {
        status: 'localError'
      });
      publish<MutationEvent>('mutationcacheupdated');
      setMutationResult({ ...mutationResult, status: 'localError' });
      throw error;
    }
  }

  // The use mutation result object
  const [mutationResult, setMutationResult] = useState<
    UseMutationResultState<TData, TVariables>
  >({
    mutate,
    mutateAsync,
    status: 'idle',
    reset
  });

  // effect used mainly to cleanup and avoid memory leaks
  useEffect(() => {
    return () => {
      setMounted(false);
      setMutationResult({ mutate, mutateAsync, status: 'idle', reset });
    };
    // eslint-disable-next-line
  }, []);

  /* Always returns the response object with extra metadata */
  return {
    ...mutationResult,
    isIdle: mutationResult.status === 'idle',
    isMutating: mutationResult.status === 'mutating',
    isSuccess: mutationResult.status === 'localSuccess',
    isError: mutationResult.status === 'localError'
  };
}

/**
 * Hook that returns the mutations awaiting to be pushed to the API
 * @param mutationKey the mutation key to observe
 * @returns a list of mutations awaiting to be pushed to the API
 * @author Douglas Flores
 */
export function useIsAwaitingToSync(mutationKey: MutationKey) {
  const [mounted, setMounted] = useState<boolean>(true); // used to avoid setting states after the component is unmounted
  const [response, setResponse] = useState<MutationCache[] | null>();
  /* function that updates the response object */
  async function update() {
    try {
      const mutations = await db.mutationCache
        .where('status')
        .anyOf(['localSuccess', 'pushError', 'paused'])
        .and(x => mutationKey.every(y => x.key.includes(y)))
        .toArray();
      if (mounted) setResponse(mutations);
    } catch (error) {
      console.error(error);
      if (mounted) setResponse(null);
    }
  }
  /* subscribing to events */
  useEffect(() => {
    update();
    subscribe<MutationEvent>('mutationpost', update);
    subscribe<MutationEvent>('mutationssynched', update);
    subscribe<MutationEvent>('mutationcacheupdated', update);
    return () => {
      setMounted(false);
      unsubscribe<MutationEvent>('mutationpost', update);
      unsubscribe<MutationEvent>('mutationssynched', update);
      unsubscribe<MutationEvent>('mutationcacheupdated', update);
      setResponse([]);
    };
    // eslint-disable-next-line
  }, []);

  return response;
}

/**
 * Gets mutation data relative to a mutation key and updates it's response
 * each time the mutation cache is changed
 * @param mutationKey the target mutation key
 * @returns mutation cache's data filtered by key
 */
export function useMutationCache(
  mutationKey?: MutationKey,
  mutationFilter?: (x: any) => boolean
) {
  const [mounted, setMounted] = useState<boolean>(true);
  const [mutations, setMutations] = useState<
    MutationCache[] | null | undefined
  >(null);

  /**
   * Counts mutations on cache.
   * @param status is set to filter the mutations by status before counting
   * @returns the number of mutations in cache filtered by status or not
   * @author Douglas Flores
   */
  function count(status?: VoroMutationStatus) {
    if (!!status)
      return mutations?.filter(mt => mt.status === status)?.length ?? 0;
    else return mutations?.length ?? 0;
  }
  /**
   * Removes a mutation from mutationCache
   * @param mutationKey the target mutation's key
   * @returns a promise of Dexie delete
   * @author Douglas Flores
   */
  async function remove(mutationKey: MutationKey) {
    return db.mutationCache
      .delete(mutationKey)
      .then(_ => publish<MutationEvent>('mutationcacheupdated'));
  }
  /**
   * Used to update the mutations state from this hook
   * @author Douglas Flores
   */
  async function update() {
    try {
      const dbres = await db.mutationCache.toArray();
      const prefilter = !!mutationFilter ? dbres.filter(mutationFilter) : dbres;
      if (!!mutationKey) {
        const filteredMutations = prefilter.filter(x =>
          mutationKey.every(y => x.key.includes(y))
        );
        if (mounted) setMutations(filteredMutations ?? []);
      } else if (mounted) {
        setMutations(prefilter ?? []);
      }
    } catch (error) {
      console.error(error);
      if (mounted) setMutations(undefined);
    }
  }
  /* subscribing to events */
  useEffect(() => {
    setMounted(true);
    update();
    subscribe<MutationEvent>('mutationpost', update);
    subscribe<MutationEvent>('mutationssynched', update);
    subscribe<MutationEvent>('mutationcacheupdated', update);
    return () => {
      // Cleaning up
      setMounted(false);
      unsubscribe<MutationEvent>('mutationpost', update);
      unsubscribe<MutationEvent>('mutationssynched', update);
      unsubscribe<MutationEvent>('mutationcacheupdated', update);
      setMutations(null);
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (mutations === null) update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutations]);

  return {
    data: mutations ?? [],
    isReady: !!mutations,
    isError: mutations === undefined,
    isLoading: mutations === null,
    count,
    remove
  };
}
