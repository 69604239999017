import Resizer from 'react-image-file-resizer';
import { fileToBase64, mimeToExtension, urlToFile } from './files';
import { MimeType } from '../../vorotypes/types/utils';

/**
 * Resizes a blob image
 * @param file a blob of the image to be resized
 * @param max_width maximum width of the converted image
 * @param max_height maximum height of the converted image
 * @returns a Promise of the resized image
 */
export function imgResizer(
  file: Blob,
  max_width = 800,
  max_height = 600
): Promise<Blob | File> {
  const promise = new Promise<Blob | File>((resolve, reject) => {
    Resizer.imageFileResizer(
      file, // Is the file of the image which will resized.
      max_width, // Is the maxWidth of the resized new image.
      max_height, // Is the maxHeight of the resized new image.
      'JPEG', // Is the compressFormat of the resized new image.
      80, // Is the quality of the resized new image.
      0, // Is the degree of clockwise rotation to apply to uploaded image.
      uri => resolve(uri as Blob | File), // Is the callBack function of the resized new image URI.
      'blob' // Is the output type of the resized new image.
    );
  });
  return promise;
}

/**
 * Fetches a url to get a image file, resizes the image and returns a base64 version of the same image
 * @param url an url image file
 * @param mimeType image type
 * @param max_width maximum width of the converted image
 * @param max_height maximum height of the converted image
 * @returns a promise of a base64 image resized to max_width and max_height
 * @author Douglas Flores
 */
export async function urlToBase64Image(
  url: string,
  mimeType: MimeType,
  max_width: number = 800,
  max_height: number = 600
) {
  const file = await urlToFile(
    url,
    `placeholder.${mimeToExtension(mimeType)}`,
    mimeType
  );
  const resized = await imgResizer(file, max_width, max_height);
  const base64 = await fileToBase64(resized);
  return base64;
}
