import { makeStyles } from '@mui/styles';

const dotStyles = makeStyles(() => ({
  dot1: {
    animation: '$visibility1 3s linear infinite'
  },
  '@keyframes visibility1': {
    '0%': {
      opacity: 1
    },
    '65%': {
      opacity: 1
    },
    '66%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    }
  },
  dot2: {
    animation: '$visibility2 3s linear infinite'
  },
  '@keyframes visibility2': {
    '0%': {
      opacity: 0
    },
    '21%': {
      opacity: 0
    },
    '22%': {
      opacity: 1,
    },
    '65%': {
      opacity: 1,
    },
    '66%': {
      opacity: 0
    },
    '100%': {
      opacity: 0,
    }
  },
  dot3: {
    animation: '$visibility3 3s linear infinite'
  },
  '@keyframes visibility3': {
    '0%': {
      opacity: 0
    },
    '43%': {
      opacity: 0
    },
    '44%': {
      opacity: 1,
    },
    '65%': {
      opacity: 1,
    },
    '66%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    }
  }
}));

export default dotStyles;