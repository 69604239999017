import { makeStyles } from '@mui/styles';

const useDqsAttachStyles = makeStyles(theme => ({
  listDiv: {
    width: '100%',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 5
  },
  groupDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: '100%'
  },
  materialButton: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export default useDqsAttachStyles;
