import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const launchScreenStyles = makeStyles({
  splashSuper: {
    width: '100dvw',
    height: '100dvh',
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    padding: 0,
    margin: 0
  },
  splashWrapper: {
    /* Must be a square with l equal to the largest screen axis */
    height: '160dvmax',
    width: '160dvmax',
    /* Positioning */
    position: 'absolute',
    top: 'calc(-80dvmax + 50dvh - 12px)', // Relative to height and width. 12px is padding for the logo
    left: 'calc(-80dvmax + 50dvw)', // Relative to height and width
    zIndex: 4096,
    /* Alignment */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    /* Backdrop */
    backgroundColor: `${theme.palette.primary.main}A0`,
    transition: 'background 1500ms ease-in'
  },
  noBackDrop: {
    backgroundColor: 'transparent',
    transition: ''
  },
  splash: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    background:
      'linear-gradient(50deg, rgba(11,11,71,1) 0%, rgba(44,29,131,1) 200%)'
  },
  circleFade: {
    animationDelay: '500ms',
    animation: '$circleFade 1200ms ease-in-out both'
  },
  circleFadePulse: {
    animationDelay: '500ms',
    animation: '$circleFadePulse 1300ms ease-in-out both'
  },
  logo: {
    /**
     * This is an offset to make the logo look centralized.
     * This is needed because the logo is triangular, so its centroid is a little higher
     * than the center of its encompassing square
     */
    marginTop: 12,
    width: '28dvmin' // responsive to screen orientation
  },
  logoVanish: {
    animationDelay: '1100ms',
    animation: '$vanish 600ms ease-in-out forwards'
  },
  logoPulse: {
    animationDelay: '900ms',
    animation: '$pulse 350ms ease-in-out'
  },
  '@keyframes circleFade': {
    '0%': {
      width: '100%',
      height: '100%'
    },
    '35%': {
      width: '60vmin',
      height: '60vmin'
    },
    '40%': {
      width: '62vmin',
      height: '62vmin'
    },
    '65%': {
      width: '64vmin',
      height: '64vmin'
    },
    '100%': {
      borderRadius: '100%',
      width: 0,
      height: 0
    }
  },
  '@keyframes circleFadePulse': {
    '40%': {
      width: '60vmin',
      height: '60vmin'
    },
    '45%': {
      width: '62vmin',
      height: '62vmin'
    },
    '60%': {
      width: '64vmin',
      height: '64vmin'
    },
    '100%': {
      transform: 'scale(1)'
    }
  },
  '@keyframes vanish': {
    '0%': {
      transform: 'rotate(0deg)',
      '-webkit-transform': 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg) scale(0)',
      '-webkit-transform': 'rotate(360deg) scale(0)'
    }
  }
});

export default launchScreenStyles;
