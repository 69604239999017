import axios, { AxiosResponse } from 'axios';
import api, { API_TIMEOUT } from './api';
import { S3FileFormat } from '../types/utils';
import db from './db';
import {
  BucketData,
  LocalFileWrapper,
  MimeType,
  PresignedDownload,
  PresignedMultiUpload,
  PresignedUpload,
  PresignedUploadWithKey
} from '../../vorotypes/types/utils';
import { MimeTypeSchema } from '../../vorotypes/schemas/utils.zod';

/**
 * Converts a base64 encoded file to a File
 * @param base64 a valid base64 url
 * @param fileName desired name for the generated file
 * @returns a file promise
 */
export async function base64ToFile(
  base64: string,
  fileName: string
): Promise<File> {
  // Fetching base64URL
  const res = await fetch(base64);
  // Getting blob from the response
  const blob: Blob = await res.blob();
  /* Deriving extension (file type) from base64URL */
  const arr = base64.split(',');
  const extension = (arr[0].match(/:(.*?);/) || [''])[1];
  // Creating and returning file
  return new File([blob], fileName, { type: extension });
}

/**
 * @param key the key of the file in S3
 * @param bucketName the name of the bucket from wich to download the file
 * @param format defines wich type of object will be returned
 *  - base64: returns a base64 string
 *  - file:  return a binary
 * @returns a file in base64 or binary
 * @author Douglas Flores
 */
export async function downloadPresignedFile(
  key: string,
  bucketName: string,
  format: S3FileFormat
) {
  try {
    const res = await api.get<PresignedDownload>(
      '/app/presignedUrlFileDownload',
      {
        params: {
          key: key,
          name: bucketName
        },
        timeout: API_TIMEOUT
      }
    );
    const url = res.data.url as string;
    if (format === 'base64') return await urlToBase64(url);
    else if (format === 'file') return await urlToFile(url, key);
    else return url;
  } catch (error: any) {
    console.error(error);
    return null;
  }
}

/**
 * Retrieves a S3 file in base64 or binary from cache.
 * On cache miss, downloads the file and caches it for later.
 * @param key the key of the file in S3
 * @param bucketName the name of the bucket from wich to download the file
 * @param format defines wich type of object will be returned
 *  - base64: returns a base64 string
 *  - file:  return a binary
 * @returns the requested file, pulling it from cache or downloading it on cache miss
 * @author Douglas Flores
 */
export async function getS3File(
  key: string,
  bucketName: string,
  format: S3FileFormat = 'base64'
) {
  try {
    if (!key || !bucketName)
      throw new Error('[ERROR] files @ getS3File : missing params');
    /* Getting from cache */
    const cacheres = await db.s3Cache
      .where(['key', 'bucketName', 'format'])
      .equals([key, bucketName, format])
      .toArray();
    if (!!cacheres[0] && !!cacheres[0]?.data) {
      db.s3Cache.update([key, bucketName, format], {
        dateOfLastUse: new Date().toISOString()
      });
      return cacheres[0]?.data;
    }
    /* Downloading */
    const data = await downloadPresignedFile(key, bucketName, format);
    db.s3Cache
      .where('dateOfLastUse')
      .below(
        new Date(new Date().getTime() - 30 * 24 * 3600 * 1000).toISOString()
      )
      .delete()
      .then(deleteCount => {
        if (deleteCount > 0)
          console.log(`[LOG] Removed ${deleteCount} files from cache`);
      })
      .catch(err => console.error(err))
      .finally(() => {
        db.s3Cache.put({
          key: key,
          bucketName: bucketName,
          format: format,
          data: data,
          dateOfLastUse: new Date().toISOString()
        });
      });
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Uploads a list of files into one directory
 * @param directory the directory wich the file will be uploaded
 * @param wrappedFiles array of file wrappers
 * @returns an object with the response from the api after getting the pressignedUrls and all file upload promises
 */
export async function uploadPresignedFiles(
  directory: string,
  wrappedFiles: LocalFileWrapper[]
) {
  /* Getting presigned urls to upload the files */
  const apiRes = await api.get<PresignedMultiUpload>('/app/presignedUrls', {
    params: {
      urlRequisitions: wrappedFiles?.map(wfile =>
        Object({
          key: `${directory}/${wfile.id}.${mimeToExtension(wfile.type)}`,
          type: `${wfile.type ?? 'image/jpeg'}`
        })
      )
    }
  });
  const urls = apiRes?.data?.urls;
  /* Building promises */
  const putFilePromises = urls?.map(
    async (url: string, idx: number) =>
      new Promise(async (resolve, reject) => {
        try {
          const wfile = wrappedFiles[idx];
          // Gets a file from the base64URL attribute
          const file = await base64ToFile(
            wfile.base64,
            `${wfile.id}.${mimeToExtension(wfile?.type)}`
          );
          // Uploading the file
          const res = await axios.put(url, file, {
            method: 'PUT',
            headers: { 'Content-Type': wfile.type }
          });
          resolve(res);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })
  );
  /* Awaiting promises to be fullfiled */
  const fulfilled = !!urls?.length ? await Promise.all(putFilePromises) : [];
  /* Returning results */
  return { presignedRes: apiRes, fulfilled };
}

/**
 *
 * @param directory the directory where the file will be uploaded
 * @param wrappedFile the file to upload
 * @param registerHash (optional) sets the register hash to upload selfie in self register
 * @returns an object with the api's response and the aws s3's response
 */
export async function uploadPresignedFile(
  directory: string,
  wrappedFile: LocalFileWrapper,
  registerHash?: string
): Promise<{
  apires: AxiosResponse<PresignedUpload>;
  awsres: AxiosResponse;
  bucketData: BucketData;
}> {
  /* Getting presigned url to upload the file */
  const route = registerHash
    ? '/app/presignedUrlFromHash'
    : '/app/presignedUrl';
  const fileKey = `${directory}/${wrappedFile.id}.${mimeToExtension(
    wrappedFile.type
  )}`;
  const apires = await api.get<PresignedUpload>(route, {
    params: {
      key: fileKey,
      type: wrappedFile.type,
      register_hash: registerHash
    }
  });
  const url = apires?.data?.url;
  const wfile = wrappedFile;

  /* Gets a file from the base64 attribute */
  const file = await base64ToFile(
    wfile.base64,
    `${wfile.id}.${mimeToExtension(wfile?.type)}`
  );
  /* Uploading the file to S3 */
  const awsres = await axios.put(url, file, {
    method: 'PUT',
    headers: { 'Content-Type': wfile.type }
  });

  /* Setting Bucket Data */
  const bucketData: BucketData = {
    key: fileKey,
    name: apires?.data?.bucketName
  };

  /* Returning results */
  return { apires, awsres, bucketData };
}

/**
 *
 * @param directory the directory where the file will be uploaded
 * @param wrappedFile the file to upload
 * @param registerHash (optional) sets the register hash to upload selfie in self register
 * @returns an object with the api's response and the aws s3's response
 */
export async function uploadPresignedSelfie(
  wrappedFile: LocalFileWrapper
): Promise<{
  apires: AxiosResponse<PresignedUploadWithKey>;
  awsres: AxiosResponse;
}> {
  /* Getting presigned url to upload the file */
  const apires = await api.get<PresignedUploadWithKey>(
    '/app/presignedSelfieUrl',
    {
      params: {
        fileName: `${wrappedFile.id}.${mimeToExtension(wrappedFile.type)}`,
        type: wrappedFile.type
      }
    }
  );
  const url = apires?.data?.url;
  const wfile = wrappedFile;

  /* Gets a file from the base64 attribute */
  const file = await base64ToFile(
    wfile.base64,
    `${wfile.id}.${mimeToExtension(wfile?.type)}`
  );
  /* Uploading the file to S3 */
  const awsres = await axios.put(url, file, {
    method: 'PUT',
    headers: { 'Content-Type': wfile.type }
  });

  /* Returning results */
  return { apires, awsres };
}

/**
 * Converts a mime type string into a file extension
 * @param {MimeType} mime example: 'image/jpg'
 * @param defaultExtension extension to fallback if the conversion goes unsuccessful
 * @returns an file extension derived from the mime type granted
 */
export function mimeToExtension(
  mime: MimeType,
  defaultExtension: string = 'jpeg'
) {
  const matches = mime.split('/');
  if (!!matches && matches.length > 1) return matches[1];
  else return defaultExtension;
}

/**
 * Converts a file or blob to base64
 * @param file the file or blob to be converted to base64
 * @returns a Promise to a base64 encoded file
 */
export function fileToBase64(file: Blob | File) {
  return new Promise<string>((resolve, reject) => {
    // Read the file as DataURL using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') resolve(reader.result);
      else reject(new Error(`Couldn't convert file to base64 encoding`));
    };
    reader.onerror = () => reject(new Error(`Failed to read the file`));
    reader.readAsDataURL(file);
  });
}

/**
 * Extracts the mimetype from a base64 data url
 * @param {string} base64 a base64 data url
 * @returns a Mimetype on success and undefined on a failure
 * @author Douglas Flores
 */
export function getMimetypeFromBase64(base64: string) {
  try {
    const base64Split = base64.split(',');
    const matchResult = base64Split[0].match(/:(.*?);/);
    if (!matchResult) throw Error('No match for mimetype');
    const mimetypeMatch = matchResult[1];
    const mimetype = MimeTypeSchema.parse(mimetypeMatch);
    return mimetype;
  } catch (error) {
    console.warn(error);
    return undefined;
  }
}

/**
 * Downloads a file from an url and converts it to base64
 * @param url of the file
 * @returns a Promise of a base64 file
 */
export async function urlToBase64(
  url: string
): Promise<string | ArrayBuffer | null> {
  try {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Converts an url into a File
 * @param url url of the file to be converted
 * @param {string} filename the desired name of the generated file
 * @param {MimeType} mimeType self explanatory
 * @returns a Promise of the resulting File
 */
export async function urlToFile(
  url: string,
  filename: string,
  mimeType?: MimeType
): Promise<File> {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
}
