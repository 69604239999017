import {
  Complement,
  FormField,
  FormFieldCondition
} from '../../../../../vorotypes/types/formTemplate';

/**
 * Gets a list of complements from the target fields
 * @param fields list of form fields
 * @param formComplements list of the current formComplements saved in redux's state
 * @returns the formComplements from the target fields in array form instead of object
 * @author Douglas Flores
 */
export function getComplementsFromFields(
  fields: FormField[],
  formComplements?: { [key: string]: any }
): Array<Complement> {
  if (!formComplements) return [];
  const fieldComplements = fields
    .flatMap(field => field?.complements)
    .filter(c => !!c);
  const complements = fieldComplements.map(fc => ({
    id: fc!.id,
    value: formComplements[fc!.id]
  }));
  return complements;
}

/**
 * Checks if at least one condition from a list is satisfied
 * @param conditions a list of conditions
 * @param formAnswer a formAnswer object, generally used in redux's form state
 * @param formComplements a formComplements object, generally used in redux's form state
 * @returns `true` if at least one of the conditions is met or if "conditions" is empty
 * @returns `false` if none of the conditions are met
 * @author Douglas Flores
 */
export function conditionsSatisfied(
  conditions: Array<FormFieldCondition> | undefined,
  formAnswer: { [key: string]: any },
  formComplements: { [key: string]: any } | undefined
) {
  if (!conditions) return true;
  // checking each condition
  let oneConditionIsMet = conditions?.some(condition => {
    if (condition?.type === 'complement') {
      const currentValue = !!formComplements
        ? formComplements[condition.field_id]
        : undefined;
      return checkFormFieldCondition(condition, currentValue);
    } else {
      const currentAnswer = formAnswer[condition.field_id];
      return checkFormFieldCondition(condition, currentAnswer);
    }
  });
  return oneConditionIsMet;
}

/**
 * Checks if a condition is satisfied by an answer
 * @param condition a FormFieldCondition
 * @param answer field's or complement's answer
 * @returns `true` if the condition is met
 * @returns `false` if the condition is not met
 * @author Douglas Flores
 */
function checkFormFieldCondition(condition: FormFieldCondition, answer?: any) {
  // if is array, checks if the current answer includes at least one of the expected answers
  if (
    Array.isArray(answer) &&
    answer.find(ans => condition.expected_answer.includes(ans))
  ) {
    return true;
  }
  // if it is not an array, it is a simple expected answer
  else if (!Array.isArray(answer) && answer === condition.expected_answer) {
    return true;
  } else return false;
}
