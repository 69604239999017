/* Global imports */
import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import brLocale from 'date-fns/locale/pt-BR';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
/* Local imports */
import { TextField, Typography } from '@mui/material';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import { FormField } from '../../../../../vorotypes/types/formTemplate';

type FieldDateProps = {
  field: FormField;
  setAnswers: React.Dispatch<
    React.SetStateAction<{ field_id: string; answer: any }[]>
  >;
};

export default function FieldDate(props: FieldDateProps) {
  const { field, setAnswers } = props;
  /* Hooks */
  const formAnswer = useAppSelector(state => state.form.formAnswer);
  const answerText = formAnswer[field.field_id] || '';
  /* States */
  const [input, setInput] = useState<Date | null>(
    answerText ? new Date(answerText) : null
  );
  /* Functions */
  const handleDateChange = (newDate: Date | null) => setInput(newDate);
  const updateAnswer = () =>
    setAnswers((prev: any) => {
      const stringDate =
        dayjs(input).format('YYYY-MM-DD').toString() || undefined;
      const payload = { field_id: field?.field_id, answer: stringDate };
      if (!prev) return [payload];
      const prevAnswer = prev?.findIndex(
        (ans: any) => ans.field_id === field.field_id
      );
      if (prevAnswer > -1) {
        prev[prevAnswer] = payload;
        return [...prev];
      } else return [...prev, payload];
    });
  /* useEffects */
  React.useEffect(() => {
    updateAnswer();
    //eslint-disable-next-line
  }, [input]);
  /* --- Return --- */
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}
    >
      <Typography variant="h3" marginTop={1}>
        {field?.properties?.question}
      </Typography>
      {field.properties?.description !== field.properties?.question && (
        <Typography variant="body2">{field.properties?.description}</Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
        <MobileDatePicker
          disablePast
          openTo="day"
          inputFormat="dd/MM/yyyy"
          views={['year', 'month', 'day']}
          value={input}
          onChange={handleDateChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              placeholder="dd/mm/aaaa"
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
