import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { ParticipantSignature } from '../../types/form';
import { Coworker } from '../../../vorotypes/types/user';
import { formatCPF } from '../../utils/cpf';
import signaturesStyles from './styles';
import { useCoworkers } from '../../hooks/query.hooks';
import { getS3File } from '../../services/files';
import { PARTICIPANT_JOB_DICTIONARY } from '../../../vorotypes/internalCodes/participant';
import React from 'react';

type SignatureProps = {
  signature: ParticipantSignature;
  coworkers: Coworker[] | undefined;
};

function Signature({ signature, coworkers }: SignatureProps) {
  const localClasses = signaturesStyles();

  const coworker = useMemo(() => {
    return coworkers?.find(p => p.id === signature.id);
  }, [coworkers, signature.id]);
  const [signatureImg, setSignatureImg] = useState<string | null>();
  const [selfieImg, setSelfieImg] = useState<string | null>();

  // set selfie image
  useEffect(() => {
    if (!!signature.photo?.bucketData) {
      const bucketData = signature.photo.bucketData;
      getS3File(bucketData?.key, bucketData?.name)
        .then(data => {
          if (typeof data === 'string') setSelfieImg(data ?? '');
          else setSelfieImg(null);
        })
        .catch(err => console.error(err));
    } else if (!!signature.photo?.base64) {
      setSelfieImg(signature.photo.base64);
    } else {
      setSelfieImg('');
    }
  }, [signature.photo?.bucketData, signature.photo?.base64]);

  // set signature drawing
  useEffect(() => {
    if (!signature?.signatureImage) return;
    if (!!signature.signatureImage?.bucketData) {
      const bucketData = signature.signatureImage?.bucketData;
      getS3File(bucketData.key, bucketData.name)
        .then(data => {
          if (typeof data === 'string') setSignatureImg(data ?? '');
          else setSignatureImg(null);
        })
        .catch(err => console.error(err));
    } else if (!!signature?.signatureImage?.base64) {
      setSignatureImg(signature.signatureImage.base64);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature.signatureImage]);

  return (
    <div className={localClasses.container}>
      {!!selfieImg && (
        <figure className={localClasses.figure}>
          <img
            src={selfieImg}
            alt={coworker?.name ?? ''}
            className={localClasses.avatar}
          />
        </figure>
      )}

      <div className={localClasses.signatureContent}>
        <Typography variant="h4" mt={0}>
          {coworker?.name}
        </Typography>
        {!!signature?.job && (
          <Typography variant="body2" textTransform="capitalize">
            {PARTICIPANT_JOB_DICTIONARY[signature.job]}
          </Typography>
        )}
        <div style={{ marginTop: 2 }}>
          {signature.personalId?.value && (
            <Typography
              fontStyle="italic"
              style={{ textTransform: 'uppercase' }}
            >
              {`${signature.personalId?.type}: `}
              {signature.personalId?.type === 'cpf'
                ? formatCPF(signature.personalId.value)
                : signature.personalId?.value}
            </Typography>
          )}
          <Typography fontStyle="italic">
            Assinado em:{' '}
            {new Date(signature.date as string).toLocaleString('pt-BR')}
          </Typography>
        </div>
        {!!signatureImg ? (
          <img
            src={signatureImg}
            alt={`Assinatura de ${coworker?.name}`}
            className={localClasses.signatureImage}
          />
        ) : (
          !!coworker?.signature && (
            <Typography
              fontFamily={coworker.signature.font}
              fontSize={48}
              mt={2}
            >
              {coworker.signature.text}
            </Typography>
          )
        )}
      </div>
    </div>
  );
}

type SignaturesProps = {
  data: Array<ParticipantSignature>;
};

function Signatures({ data }: SignaturesProps) {
  const [signatures, setSignatures] = useState<ParticipantSignature[]>([]);
  const coworkers = useCoworkers(); // Calling coworkers here insted of in Signature to reduce the number of requests to the api

  useEffect(() => {
    setSignatures(data ? data : []);
  }, [data]);

  return (
    <React.Fragment>
      {signatures?.length > 0 &&
        signatures.map(signature => (
          <Signature
            signature={signature}
            key={signature.id}
            coworkers={coworkers?.data?.coworkers}
          />
        ))}
    </React.Fragment>
  );
}

export default Signatures;
