/* Global imports */
import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Fade, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import Cookies from 'js-cookie';
import clsx from 'clsx';

/* Icon imports */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/* Local imports */
import { loginRequest as msalLoginRequest } from '../../config/msalAuthConfig';
import signinUseStyles from '../SignIn/style';
import useAlert from '../../hooks/useAlert';
import SnackbarAlert from '../../components/SnackbarAlert';
import logAppEvent from '../../services/logAppEvent';
import api from '../../services/api';
import { getFlavour } from '../../utils/helpers';
import { Flavour } from '../../types/utils';
import LogoBanner from '../SignIn/components/logoBanner';

export default function SingleSignOn() {
  const signinClasses = signinUseStyles();
  const history = useHistory();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [showBackBtn, setShowBackBtn] = useState<boolean>(false);
  const [flavour] = useState<Flavour>(getFlavour());
  const { instance } = useMsal();

  /* Style consts */
  const rootClass = clsx(
    signinClasses.root,
    flavour === 'brf' && signinClasses.brfBackground
  );
  const paperClass = clsx(
    signinClasses.loginPaper,
    flavour === 'brf' && signinClasses.loginPaperCustom
  );
  const logoClass = clsx(
    signinClasses.companyBannerCustom,
    flavour === 'brf'
      ? signinClasses.companyBannerCustomSso
      : signinClasses.companyBannerSso
  );

  async function authenticateExternally() {
    try {
      setIsLoading(true);
      await instance.loginRedirect(msalLoginRequest);
    } catch (err: any) {
      console.error(err);
      if (err?.response && err?.response?.status === 401) {
        alert.error('Usuário ou Senha incorretos');
      } else if (err?.response && err?.response?.status === 403) {
        alert.error('Usuário não autorizado');
      } else {
        alert.error('Erro ao autenticar usuário');
      }
    } finally {
      setIsLoading(false);
    }
  }
  async function authUserVorotech(account: AccountInfo | undefined) {
    try {
      setIsLoading(true);
      const { accessToken } = await instance.acquireTokenSilent({
        ...msalLoginRequest,
        account
      });
      try {
        const res = await api.post('/authEntraUser', {
          accessToken: accessToken
        });
        Cookies.set('email', res.data.email, { expires: 365 });
        Cookies.set('token', res.data.token, { expires: 365 });
        Cookies.set('name', res.data.name, { expires: 365 });
        logAppEvent(`Log in`);
        history.push('/home');
      } catch (err: any) {
        setIsLoading(false);
        if (err.response && err.response.status === 401) {
          alert.error('Usuário ou Senha incorretos');
        } else if (err.response && err.response.status === 403) {
          alert.error('Usuário não autorizado');
        } else {
          alert.error('Erro ao autenticar usuário');
        }
      }
    } catch (err: any) {
      history.push('/login');
    }
  }

  useEffect(() => {
    async function handleAuth() {
      try {
        const redirectRes = await instance.handleRedirectPromise();
        if (!redirectRes) {
          authenticateExternally();
        } else {
          authUserVorotech(redirectRes?.account ?? undefined);
        }
      } catch (err: any) {
        console.error(err);
      }
    }
    handleAuth();
    setTimeout(() => setShowBackBtn(true), 2000);
    return () => setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={rootClass}>
      <div className={paperClass} style={{ gap: 34 }}>
        <LogoBanner className={logoClass} />
        <div className={signinClasses.lowerDiv}>
          <Fade in={isLoading} timeout={500}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <CircularProgress size={16} style={{ color: 'white' }} />
              <Typography style={{ marginTop: 1 }}>
                Preparando login, aguarde...
              </Typography>
            </div>
          </Fade>
          <Fade in={showBackBtn} timeout={1500}>
            <Button
              id="btn-login"
              variant="outlined"
              className={signinClasses.enterButton}
              onClick={() => history.push('/')}
              startIcon={<ArrowBackIcon style={{ fontSize: 25 }} />}
            >
              Voltar
            </Button>
          </Fade>
        </div>
      </div>

      <SnackbarAlert {...alert.state} style={{ marginTop: 16 }} />
    </div>
  );
}
