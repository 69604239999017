import React, { useEffect, useReducer, useState } from 'react';

type ActionType =
  | 'set_filter'
  | 'unset_filter'
  | 'clear_filters'
  | 'create_filter';

export interface FilterStateValue {
  fn: (x: any, filterValue: any) => boolean;
  value: any;
}

export interface FilterState {
  [key: string]: FilterStateValue;
}

export interface Action {
  type: ActionType;
  idFilter?: string;
  payload?: FilterStateValue;
}

/**
 * A hook to manage filters
 * @param dataToFilter the array that should be filtered
 * @param initialState optionally set an initial set of filters
 * @returns a useReducer hook to manage filters
 * @author Douglas Flores
 */
export default function useFilter<T = any>(
  dataToFilter: Array<T> | undefined,
  initialState?: FilterState
): [FilterState, React.Dispatch<Action>, Array<T> | undefined] {
  const [results, setResults] = useState<T[] | undefined>();
  const [filters, dispatchFilters] = useReducer(
    filterReducerFn,
    initialState ?? {}
  );
  useEffect(() => {
    if (!!dataToFilter) {
      const entries = Object.entries(filters as FilterState);
      let filteredResults = [...dataToFilter];
      if (entries.length > 0) {
        for (const [key, value] of entries) {
          filteredResults = filteredResults.filter(
            bu => value.value === undefined || value?.fn(bu, value.value)
          );
        }
      }
      setResults(filteredResults);
    }
  }, [filters, dataToFilter]);
  return [filters, dispatchFilters, results];
}

/**
 * Reducer to manipulate filters
 * @param state object with all filters
 * @param action to be dispatched
 * @returns the next state
 * @author Douglas Flores
 */
function filterReducerFn(state: FilterState, action: Action): FilterState {
  switch (action.type) {
    case 'set_filter':
      if (!!action?.idFilter) {
        let next_state = { ...state };
        Object.assign(next_state, { [action.idFilter]: action?.payload });
        return next_state;
      } else {
        console.warn('Cannot set a filter without an id');
        return state;
      }
    case 'unset_filter':
      if (!!action?.idFilter) {
        let next_state = {};
        for (const [key, value] of Object.entries(state)) {
          if (key !== action.idFilter)
            Object.assign(next_state, { [key]: value });
        }
        return next_state;
      } else {
        console.warn('Cannot unset a undefined filter');
        return state;
      }
    case 'create_filter':
      if (!!action?.idFilter && !!action?.payload) {
        let next_state = { ...state };
        next_state[action.idFilter] = action.payload;
        return next_state;
      } else {
        console.warn('Cannot create a filter without an id or a payload');
        return state;
      }
    case 'clear_filters':
      return {};
    default:
      return state;
  }
}
