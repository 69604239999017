import React, { useState } from 'react';

import { Button, InputBase, Typography } from '@mui/material';

import { useHistory } from 'react-router-dom';
import signinUseStyles from './style';
import useAlert from '../../hooks/useAlert';
import SnackbarAlert from '../../components/SnackbarAlert';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentPasteGoRoundedIcon from '@mui/icons-material/ContentPasteGoRounded';
import LogoBanner from './components/logoBanner';
import { LoadingButton } from '@mui/lab';

import api from '../../services/api';
import { ValidatePortabilityTokenReq } from '../../../vorotypes/types/routes.app.requests';

export default function PortabilitySignIn(props: any) {
  /* Styles */
  const portabilitySignInClasses = signinUseStyles();
  /* Hooks */
  const history = useHistory();
  const alert = useAlert();
  /* States */
  const [accessKey, setAccessKey] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /* Style consts */
  const rootClass = portabilitySignInClasses.root;
  const paperClass = portabilitySignInClasses.loginPaper;
  const logoClass = portabilitySignInClasses.companyBannerDefault;

  async function validatePortabilityToken(pastedAccessKey?: string) {
    setIsLoading(true);
    const key = pastedAccessKey ?? accessKey;
    try {
      await api.post('/app/portability/validate-token', {
        token: `${key}`
      } as ValidatePortabilityTokenReq);
      history.push(`/portabilidade/${key}`);
    } catch (err) {
      setError(true);
      alert.error('Chave de acesso inválida');
    } finally {
      setIsLoading(false);
    }
  }

  async function pasteAndGo() {
    try {
      const key = await navigator.clipboard.readText();
      validatePortabilityToken(key);
    } catch (error) {
      setError(true);
      alert.error('Não foi possível colar a chave de acesso!');
    }
  }

  return (
    <div className={rootClass}>
      <div className={paperClass}>
        <React.Fragment>
          <LogoBanner className={logoClass} />
          <div className={portabilitySignInClasses.centerDiv}>
            <Typography color="#FFF">
              Cole abaixo a chave de acesso copiada para que você efetue o login
              diretamente.
            </Typography>
            <div className={portabilitySignInClasses.linkPasteDiv}>
              <Button
                variant="contained"
                className={portabilitySignInClasses.pasteButton}
                onClick={pasteAndGo}
              >
                <ContentPasteGoRoundedIcon />
              </Button>
              <InputBase
                id="txt-input-access-key"
                error={error}
                className={portabilitySignInClasses.textField}
                placeholder="Chave de acesso"
                classes={{ error: portabilitySignInClasses.textFieldError }}
                value={accessKey}
                onChange={e => setAccessKey(e.target.value.trim())}
                inputProps={{ inputMode: 'text' }}
                required
                autoFocus
              />
            </div>
            <LoadingButton
              id="btn-login"
              fullWidth
              variant="contained"
              className={portabilitySignInClasses.mainButton}
              disabled={accessKey.length === 0}
              onClick={() => validatePortabilityToken()}
              loading={isLoading}
              endIcon={
                <LoginRoundedIcon
                  className={portabilitySignInClasses.loginIcon}
                />
              }
            >
              Entrar
            </LoadingButton>
          </div>
          <div className={portabilitySignInClasses.backDiv}>
            <Button
              id="btn-login"
              variant="outlined"
              onClick={() => history.push('/login')}
              style={{ alignSelf: 'flex-start', color: 'white' }}
              startIcon={<ArrowBackIcon />}
            >
              Voltar
            </Button>
          </div>
        </React.Fragment>
      </div>
      <SnackbarAlert {...alert.state} style={{ marginTop: 16 }} />
    </div>
  );
}
