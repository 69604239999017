import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const photosInputStyles = makeStyles(() => ({
  inputPhotoDiv: {
    display: 'flex',
    flexDirection: 'row'
  },
  cameraButton: {
    backgroundColor: theme.palette.secondary.main,
    width: 66,
    borderRadius: 5
  },
  horizontalScroll: {
    overflowX: 'auto',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    marginLeft: 8,
    '&::-webkit-scrollbar': {
      height: '3px'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.30)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main
    }
  },
  removePhotoButton: {
    position: 'absolute',
    right: 7,
    top: 7,
    color: '#FFFFFFF0',
    borderRadius: '100%',
    width: 22,
    height: 22
  }
}));

export default photosInputStyles;
