import { useTheme } from '@mui/material';
import { useElapsedTime } from '../../hooks/custom.hooks';
import { useMemo } from 'react';

interface TimerProps {
  startDatetime?: string; // ISO8601 datetime string
  warningThreshold?: number;
  criticalThreshold?: number;
}

export default function Timer(props: TimerProps) {
  const { warningThreshold, criticalThreshold } = props;
  const theme = useTheme();
  const [elapsedTimeStr, elapsedTimeInSec] = useElapsedTime(
    props?.startDatetime
  );
  const state = useMemo(() => {
    if (!!criticalThreshold && elapsedTimeInSec >= criticalThreshold)
      return 'error';
    if (!!warningThreshold && elapsedTimeInSec >= warningThreshold)
      return 'warning';
    return 'ok';
  }, [elapsedTimeInSec, warningThreshold, criticalThreshold]);
  /* Return component */
  return (
    <span
      style={{
        color:
          state === 'error'
            ? theme.palette.error.main
            : state === 'warning'
            ? theme.palette.warning.dark
            : 'inherit'
      }}
    >
      {elapsedTimeStr}
    </span>
  );
}
