import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import fieldUserStyles from '../styles';
import { getCertificationStatus } from '../../../../../utils/helpers';
import { Certification } from '../../../../../../vorotypes/types/certification';

interface CertificationChipProps {
  validThrough: string;
  certification?: Certification;
}

export default function RenderCertification(props: CertificationChipProps) {
  /* Props */
  const { validThrough, certification } = props;
  /* Styles */
  const theme = useTheme();
  const localClasses = fieldUserStyles(theme);
  /* Memo */
  const status = useMemo(
    () =>
      getCertificationStatus(
        validThrough,
        certification?.warningPeriodInDays ?? 0
      ),
    [validThrough, certification]
  );
  /* Return component */
  return (
    <div
      className={localClasses.certificationTag}
      style={{
        backgroundColor:
          status === 'expired'
            ? theme.palette.error.main
            : status === 'warning'
            ? theme.palette.warning.main
            : 'rgba(20, 20, 60, 0.1)',
        color: status === 'expired' ? '#fff' : theme.palette.primary.main
      }}
    >
      {certification?.name}
    </div>
  );
}
