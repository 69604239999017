import { Alert, AlertColor, Snackbar, SnackbarProps } from '@mui/material';

interface SnackbarAlertProps extends SnackbarProps {
  severity: AlertColor;
  message: string;
}

/**
 * Simplified combination of snackbar and alert
 * @param props the props
 * @returns a snackbar-alert combined component
 */
export default function SnackbarAlert(props: SnackbarAlertProps) {
  const { severity, message, ...snackprops } = props;
  return (
    <Snackbar {...snackprops}>
      <Alert severity={severity} sx={{whiteSpace: 'pre-line'}}>{message}</Alert>
    </Snackbar>
  );
}
