import { useState } from 'react';
import { Typography } from '@mui/material';
import VorotechLogo from '../../../images/vorotech_logo.png';
import BRFLogo from '../../../images/brf_global_logo.svg?react';
import VorotechLogoOutlined from '../../../images/logo_outlined.svg?react';
import { getFlavour } from '../../../utils/helpers';
import { Flavour } from '../../../types/utils';
import signinUseStyles from '../style';
import clsx from 'clsx';

type LogoBannerProps = {
  style?: React.CSSProperties;
  className?: string;
};

export default function LogoBanner(props: LogoBannerProps) {
  const [flavour] = useState<Flavour>(getFlavour());
  if (!flavour) return <DefaultLogoBanner {...props} />;
  else return <CompanyLogoBanner flavour={flavour} {...props} />;
}

function DefaultLogoBanner(props: LogoBannerProps) {
  return (
    <div style={{ ...props?.style }} className={props?.className}>
      <img
        src={VorotechLogo}
        alt="Logo Vorotech"
        width="100%"
        style={{ display: 'block' }} // used to keep the outer div with the same height of the <img>
      />
    </div>
  );
}

function CompanyLogoBanner(
  props: LogoBannerProps & {
    flavour: Flavour;
  }
) {
  const { flavour } = props;
  const localClasses = signinUseStyles();
  return (
    <div
      className={clsx(localClasses.companyBannerDiv, props?.className)}
      style={props?.style}
    >
      <Typography display="flex" gap={2} alignItems="center">
        <VorotechLogoOutlined
          width={16}
          height={16}
          style={{ marginBottom: 1 }}
        />
        PET Digital
      </Typography>
      {flavour === 'brf' && <BRFLogo className={localClasses.mainLogo} />}
    </div>
  );
}
