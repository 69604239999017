import api from './api';
import store from '../store';
import db from './db';
import { setFormanswer, setFormTemplateId } from '../slices/formSlice';
import {
  setAnswerTimeSeed,
  setAnswerInitDate,
  setAnswerInitPosition
} from '../slices/answerMetadataSlice';
import { TermsAndConditionsElement } from '../types/utils';
import { certificationsQueryFn, defaultQueryFn } from '../utils/queries';
import voroQueryClient from './voroQueryClient';
import { setDebugMode, setUpdateStatus } from '../slices/sessionSlice';
import { CoworkerListRes } from '../../vorotypes/types/routes.app.responses';
import { setAdvancedCamera } from '../slices/configSlice';

export function updateRedux() {
  try {
    // Update Values (DexieDB -> redux_store)
    db.table('misc')
      .where('key')
      .equals('formAnswer')
      .toArray()
      .then(dbres => {
        if (dbres[0]) {
          store.dispatch(setFormanswer(dbres[0].value));
        }
      })
      .catch(err => {
        console.log(err);
      });
    // db.table('misc')
    //   .where('key')
    //   .equals('step')
    //   .toArray()
    //   .then(dbres => {
    //     if (dbres[0]) {
    //       store.dispatch(setStep(dbres[0].value));
    //     }
    //   })
    //   .catch(err => { console.log(err); });
    db.table('misc')
      .where('key')
      .equals('formTemplateId')
      .toArray()
      .then(dbres => {
        if (dbres[0]) {
          store.dispatch(setFormTemplateId(dbres[0].value));
        }
      })
      .catch(err => {
        console.log(err);
      });
    // Update Values (DexieDB -> redux_store)
    db.table('misc')
      .where('key')
      .equals('answerTimeSeed')
      .toArray()
      .then(dbres => {
        if (dbres[0]) {
          store.dispatch(setAnswerTimeSeed(dbres[0].value));
        }
      })
      .catch(err => {
        console.log(err);
      });
    db.table('misc')
      .where('key')
      .equals('answerInitDate')
      .toArray()
      .then(dbres => {
        if (
          dbres[0] &&
          typeof dbres[0].value === 'string' &&
          dbres[0].value.length > 0
        ) {
          store.dispatch(setAnswerInitDate(dbres[0].value));
        }
      })
      .catch(err => {
        console.log(err);
      });
    db.table('misc')
      .where('key')
      .equals('answerInitPosition')
      .toArray()
      .then(dbres => {
        if (dbres[0]) {
          store.dispatch(setAnswerInitPosition(dbres[0].value));
        }
      })
      .catch(err => {
        console.log(err);
      });
    db.table('misc')
      .where('key')
      .equals('debugMode')
      .toArray()
      .then(dbres => {
        if (!!dbres[0]?.value) store.dispatch(setDebugMode(true));
        else store.dispatch(setDebugMode(false));
      });
    db.table('misc')
      .where('key')
      .equals('updateStatus')
      .toArray()
      .then(dbres => {
        if (!!dbres[0]?.value) store.dispatch(setUpdateStatus(dbres[0].value));
        else store.dispatch(setUpdateStatus('sleeping'));
      });
    db.table('misc')
      .where('key')
      .equals('advancedCamera')
      .toArray()
      .then(dbres => {
        if (!!dbres[0]?.value) store.dispatch(setAdvancedCamera(true));
        else store.dispatch(setAdvancedCamera(false));
      });
  } catch (err) {
    console.error(err);
  }
}

/**
 * @description Caches form answer's redux's current state
 */
export function updateDexieFormAnswer() {
  try {
    if (store.getState()?.form?.formAnswer) {
      db.table('misc')
        .put({
          key: 'formAnswer',
          value: store.getState()?.form?.formAnswer
        })
        .catch(err => console.log(err));
    }
    db.table('misc')
      .put({
        key: 'step',
        value: Number(store.getState()?.form?.step)
      })
      .catch(err => console.log(err));
    if (store.getState()?.form?.formTemplateId) {
      db.table('misc')
        .put({
          key: 'formTemplateId',
          value: store.getState()?.form?.formTemplateId
        })
        .catch(err => console.log(err));
    }
    if (store.getState()?.answerMetadata?.answerInitPosition) {
      db.table('misc')
        .put({
          key: 'answerInitPosition',
          value: store.getState()?.answerMetadata?.answerInitPosition
        })
        .catch(err => console.log(err));
    }
    if (store.getState()?.answerMetadata?.answerInitDate) {
      db.table('misc')
        .put({
          key: 'answerInitDate',
          value: store.getState()?.answerMetadata?.answerInitDate
        })
        .catch(err => console.log(err));
    }
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Fetch data that must be available offline
 */
export function fetchAPI() {
  try {
    if (!navigator.onLine) throw new Error('Network Error: offline');
    console.log('Fetching API');
    /* Prefetching queries */
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['companyServices'],
        route: '/app/companyServices',
        queryFn: defaultQueryFn
      });
    }, 1000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['certifications'],
        route: '/app/certifications',
        queryFn: certificationsQueryFn
      });
    }, 2000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery<CoworkerListRes>({
        queryKey: ['coworkers'],
        route: '/app/coworkersList',
        queryFn: defaultQueryFn
      });
    }, 3000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['termsAndConditions'],
        route: '/app/termsAndConditions',
        queryFn: async () =>
          (
            await api.get<{ elements: TermsAndConditionsElement[] }>(
              '/termsAndConditions'
            )
          ).data.elements
      });
    }, 4000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['userCertifications'],
        route: '/app/activeUserCertifications',
        queryFn: defaultQueryFn
      });
    }, 5000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['documentsHistory'],
        route: '/app/documentsHistory',
        queryFn: defaultQueryFn
      });
    }, 6000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['businessUnits'],
        route: '/app/businessUnits',
        queryFn: defaultQueryFn
      });
    }, 7000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['voroRoles'],
        route: '/app/voroRoles',
        queryFn: defaultQueryFn
      });
    }, 8000);
    setTimeout(() => {
      voroQueryClient.prefetchQuery({
        queryKey: ['dqsTopics'],
        route: '/app/dqsTopics',
        queryFn: defaultQueryFn
      });
    }, 9000);
  } catch (err) {
    console.log(err);
  }
}

export async function clearDB() {
  try {
    await db.table('cache').clear();
    await db.table('answers').clear();
    await db.table('misc').clear();
    await db.table('cancellations').clear();
    await db.table('edits').clear();
    await db.queryCache.clear();
    await db.mutationCache.clear();
    await db.s3Cache.clear();
  } catch (error) {
    console.error(error);
  }
}
