/* Lib imports */
import React, { Fragment, useEffect, useState } from 'react';
import { Button, CircularProgress, Skeleton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
/* Icon imports */
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import CancellationIcon from '@mui/icons-material/DoNotDisturbOn';
import CloudSyncRoundedIcon from '@mui/icons-material/CloudSyncRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import NonExecutionIcon from '@mui/icons-material/DoNotDisturbAlt';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
/* Local imports */
import faStatusStyles from './styles';
import { FormAnswerEdit } from '../../types/form';
import { FormInspection } from '../../../vorotypes/types/formInspection';
import { getCurrentPosition } from '../../services/getPos';
import { useEditFormAnswer } from '../../hooks/mutation.hooks';
// import CustomCircularProgress from '../CircularProgress';
import DotDotDot from '../DotDotDot';
import { useFormAnswer, useCoworkers } from '../../hooks/query.hooks';
import theme from '../../theme';
import dayjs from 'dayjs';
import { useMutationCache } from '../../hooks/voroMutation';
import clsx from 'clsx';
import voroQueryClient from '../../services/voroQueryClient';
import { version } from '../../version.json';
import {
  AnswerStatus,
  AppLocalFormAnswer
} from '../../../vorotypes/types/formAnswer';
import Timer from '../Timer';
import { timeTolerance } from '../../config/estimatedDuration';

interface FormAnswerStateBannerProps {
  formAnswerUlid: string;
  filledDate?: string;
  endDate?: string;
  author?: string;
  isPausable?: boolean;
  displayElapsedTime?: boolean;
}

export default function FormAnswerStatusBanner(
  props: FormAnswerStateBannerProps
) {
  const {
    formAnswerUlid,
    filledDate,
    endDate,
    author,
    isPausable,
    displayElapsedTime
  } = props;
  const localClasses = faStatusStyles();
  /* Hooks */
  const {
    data: selectedAnswer,
    isReady,
    refetch
  } = useFormAnswer<AppLocalFormAnswer>(formAnswerUlid);
  const mutationCache = useMutationCache(['editFormAnswer', formAnswerUlid]);
  const editFormAnswer = useEditFormAnswer(formAnswerUlid);
  const { data: coworkers } = useCoworkers({ fetchOnlyOnEmptyCache: true });
  const history = useHistory();
  /* States */
  const [formInspections, setFormInspections] = useState<FormInspection[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [status, setStatus] = useState<AnswerStatus>();
  const [isPersistent, setIsPersistent] = useState<boolean>();
  const [estimatedTime, setEstimatedTime] = useState<number>();
  /* Const vars */
  const pauseHistory = status?.pauseResumeHistory;
  // There is not a reliable way to implement a percentage loading circle/bar because
  // the mutations are pushed individually, not in batches. So, when a mutation
  // finishes, there is a brief instant in time where no mutation is 'pushing' until
  // the mutator notices it and begins pushing the next one. Maybe in the future
  // we can come back here and think in one solution if this feature becomes
  // highly desireble
  // const loadingPercentage = useMemo(
  //   () =>
  //     (mutationCache.filter(mt => mt.status === 'pushed').length /
  //       (mutationCache.filter(mt =>
  //         ['pushing', 'paused', 'localSuccess', 'pushed'].includes(mt.status)
  //       ).length ?? 1)) *
  //     100,
  //   [mutationCache]
  // );
  const users = coworkers?.coworkers ?? [];
  /* Functions */
  const renderPauseResumeHistory = () => {
    if (!!pauseHistory?.length)
      return pauseHistory.map((item: any) => (
        <Typography key={`${item?.action}-${item?.datetime}`} variant="body2">
          {item?.action === 'pause' ? 'Pausada' : 'Retomada'}
          {' em '}
          {dayjs(item?.datetime).format('DD/MM/YYYY - HH:mm')}
        </Typography>
      ));
  };
  const renderFormInspections = () => {
    return formInspections?.map((insp, idx) => {
      return (
        <div key={insp.dateOfInspection} style={{ marginTop: 8 }}>
          <Typography fontWeight={700}>Inspeção {idx + 1}</Typography>
          <Typography variant="body2">
            Realizada em{' '}
            {dayjs(insp.dateOfInspection).format('DD/MM/YYYY - HH:mm')}
          </Typography>
          <Typography
            variant="body2"
            fontStyle="italic"
            color={theme.palette.grey[600]}
          >
            {users.find(c => c.id === insp.id_inspector)?.name}
          </Typography>
        </div>
      );
    });
  };
  const pauseFA = async () => {
    try {
      setIsSubmitting(true);
      /* Building data package */
      const pos = await getCurrentPosition();
      const edit: FormAnswerEdit = {
        route: '/app/formAnswer/pause',
        postData: {
          formAnswerUlid: formAnswerUlid,
          action: 'pause',
          payload: null,
          fieldId: 'answerStatus',
          datetime: new Date().toISOString(),
          appVersion: version,
          position: pos ?? undefined
        },
        config: {
          headers: { 'vorotech-required-version': '^2.0.0' }
        }
      };

      /* Executing the mutation */
      editFormAnswer.mutate(edit, {
        onError: err => console.error(err?.message)
      });
    } catch (error: any) {
      console.error(error?.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const resumeFA = () => history.push(`/ongoing/revalidate/${formAnswerUlid}`);
  /* Use effects */
  useEffect(() => {
    setStatus(selectedAnswer?.answerStatus);
    setIsPersistent(selectedAnswer?.template.config?.persistent);
    if (!!formAnswerUlid && !!selectedAnswer) {
      voroQueryClient
        .getQueryData<FormInspection[]>(['formInspections', formAnswerUlid])
        .then(inspections => {
          if (!!inspections && inspections?.length > 0) {
            let filteredInspections = inspections?.filter(
              inspection => inspection.ulid_formAnswer === formAnswerUlid
            );
            setFormInspections(filteredInspections ?? []);
          }
        });
    }
    if (!!selectedAnswer) {
      const template = selectedAnswer.template;
      const fields = template.steps.flatMap(step => step.fields);
      const etField = fields.find(field => field.type === 'EstimatedDuration');
      const et = selectedAnswer.answer.items[etField?.field_id ?? ''];
      if (!!et) setEstimatedTime(et);
    }
  }, [formAnswerUlid, selectedAnswer]);
  useEffect(() => {
    if (editFormAnswer.isSuccess) {
      refetch();
      editFormAnswer.reset();
    }
    //eslint-disable-next-line
  }, [editFormAnswer]);
  /* Returning banners */
  return (
    <div
      className={clsx(
        localClasses.banner,
        status?.status === 'paused' && localClasses.pausedBanner
      )}
    >
      {mutationCache.data.some(mt => mt.status === 'pushing') ? (
        <React.Fragment>
          <CloudSyncRoundedIcon className={localClasses.synchingIcon} />
          <div style={{ flexGrow: 2 }}>
            {author && (
              <Typography fontWeight={700}>Criada por {author}</Typography>
            )}
            <Typography
              variant={!!author ? 'body2' : 'body1'}
              fontWeight={!!author ? 400 : 700}
            >
              {filledDate ? (
                <React.Fragment>
                  {isPersistent ? 'Iniciada' : 'Realizada'} em{' '}
                  <b>{filledDate}</b>
                </React.Fragment>
              ) : (
                <Skeleton width={80} />
              )}
            </Typography>
            {renderPauseResumeHistory()}
            <Typography variant="body2" color="secondary" fontWeight={500}>
              Sincronizando
              <DotDotDot />
            </Typography>
          </div>
        </React.Fragment>
      ) : editFormAnswer.isMutating || !isReady ? (
        <React.Fragment>
          <CircularProgress size={22} thickness={5.5} color="secondary" />
          <div style={{ flexGrow: 2 }}>
            <Typography fontWeight={700}>
              <Skeleton width={80} />
            </Typography>
            {author && (
              <Typography variant="body2">
                <Skeleton width={150} />
              </Typography>
            )}
            <Typography variant="body2">
              <Skeleton width={130} />
            </Typography>
          </div>
          {isPausable && (
            <Button
              variant="contained"
              style={{ padding: 6, minWidth: 36 }}
              disabled
            >
              <PauseRoundedIcon />
            </Button>
          )}
        </React.Fragment>
      ) : status?.status === 'ongoing' ? (
        <React.Fragment>
          <CachedRoundedIcon className={localClasses.ongoingIcon} />
          <div style={{ flexGrow: 2 }}>
            {author && (
              <Typography fontWeight={700}>
                Criada por <strong>{author}</strong>
              </Typography>
            )}
            <Typography
              variant={!!author ? 'body2' : 'body1'}
              fontWeight={!!author ? 400 : 700}
            >
              {filledDate ? (
                <React.Fragment>
                  Atividade iniciada em <b>{filledDate}</b>
                </React.Fragment>
              ) : (
                <Skeleton width={80} />
              )}
            </Typography>
            <Typography variant="body2">
              Atividade em <b>andamento</b>{' '}
              {displayElapsedTime && (
                <Fragment>
                  há{' '}
                  <Timer
                    startDatetime={
                      selectedAnswer?.answer.metadata.timer.answerFinishedDate
                    }
                    warningThreshold={estimatedTime}
                    criticalThreshold={
                      !!estimatedTime
                        ? estimatedTime + timeTolerance
                        : undefined
                    }
                  />
                </Fragment>
              )}
            </Typography>
            {renderPauseResumeHistory()}
          </div>
          {isPausable && (
            <Button
              variant="contained"
              style={{ padding: 6, minWidth: 36 }}
              onClick={pauseFA}
              disabled={isSubmitting || editFormAnswer.isMutating}
            >
              <PauseRoundedIcon />
            </Button>
          )}
        </React.Fragment>
      ) : status?.status === 'ok' ? (
        <React.Fragment>
          <VerifiedRoundedIcon className={localClasses.okIcon} />
          <div>
            {author && (
              <Typography fontWeight={700}>
                Criada por <strong>{author}</strong>
              </Typography>
            )}
            <Typography
              variant={!!author ? 'body2' : 'body1'}
              fontWeight={!!author ? 400 : 700}
            >
              {filledDate ? (
                <React.Fragment>
                  Atividade {!!isPersistent ? 'iniciada' : 'realizada'} em{' '}
                  <b>{filledDate}</b>
                </React.Fragment>
              ) : (
                <Skeleton width={80} />
              )}
            </Typography>
            {!!isPersistent && (
              <React.Fragment>
                {renderPauseResumeHistory()}
                {endDate && (
                  <Typography variant="body2">
                    Concluída em{' '}
                    <b>{endDate ? `${endDate}` : <Skeleton width={40} />}</b>
                  </Typography>
                )}
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      ) : status?.status === 'nonExecution' ? (
        <React.Fragment>
          <NonExecutionIcon className={localClasses.nonExecutionIcon} />
          <div>
            {author && (
              <Typography fontWeight={700}>
                Criada por <strong>{author}</strong>
              </Typography>
            )}
            <Typography
              variant={!!author ? 'body2' : 'body1'}
              fontWeight={!!author ? 400 : 700}
            >
              {filledDate ?? <Skeleton width={80} />}
            </Typography>
            {renderPauseResumeHistory()}
            <Typography variant="body2">Atividade não executada</Typography>
          </div>
        </React.Fragment>
      ) : status?.status === 'cancelled' ? (
        <React.Fragment>
          <CancellationIcon className={localClasses.cancelledIcon} />
          <div>
            {author && (
              <Typography fontWeight={700}>
                Criada por <strong>{author}</strong>
              </Typography>
            )}
            <Typography
              variant={!!author ? 'body2' : 'body1'}
              fontWeight={!!author ? 400 : 700}
            >
              {filledDate ? (
                <React.Fragment>
                  Atividade iniciada em <b>{filledDate}</b>
                </React.Fragment>
              ) : (
                <Skeleton width={80} />
              )}
            </Typography>
            {renderPauseResumeHistory()}
            <Typography variant="body2">
              Interrompida em{' '}
              <b>{endDate ? `${endDate}` : <Skeleton width={40} />}</b>
            </Typography>
          </div>
        </React.Fragment>
      ) : status?.status === 'warning' ? (
        <React.Fragment>
          <WarningRoundedIcon className={localClasses.warningIcon} />
          <div>
            {author && (
              <Typography fontWeight={700}>
                Criada por <strong>{author}</strong>
              </Typography>
            )}
            <Typography
              variant={!!author ? 'body2' : 'body1'}
              fontWeight={!!author ? 400 : 700}
            >
              {filledDate ? (
                <React.Fragment>
                  Atividade {!!isPersistent ? 'iniciada' : 'realizada'} em{' '}
                  <b>{filledDate}</b>
                </React.Fragment>
              ) : (
                <Skeleton width={80} />
              )}
            </Typography>
            {!!isPersistent && (
              <React.Fragment>
                {renderPauseResumeHistory()}
                <Typography variant="body2">
                  Concluída em{' '}
                  <b>{endDate ? `${endDate}` : <Skeleton width={40} />}</b>
                </Typography>
              </React.Fragment>
            )}
            <Typography variant="body2" color="#C69D0E" fontStyle="italic">
              Contém inconsistências
            </Typography>
          </div>
        </React.Fragment>
      ) : status?.status === 'paused' ? (
        <React.Fragment>
          <PauseRoundedIcon />
          <div style={{ flexGrow: 2 }}>
            {author && (
              <Typography fontWeight={700}>
                Criada por <strong>{author}</strong>
              </Typography>
            )}
            <Typography
              variant={!!author ? 'body2' : 'body1'}
              fontWeight={!!author ? 400 : 700}
            >
              {filledDate ? (
                <React.Fragment>
                  Atividade iniciada em <b>{filledDate}</b>
                </React.Fragment>
              ) : (
                <Skeleton width={80} />
              )}
            </Typography>
            {renderPauseResumeHistory()}
          </div>
          {isPausable && (
            <Button
              variant="contained"
              style={{
                flexGrow: 3,
                backgroundColor: '#0000004A',
                fontSize: 16,
                padding: 10
              }}
              startIcon={<PlayArrowRoundedIcon style={{ fontSize: 24 }} />}
              onClick={resumeFA}
              disabled={isSubmitting || editFormAnswer.isMutating}
            >
              Retomar Trabalho
            </Button>
          )}
        </React.Fragment>
      ) : status?.status === 'in_inspection' ? (
        <div className={localClasses.banner}>
          <TimerRoundedIcon />
          <div>
            <Typography fontWeight={700} fontSize={16}>
              Aguardando dados de inspecção
            </Typography>
            {author && (
              <Typography variant="body2">
                Criada por <strong>{author}</strong>
              </Typography>
            )}
            <Typography
              variant={!!author ? 'body2' : 'body1'}
              fontWeight={!!author ? 400 : 700}
            >
              {filledDate ? (
                <React.Fragment>
                  Atividade iniciada em <b>{filledDate}</b>
                </React.Fragment>
              ) : (
                <Skeleton width={80} />
              )}
            </Typography>
            {renderPauseResumeHistory()}
            {endDate && (
              <Typography variant="body2">
                Concluída em{' '}
                <b>{endDate ? `${endDate}` : <Skeleton width={40} />}</b>
              </Typography>
            )}
            {renderFormInspections()}
          </div>
        </div>
      ) : (
        <React.Fragment>
          <WarningRoundedIcon />
          <div>
            {author && (
              <Typography fontWeight={700}>
                Criada por <strong>{author}</strong>
              </Typography>
            )}
            <Typography
              variant={!!author ? 'body2' : 'body1'}
              fontWeight={!!author ? 400 : 700}
            >
              {filledDate ? (
                <React.Fragment>
                  Atividade realizada em <b>{filledDate}</b>
                </React.Fragment>
              ) : (
                <Skeleton width={80} />
              )}
            </Typography>
            <Typography
              variant="body2"
              fontStyle="italic"
              color={theme.palette.grey[600]}
            >
              Status desconhecido
            </Typography>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
