import React, { useState } from 'react';
import { InputBase, Typography } from '@mui/material';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import { FormField } from '../../../../../vorotypes/types/formTemplate';

type FieldTextProps = {
  field: FormField;
  setAnswers: React.Dispatch<
    React.SetStateAction<{ field_id: string; answer: any }[]>
  >;
  multiline?: boolean;
};

export default function FieldText(props: FieldTextProps) {
  const { field, setAnswers, multiline } = props;
  /* Hooks */
  const formAnswer = useAppSelector(state => state.form.formAnswer);
  /* States */
  const [input, setInput] = useState<string>(formAnswer[field.field_id] || '');
  /* Functions */
  const updateAnswer = () =>
    setAnswers((prev: any) => {
      const payload = { field_id: field?.field_id, answer: input };
      if (!prev) return [payload];
      const prevAnswer = prev?.findIndex(
        (ans: any) => ans.field_id === field.field_id
      );
      if (prevAnswer > -1) {
        prev[prevAnswer] = payload;
        return [...prev];
      } else {
        return [...prev, payload];
      }
    });
  /* useEffects */
  React.useEffect(() => {
    updateAnswer();
    //eslint-disable-next-line
  }, [input]);
  /* --- Return --- */
  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h3" marginTop={1}>
        {field?.properties?.question}
      </Typography>
      {!!field.properties?.description && (
        <Typography variant='body2'>{field.properties.description}</Typography>
      )}
      <InputBase
        id="txt-input-answer"
        fullWidth
        multiline={multiline}
        placeholder={field?.properties?.placeholder || ''}
        style={{ fontSize: 14 }}
        value={input}
        onChange={evt => setInput(evt.target.value)}
        inputProps={
          !!field?.properties?.numeric
            ? { inputMode: 'numeric', pattern: '[0-9]*' }
            : undefined
        }
      />
    </div>
  );
}
