import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { IconButton, Grid, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FlipCameraIcon from '@mui/icons-material/FlipCameraAndroid';
import theme from '../../theme';
import useStyles from '../../useStyles';
import { useHistory } from 'react-router-dom';
import MaskWrapper from '../MaskWrapper';

export interface CameraResponse {
  base64: string | null | undefined;
  isSelfie: boolean;
  permissionState?: PermissionState | 'error';
}

type CameraProps = {
  onChange?: (x: CameraResponse) => void;
  onClose?: () => void;
  startInSelfieMode?: boolean;
  silhouette?: boolean;
  testingMode?: boolean;
};

export default function Camera(props: CameraProps) {
  /* Styles */
  const classes = useStyles(theme);

  /* States */
  const [camViewportWidth, setCamViewportWidth] = useState(window.innerWidth);
  const [camViewportHeight, setCamViewportHeight] = useState(
    window.innerHeight
  );
  const [videoConstraints, setVideoConstraints] =
    useState<MediaTrackConstraints>({
      facingMode: props?.startInSelfieMode ? 'selfie' : 'environment'
    });
  const [mirrored, setMirrored] = useState<boolean>(!!props?.startInSelfieMode);
  const [base64, setBase64] = useState<string | null>();
  const [permissionState, setPermissionState] = useState<
    PermissionState | 'error'
  >();

  /* Functions */
  function switchCamera() {
    if (videoConstraints.facingMode === 'environment') {
      setMirrored(true);
      setVideoConstraints({ facingMode: 'user' });
    } else {
      setMirrored(false);
      setVideoConstraints({ facingMode: 'environment' });
    }
  }
  const stopAllTracks = () => {
    let tracks = webcamRef?.current?.stream?.getTracks();
    if (!!tracks && tracks?.length > 1)
      webcamRef?.current?.stream?.getTracks().forEach(track => track.stop());
  };
  const captureCallback = () => {
    if (webcamRef.current !== null) {
      let src = webcamRef.current.getScreenshot();
      setBase64(src ?? '');
      stopAllTracks();
    }
  };
  const onCameraError = async (err: string | DOMException) => {
    console.error(err);
    alert('Nós não possuímos permissão para utilizar sua câmera.');
    if (props?.testingMode) {
      setBase64(null);
      setPermissionState('denied');
      close();
    } else history.push('/config');
  };
  const close = () => {
    stopAllTracks();
    if (props?.onClose) props.onClose();
  };
  const okCallback = () => {
    setBase64(null);
    setPermissionState('granted');
    close();
  };
  const errorCallback = () => {
    setBase64(null);
    setPermissionState('error');
    close();
  };
  const onChange = (x: CameraResponse) => props?.onChange && props.onChange(x);
  /* Hooks */
  const history = useHistory();
  const webcamRef = useRef<Webcam | null>(null);
  const capture = useCallback(captureCallback, [webcamRef, setBase64]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const okFunc = useCallback(okCallback, [webcamRef, setBase64]);
  const errorFunc = useCallback(errorCallback, [webcamRef, setBase64]);

  /* Use Effects */
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        window.innerHeight >= window.innerWidth ||
        window.innerWidth * (9 / 16) > window.innerHeight - 150
      ) {
        setCamViewportHeight(window.innerHeight - 150);
        setCamViewportWidth(window.innerHeight * (9 / 16));
      } else {
        setCamViewportHeight(window.innerWidth * (9 / 16));
        setCamViewportWidth(window.innerWidth);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    onChange({
      base64: base64,
      isSelfie: mirrored,
      permissionState: permissionState
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base64, mirrored, permissionState]);

  return (
    <div className={classes.camBackground}>
      <div className={classes.camViewport}>
        <MaskWrapper maskRelativeHeight={'80%'} active={!!props?.silhouette}>
          <Webcam
            ref={webcamRef}
            audio={false}
            width={camViewportWidth}
            height={camViewportHeight}
            screenshotFormat="image/jpeg"
            mirrored={mirrored}
            videoConstraints={videoConstraints}
            // onUserMediaError={(err) => {throw err}}
            onUserMediaError={onCameraError}
          />
        </MaskWrapper>
      </div>

      {!props?.testingMode ? (
        <Grid container style={{ height: 150 }}>
          <Grid item xs={4} style={{ display: 'flex' }}>
            <IconButton
              style={{ margin: 'auto', color: 'white' }}
              onClick={close}
            >
              <CloseIcon style={{ fontSize: 40 }} />
            </IconButton>
          </Grid>
          <Grid item xs={4} style={{ display: 'flex' }}>
            <IconButton
              style={{ backgroundColor: 'white', margin: 'auto' }}
              onClick={capture}
              id="btn-cam-capture"
            >
              <div style={{ height: 45, width: 45 }} />
            </IconButton>
          </Grid>
          <Grid item xs={4} style={{ display: 'flex' }}>
            <IconButton
              style={{ color: 'white', margin: 'auto' }}
              onClick={switchCamera}
            >
              <FlipCameraIcon style={{ fontSize: 40 }} />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          style={{
            height: 250,
            backgroundColor: theme.palette.background.paper
          }}
          padding={3}
          columnSpacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="body2">
              Estamos testando a sua câmera. Se a imagem da câmera aparecer
              corretamente, clique em "OK". Caso contrário, clique em "Erro".
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" onClick={okFunc}>
              Ok
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" onClick={errorFunc}>
              Erro
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
