import React from "react";
import theme from "../../theme";

type CheckBoxRowProps = {
  isChecked:boolean,
  children?: React.ReactNode,
}

export default function CheckBoxRow(props:CheckBoxRowProps) {
  return (
    <div style={{
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      opacity: props?.isChecked ? 1 : 0.45 }}
    >
      <div style={{
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 2,
        backgroundColor: theme.palette.common.white,
        height: 15,
        width: 15,
        minWidth: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {props?.isChecked &&
          <div style={{
            width: 7,
            height: 7,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 2
          }}/>
        }
      </div>
      {props?.children}
    </div>
  )
}