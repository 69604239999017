import { makeStyles } from '@mui/styles';
import theme from '../../theme';
import { CSSProperties } from 'react';

const defaultNotSelectedOption: CSSProperties = {
  width: 18,
  height: 18,
  backgroundColor: theme.palette.secondary.main
};

const defaultNotSelectedOptionMini: CSSProperties = {
  width: 13,
  height: 13,
  backgroundColor: theme.palette.secondary.main
};

const checkboxUseStyles = makeStyles(() => ({
  iconSelected: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 3,
    color: 'white',
    padding: 2
  },
  iconNotSelected: {
    color: theme.palette.secondary.main
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    borderRadius: 5,
    width: '100%',
    padding: '14px 16px'
  },
  optionMini: {
    padding: '4px 14px 4px 14px',
    width: 'fit-content',
    border: '1px solid',
    borderColor: 'rgba(20,20,60,0.2)',
    borderRadius: '24px'
  },
  optionSelected: {
    border: '1px solid',
    borderColor: 'transparent'
  },
  notSelectedOption: {
    ...defaultNotSelectedOption,
    borderRadius: 3
  },
  notSelectedOptionMini: {
    ...defaultNotSelectedOptionMini,
    borderRadius: 2
  },
  startIcon: {
    marginRight: 16
  }
}));

export default checkboxUseStyles;
