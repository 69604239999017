import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const formAnswerStyles = makeStyles(() => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  cellTitle: {
    fontSize: 18,
    lineHeight: '120%',
    fontWeight: 700
  },
  listAnswer: {
    paddingLeft: 25,
    marginTop: 4,
    marginBottom: 0,
    '& li': {
      fontSize: 14
    },
    '& li::marker': {
      fontSize: 13
    }
  },
  miniFlex: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  },
  formAuditContainer: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    width: '100%',
    borderRadius: 5,
    padding: '14px 14px 18px'
  },
  titleDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  emptySelection: {
    width: 28,
    height: 28,
    flex: '0 0 28px',
    marginLeft: 5,
    borderRadius: 28,
    backgroundColor: 'rgba(0,0,0,0.2)'
  },
  filledSelection: {
    width: 28,
    height: 28,
    flex: '0 0 28px',
    marginLeft: 5,
    borderRadius: 28,
    backgroundColor: theme.palette.error.main,
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center'
  },
  selected: {
    backgroundColor: `${theme.palette.error.light}80`,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    border: '1px solid'
  },
  inputDiv: {
    marginTop: 12,
    '&:before': {
      display: 'block',
      position: 'absolute',
      content: '""',
      borderTop: '1px solid',
      borderColor: theme.palette.error.main,
      width: 'calc(100% - 32px)',
      left: 16
    }
  },
  usersRissAnswerDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    marginTop: 8,
    width: '100%'
  },
  rissUserTip: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 4,
    backgroundColor: `${theme.palette.secondary.main}40`,
    borderRadius: 5,
    width: '100%',
    padding: '8px 12px'
  },
  userTip: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    marginBottom: 2
  },
  horizontalScroll: {
    overflowX: 'auto',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    '&::-webkit-scrollbar': {
      height: '3px'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.30)',
      marginLeft: 16,
      marginRight: 16
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main
    }
  },
  checkboxList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    marginTop: 8
  },
  photosContainer: {
    marginTop: 8,
    display: 'flex',
    gap: 8,
    width: '100vw',
    position: 'relative',
    left: -16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 4
  }
}));

export default formAnswerStyles;
