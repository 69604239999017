import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { urlToBase64Image } from '../../services/images';
import logAppEvent from '../../services/logAppEvent';
import { Photo } from '../../types/form';
import photosInputStyles from './styles';
import PhotoInput, { FileInputSource } from '../PhotoInput';

type PhotosInputProps = {
  photos: Photo[];
  onChange: (val: any[]) => void;
  disabled?: boolean;
  silhouette?: boolean;
  height?: number;
  coworkerId?: number;
  single?: boolean;
  buttonClassName?: string;
  preferredInput?: FileInputSource;
};

export default function PhotosInput(props: PhotosInputProps) {
  const { photos, onChange, disabled } = props;
  /* Styles */
  const localClasses = photosInputStyles();
  /* States */
  const [cameraDialogOpen, setCameraDialogOpen] = useState<boolean>(false);
  const [chosenImageSrc, setChosenImageSrc] = useState<string>('');
  const [isSelfie, setIsSelfie] = useState<boolean>(false);
  /* Functions */
  const addPhoto = (photo: Photo) => onChange([photo].concat([...photos]));
  const removePhoto = (id: string) => {
    onChange(photos.filter(photo => photo.id !== id));
  };
  /* Use Effects */
  useEffect(() => {
    const currentChosenImageSrc = chosenImageSrc;
    if (!!currentChosenImageSrc.length) {
      const id = uuidv4();
      urlToBase64Image(currentChosenImageSrc, 'image/jpeg')
        .then(base64 => {
          addPhoto({
            id: id,
            base64: base64,
            type: 'image/jpeg',
            selfie: isSelfie,
            coworker_id: props?.coworkerId || 0
          });
        })
        .catch(err => {
          console.log(err);
          logAppEvent(`[ERROR] PhotosInput @ urlToBase64Image: ${err}`);
        });
    }
    setChosenImageSrc('');
    // eslint-disable-next-line
  }, [chosenImageSrc]);
  /* Return component */
  return (
    <div className={localClasses.inputPhotoDiv}>
      <div>
        {(props?.single !== true ||
          (props?.single === true && photos?.length < 1)) && (
          <PhotoInput
            aria-label="upload picture"
            variant="contained"
            color="secondary"
            className={props?.buttonClassName}
            style={{ height: props?.height ?? 140 }}
            onChange={val => {
              setChosenImageSrc(val.base64 ?? '');
              setIsSelfie(val.isSelfie);
            }}
            disabled={disabled}
            preferredInput={props?.preferredInput}
          >
            <AddAPhotoIcon />
          </PhotoInput>
        )}
      </div>

      <div className={localClasses.horizontalScroll}>
        {photos
          .filter(photo => {
            if (!!props?.coworkerId) {
              return photo?.coworker_id === props.coworkerId;
            }
            return true;
          })
          .map((photo: Photo, index: number) => (
            <div key={index} style={{ position: 'relative' }}>
              <img
                style={{ borderRadius: 5 }}
                src={photo.base64}
                alt={`cancel-${index}`}
                height={props?.height || 140}
              />
              <IconButton
                className={localClasses.removePhotoButton}
                onClick={() => removePhoto(photo.id)}
              >
                <CancelRoundedIcon style={{ fontSize: 24 }} />
              </IconButton>
            </div>
          ))}
      </div>
    </div>
  );
}
