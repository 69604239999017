import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import BrokenImageRoundedIcon from '@mui/icons-material/BrokenImageRounded';
import { GeneralPhoto } from '../../../../types/form';
import formAnswerStyles from '../../styles';
import theme from '../../../../theme';
import LoadingImage from '../../../LoadingImage';
import useStyles from '../../../../useStyles';
import loadingImageStyles from '../../../LoadingImage/styles';

interface AnswerGeneralPhotosProps {
  photos: Array<GeneralPhoto>;
}

export default function AnswerGeneralPhotos(props: AnswerGeneralPhotosProps) {
  const { photos } = props;
  /* Styles */
  const classes = useStyles();
  const loadingImageClasses = loadingImageStyles();

  return (
    <React.Fragment>
      {photos.map((photoData, idxPhoto) => {
        if (!!photoData?.bucketData || !!photoData?.base64) {
          return (
            <LoadingImage
              key={photoData?.bucketData?.key ?? `photo-div-${photoData.id}`}
              bucketData={photoData?.bucketData}
              base64={photoData?.base64}
              width={150}
              amplifiable
              classes={{
                img: classes.loadingImage,
                skeleton: classes.loadingImage
              }}
            />
          );
        } else {
          return (
            <div
              key={`broken-photo-${idxPhoto}`}
              className={loadingImageClasses.notFoundDiv}
              style={{ width: 150, height: 200 }}
            >
              <BrokenImageRoundedIcon fontSize="large" color="secondary" />
              <Typography
                textAlign="center"
                color={theme.palette.secondary.dark}
              >
                Erro ao carregar imagem
              </Typography>
            </div>
          );
        }
      })}
    </React.Fragment>
  );
}
