import VorotechLogo from '../../../images/vorotech_logo.png';

type LogoBannerProps = {
  style?: React.CSSProperties;
  className?: string;
};

export default function LogoBanner(props: LogoBannerProps) {
  return <DefaultLogoBanner {...props} />;
}

function DefaultLogoBanner(props: LogoBannerProps) {
  return (
    <div style={{ ...props?.style }} className={props?.className}>
      <img
        src={VorotechLogo}
        alt="Logo Vorotech"
        width="100%"
        style={{ display: 'block' }} // used to keep the outer div with the same height of the <img>
      />
    </div>
  );
}
