import { z } from 'zod';

export const ReferencePeriodSchema = z.enum(['1-fortnight', '2-fortnight']);
export type ReferencePeriod = z.infer<typeof ReferencePeriodSchema>;

type ReferencePeriodDict = {
  [K in ReferencePeriod]: string;
};
export const REFERENCE_PERIOD_DICTIONARY: ReferencePeriodDict = {
  '1-fortnight': 'Primeira Quinzena',
  '2-fortnight': 'Segunda Quinzena'
};

export const SHORT_REFERENCE_PERIOD_DICTIONARY: ReferencePeriodDict = {
  '1-fortnight': '1ª Quinzena',
  '2-fortnight': '2ª Quinzena'
};

export const DqsCategorySchema = z.enum(['ordinary', 'extraordinary']);
export type DqsCategory = z.infer<typeof DqsCategorySchema>;

type DqsCategoryDict = {
  [K in DqsCategory]: string;
};
export const DQS_CATEGORY_DICTIONARY: DqsCategoryDict = {
  'ordinary': 'Padrão (Mensal)',
  'extraordinary': 'Extraordinário'
};
