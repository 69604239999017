import { SupportContact } from '../types/utils';

export default function getSupportContacts() {
  const contacts: SupportContact[] = [
    {
      name: 'Suporte',
      ddi: 55,
      ddd: 51,
      number: '9 9000 6383',
      company: 'Vorotech',
      description: 'Telefone, Whatsapp'
    }
  ];

  return contacts;
}
