import { Dialog, DialogProps, useTheme, Zoom } from '@mui/material';
import useStyles from '../../useStyles';
import useAlertSuccessStyles from './styles';

export default function SuccessDialog(props: DialogProps) {
  /* Styles */
  const theme = useTheme();
  const classes = useStyles(theme);
  const localClasses = useAlertSuccessStyles(theme);
  /* Return components */
  return (
    <Dialog
      PaperProps={{ className: localClasses.paper }}
      slotProps={{
        backdrop: { className: classes.dialogBackdrop }
      }}
      {...props}
    >
      <Zoom in timeout={500} easing={{ enter: 'cubic-bezier(0, 0, 0.2, 1.3)' }}>
        <div className={localClasses.contentDiv}>
          <div className={localClasses.checkmark}>
            <DrawingCheckIcon />
          </div>
        </div>
      </Zoom>
    </Dialog>
  );
}

function DrawingCheckIcon() {
  const theme = useTheme();
  const localClasses = useAlertSuccessStyles(theme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      viewBox="0 0 100 100"
      fill="none"
      display="block"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6326 48.604L45.73 65.810375L70.5959375 36.89232"
        stroke="white"
        strokeWidth="10"
        className={localClasses.checkPath}
      />
    </svg>
  );
}
