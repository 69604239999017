import { makeStyles } from '@mui/styles';

const useDiscreteLoadingStyles = makeStyles(theme => ({
  mainDiv: {
    width: '100%',
    display: 'flex',
    gap: 8,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8
  }
}));

export default useDiscreteLoadingStyles;
