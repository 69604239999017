import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const loadingImageStyles = makeStyles({
  notFoundDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 8,
    gap: 8,
    backgroundColor: `${theme.palette.secondary.main}20`,
    borderRadius: 5
  }
});

export default loadingImageStyles;
