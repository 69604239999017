/* Global imports */
import React, { useEffect, useState } from 'react';
import { Button, IconButton, InputBase, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';
/* Icon imports */
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import LibraryAddRoundedIcon from '@mui/icons-material/LibraryAddRounded';
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded';
/* Local imports */
import { conditionsSatisfied } from '../FieldList/helpers';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import CheckBoxOption from '../../../../components/CheckBoxOption';
import fieldCheckboxUseStyles from './styles';
import { orderFormFieldOptions } from '../../../../utils/form';
/* Type imports */
import {
  FormField,
  FormFieldOption
} from '../../../../../vorotypes/types/formTemplate';

type FieldCheckboxProps = {
  field: FormField;
  setAnswers: React.Dispatch<
    React.SetStateAction<{ field_id: string; answer: any }[]>
  >;
  onEmptyField?: (val: boolean) => void;
};

export default function FieldCheckbox(props: FieldCheckboxProps) {
  const { field, setAnswers, onEmptyField } = props;
  const localClasses = fieldCheckboxUseStyles();
  /* Hooks */
  const formAnswer = useAppSelector(state => state.form.formAnswer);
  const formComplements = useAppSelector(state => state.form.formComplements);
  /* States */
  const [checkedBoxes, setCheckedBoxes] = useState<string[]>(
    formAnswer[field.field_id]?.filter((op: any) => typeof op === 'string') ||
      []
  );
  const [options, setOptions] = React.useState(field.properties?.options);
  const exclusiveOptions = React.useMemo(() => {
    /* useMemo() is used to avoid evaluating this expression to be  on every render */
    return options?.filter(option => option.type === 'exclusive') ?? [];
  }, [options]);
  const loadedAdditionalOptions =
    formAnswer[field.field_id]
      ?.find((ans: any) => !!ans?.others)
      ?.others?.map(
        (op: FormFieldOption) => Object({ ...op }) // This is needed to make a deep copy of the formFieldOption object that would be read-only otherwise
      ) ?? [];
  const [additionalOptions, setAdditionalOptions] = useState<FormFieldOption[]>(
    loadedAdditionalOptions
  );
  const [enableAddButton, setEnableAddButton] = useState<boolean>(
    !!field?.hasOtherOption
  );
  /* Functions */
  const addOption = () => {
    const uniqueId = uuid();
    let updatedAdditionalOptions = [...additionalOptions];
    updatedAdditionalOptions.push({
      option_id: uniqueId,
      text: ''
    });
    setAdditionalOptions(updatedAdditionalOptions);
  };
  const removeOption = (id: string) => {
    let updatedAdditionalOptions = [...additionalOptions];
    updatedAdditionalOptions = updatedAdditionalOptions.filter(
      option => option.option_id !== id
    );
    setAdditionalOptions(updatedAdditionalOptions);
  };
  const updateAnswer = () =>
    setAnswers((prev: any) => {
      let answerToSave: any = [...checkedBoxes];
      const validAdditionalOptions = additionalOptions?.filter(
        op => !!op.text?.length
      );
      if (!!validAdditionalOptions?.length)
        answerToSave.push({ others: validAdditionalOptions });

      const payload = { field_id: field?.field_id, answer: answerToSave };
      if (!prev) return [payload];
      const prevAnswer = prev?.findIndex(
        (ans: any) => ans.field_id === field.field_id
      );
      if (prevAnswer > -1) {
        prev[prevAnswer] = payload;
        return [...prev];
      } else {
        return [...prev, payload];
      }
    });
  const updateOption = (evt: any, option_id: string) => {
    let updatedAdditionalOptions = [...additionalOptions];
    let selectedOption = updatedAdditionalOptions.find(
      (op: FormFieldOption) => op.option_id === option_id
    );
    if (!!selectedOption) {
      selectedOption.text = evt.target.value;
      setAdditionalOptions(updatedAdditionalOptions);
    }
  };
  const handleKeyDownCapture = (evt: any, option_id: string) => {
    let updatedAdditionalOptions = [...additionalOptions];
    let selectedOption = updatedAdditionalOptions.find(
      (op: FormFieldOption) => op.option_id === option_id
    );
    if (
      evt.keyCode === 8 &&
      evt.target.value === '' &&
      selectedOption?.text === ''
    ) {
      removeOption(option_id);
    }
  };
  /* useEffects */
  useEffect(() => {
    const hasEmptyOption = additionalOptions.some(option => option.text === '');
    onEmptyField && onEmptyField(hasEmptyOption);
  }, [additionalOptions, onEmptyField]);
  useEffect(() => {
    updateAnswer();
    //eslint-disable-next-line
  }, [checkedBoxes]);
  useEffect(() => {
    if (additionalOptions.length < 1)
      setEnableAddButton(!!field?.hasOtherOption);
    else if (
      !additionalOptions.find((op: FormFieldOption) => !op.text?.length)
    ) {
      setEnableAddButton(
        !!field?.hasOtherOption && !field?.properties?.singleChoice
      );
    } else setEnableAddButton(false);
    if (
      additionalOptions.length > 0 &&
      !!field?.properties?.singleChoice &&
      checkedBoxes.length > 0
    )
      setCheckedBoxes([]);
    updateAnswer();
    // eslint-disable-next-line
  }, [additionalOptions]);
  useEffect(() => {
    let ordering = field?.properties?.ordering;
    let options = [];
    if (ordering === 'ascending') {
      options = orderFormFieldOptions(field.properties?.options ?? []);
    } else if (ordering === 'descending') {
      options = orderFormFieldOptions(
        field.properties?.options ?? []
      ).reverse();
    }
    // SHUFFLED OPTIONS ARE NOT BEING CONSIDERED YET
    // else if (ordering === 'shuffle') {
    //   options = shuffle_options(field.properties?.options || [], shuffleSeed);
    // }
    else options = field.properties?.options || [];
    options = options?.filter(op =>
      conditionsSatisfied(op.conditions, formAnswer, formComplements)
    );
    setOptions(options);

    //eslint-disable-next-line
  }, [field, formAnswer]);
  useEffect(() => {
    if (
      checkedBoxes.some(opt =>
        exclusiveOptions.find(excl => excl.option_id === opt)
      )
    ) {
      setEnableAddButton(false);
    } else if (!!field?.properties?.singleChoice && !!field?.hasOtherOption) {
      setEnableAddButton(additionalOptions.length < 1);
      if (additionalOptions.length > 0 && checkedBoxes.length > 0)
        setAdditionalOptions([]);
    } else {
      setEnableAddButton(!!field?.hasOtherOption);
    }
    // eslint-disable-next-line
  }, [checkedBoxes, exclusiveOptions, field]);
  useEffect(() => {
    setCheckedBoxes(prev =>
      prev.filter(cb => options?.map(op => op.option_id).includes(cb))
    );
  }, [options]);
  /* --- Return --- */
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 8
      }}
    >
      <div>
        <Typography
          variant={!!field?.properties?.minified ? 'body1' : 'h3'}
          marginTop={2}
          textAlign="left"
        >
          {field?.properties?.question}
        </Typography>
        {field?.properties?.question !== field?.properties?.description && (
          <Typography marginTop={1} textAlign="left" whiteSpace="pre-line">
            {field?.properties?.description}
          </Typography>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          gap: 8,
          flexWrap: 'wrap',
          flexDirection: 'column'
        }}
      >
        {options?.map(option => {
          let disabled = false;
          if (option?.type === 'exclusive') {
            disabled =
              additionalOptions.length > 0 ||
              checkedBoxes.some(opt => opt !== option.option_id);
          } else {
            disabled = exclusiveOptions?.some(option =>
              checkedBoxes.includes(option.option_id)
            );
          }
          return (
            <CheckBoxOption
              key={option.text}
              id={option.option_id}
              text={option.text}
              checkedBoxes={checkedBoxes}
              setCheckedBoxes={setCheckedBoxes}
              singleChoice={!!field?.properties?.singleChoice}
              mini={!!field?.properties?.minified}
              disabled={disabled}
            />
          );
        })}
        {additionalOptions.map(({ option_id, text }, idx) => (
          <div
            key={option_id}
            className={
              text !== ''
                ? localClasses.validAdditionalOptionContainer
                : localClasses.emptyAdditionalOptionContainer
            }
          >
            <LibraryAddCheckRoundedIcon style={{ fontSize: 18 }} />
            <InputBase
              id={`txt-input-${idx}`}
              multiline
              autoFocus
              className={localClasses.inputBase}
              value={text}
              onKeyDownCapture={evt => handleKeyDownCapture(evt, option_id)}
              onChange={evt => updateOption(evt, option_id)}
            />
            {!field?.properties?.singleChoice && (
              <IconButton
                data-testid={`bnt-deleteOption-${idx}`}
                style={{ color: 'inherit', padding: 5.5 }}
                onClick={() => removeOption(option_id)}
              >
                <DeleteRoundedIcon style={{ fontSize: 18 }} />
              </IconButton>
            )}
          </div>
        ))}
        {enableAddButton && (
          <Button
            data-testid={`btn-add-option-${field.field_id}`}
            variant="outlined"
            className={localClasses.addConditionButton}
            onClick={addOption}
          >
            <LibraryAddRoundedIcon style={{ fontSize: 18 }} />
            <Typography>Adicionar outra opção</Typography>
          </Button>
        )}
      </div>
    </div>
  );
}
