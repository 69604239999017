import axios, { AxiosInstance, GenericAbortSignal } from 'axios';
import Cookies from 'js-cookie';

import { version } from '../version.json';

export const API_TIMEOUT = 30000;

interface UseAPIClient
  extends Pick<AxiosInstance, 'get' | 'post' | 'patch' | 'put'> {
  abortAllRequests: () => void; // Cancels
}

/**
 * This class serves as a wrapper around an axios instance to
 * provide additional features like request interruption, for example.
 * @author Douglas Flores
 */
class APIClient implements UseAPIClient {
  controller = new AbortController();
  instance = axios.create({
    baseURL: import.meta.env.REACT_APP_API_ENDPOINT,
    signal: this.controller.signal
  });
  constructor() {
    this.appendReqInterceptors(this.instance);
    this.appendResInterceptors(this.instance);
  }
  /**
   * Cancels all axios' requests from this instance
   * and creates a new instance
   */
  abortAllRequests() {
    this.controller.abort();
    const newController = new AbortController();
    this.controller = newController;
    this.instance = this.create(
      import.meta.env.REACT_APP_API_ENDPOINT,
      newController.signal
    );
  }
  appendReqInterceptors(targetApi: AxiosInstance) {
    targetApi.interceptors.request.use(req => {
      const token = `Bearer ${Cookies.get('token')}`;
      req.headers.Authorization = token;
      req.headers['vorotech-app-version'] = version;
      return req;
    });
  }
  appendResInterceptors(targetApi: AxiosInstance) {
    targetApi.interceptors.response.use(
      res => {
        let headers = res.headers;
        const token = Cookies.get('token');
        let newToken = headers['vorotech-token'];
        if (!!token && newToken) {
          Cookies.set('token', newToken, { expires: 365 });
        }
        return res;
      },
      err => {
        if (
          err.response?.status === 401 ||
          err?.response?.data?.internalCode === 'ERR_USER_NOT_ACTIVE'
        ) {
          Cookies.remove('token');
        }
        throw err;
      }
    );
  }
  /**
   * Creates a new axios instance
   * @param baseURL app's base url
   * @param signal an abort signal from a AbortController
   * @returns a new axios instance
   */
  create(baseURL: string, signal: GenericAbortSignal) {
    const newinstance = axios.create({ baseURL, signal });
    this.appendReqInterceptors(newinstance);
    this.appendResInterceptors(newinstance);
    return newinstance;
  }
  /* Getters as shortcuts to api's most used functions */
  get get() {
    return this.instance.get.bind(this.instance);
  }
  get patch() {
    return this.instance.patch.bind(this.instance);
  }
  get post() {
    return this.instance.post.bind(this.instance);
  }
  get put() {
    return this.instance.put.bind(this.instance);
  }
}

const api = new APIClient();

export default api;
