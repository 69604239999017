/* Global imports */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme
} from '@mui/material';
/* Icon imports */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
/* Local imports */
import fieldUserStyles from '../styles';
import RenderCoworkers from '../RenderCoworkers';
import { Coworker } from '../../../../../../vorotypes/types/user';
import { forwardRef } from 'react';

interface AnswerSummaryProps {
  coworkers?: Array<Coworker>; // selected coworkers
  onClickItem?: (id: number) => void;
  disabled?: boolean;
}

const AnswerSummary = forwardRef(
  (
    props: AnswerSummaryProps,
    ref: React.ForwardedRef<HTMLDivElement | null>
  ) => {
    /* Props */
    const { coworkers, onClickItem, disabled } = props;
    /* Styles */
    const theme = useTheme();
    const localClasses = fieldUserStyles(theme);
    /* Return component */
    return (
      <Accordion
        disableGutters
        square
        variant="outlined"
        sx={{
          backgroundColor: theme.palette.grey[100],
          margin: '12px 16px 0px',
          boxShadow: 'none',
          borderRadius: '5px 5px',
          paddingLeft: '8px',
          '::before': {
            display: 'none'
          }
        }}
        ref={ref}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={localClasses.numberBadge}>
            <Typography variant="body2" color="white" fontWeight={600}>
              {coworkers?.length}
            </Typography>
          </div>
          <Typography fontWeight={500}>Usuários selecionados</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RenderCoworkers
            coworkers={coworkers ?? []}
            onClickItem={disabled ? undefined : onClickItem}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default AnswerSummary;
