import React from 'react';

import { Button, Typography } from '@mui/material';

import { useHistory } from 'react-router-dom';

import singleSignOnUseStyles from '../SignIn/style';
import useAlert from '../../hooks/useAlert';
import SnackbarAlert from '../../components/SnackbarAlert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MiscrosoftLogo from '../../images/microsoft_logo.svg?react';
import useStyles from '../../useStyles';
import LogoBanner from '../SignIn/components/logoBanner';

export default function SingleSignOn(props: any) {
  const classes = useStyles();
  const singleSignOnClasses = singleSignOnUseStyles();
  const history = useHistory();
  const alert = useAlert();
  const paperClass = singleSignOnClasses.loginPaper;
  const logoClass = singleSignOnClasses.companyBannerDefault;

  return (
    <div className={singleSignOnClasses.root}>
      <div className={paperClass}>
        <LogoBanner className={logoClass} />
        <div className={singleSignOnClasses.lowerDiv}>
          <Typography color="white">Opções de acesso:</Typography>
          <Button
            id="btn-sso"
            fullWidth
            variant="contained"
            className={classes.ssoButton}
            onClick={() => history.push('/sso/microsoft')}
            startIcon={<MiscrosoftLogo />}
          >
            Entrar com Conta Microsoft
          </Button>
        </div>
        <div className={singleSignOnClasses.backDiv}>
          <Button
            id="btn-login"
            variant="text"
            onClick={() => history.push('/')}
            style={{ alignSelf: 'flex-start', color: 'white' }}
            startIcon={<ArrowBackIcon />}
          >
            Voltar
          </Button>
        </div>
      </div>
      <SnackbarAlert {...alert.state} style={{ marginTop: 16 }} />
    </div>
  );
}
