import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type tempPhoto = string | null;
const initialTempPhoto = null as tempPhoto;

export const tempPhotoSlice = createSlice({
  name: 'tempPhoto',
  initialState: initialTempPhoto,
  reducers: {
    setTempPhoto: (state, action: PayloadAction<string>) => {
      state = action.payload;
    },
    clearTempPhoto: (state) => {
      state = null;
    }
  }
})

export const {
  setTempPhoto,
  clearTempPhoto
} = tempPhotoSlice.actions;
export default tempPhotoSlice.reducer;