import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ParticipantSignature, Photo } from '../types/form';
import { Certification } from '../../vorotypes/types/certification';
import {
  Complement,
  FormFieldOption,
  FormTemplate,
  RequiredCertification,
  Template
} from '../../vorotypes/types/formTemplate';
import { SnackbarInfo } from '../../vorotypes/types/utils';

interface RoadBlocker {
  field_id: string;
  snackbarInfo: SnackbarInfo;
}
interface formState {
  step: number;
  formAnswer: any;
  formComplements?: { [key: string]: any };
  formTemplateId?: number;
  formTemplate?: FormTemplate;
  participantSignatureList?: Array<ParticipantSignature>;
  participantCurrentlySigning?: number;
  certificationsList?: Array<Certification>;
  requiredCertifications?: Array<RequiredCertification>;
  groupAuthPhotos?: Array<Photo>;
  roadBlockers?: Array<RoadBlocker>;
  clearing?: boolean;
  formAnswerComplete?: any;
}

const initialFormState = {
  step: 0,
  formAnswer: {},
  formTemplateId: undefined,
  formTemplate: undefined,
  participantSignatureList: [],
  participantCurrentlySigning: -1,
  requiredCertifications: [],
  clearing: false
} as formState;

export const formSlice = createSlice({
  name: 'form',
  initialState: initialFormState,
  reducers: {
    clearFormanswer: state => {
      state.formAnswer = {};
      state.participantSignatureList = [];
      state.formTemplate = undefined;
      state.participantCurrentlySigning = -1;
      state.step = 0;
      state.formTemplateId = undefined;
      state.requiredCertifications = [];
      state.formComplements = undefined;
      state.formAnswerComplete = undefined;
    },
    clearForms: state => {
      state.formAnswer = initialFormState;
    },
    incrStep: state => {
      state.step = state.step + 1;
    },
    initParticipantSignatureList: (state, action: PayloadAction<number>) => {
      let localParticipantsSignatureList: Array<ParticipantSignature> = [];
      const formTemplate = state.formTemplate;
      const template = formTemplate?.template;
      let userFields = template?.steps.flatMap(step =>
        step.fields.filter(field =>
          ['User', 'Users'].includes(field?.type ?? '')
        )
      );
      userFields?.forEach(field => {
        const users = state.formAnswer[field.field_id] as Array<number>;
        users?.forEach(id_user => {
          const match = localParticipantsSignatureList?.find(
            ps => ps.id === id_user
          );
          if (!match) {
            localParticipantsSignatureList.push({
              id: id_user,
              hasSigned: false,
              date: undefined,
              isLeader: id_user === action.payload,
              signatureImage: undefined,
              photo: undefined,
              personalId: undefined
            });
          }
        });
      });
      // TODO: remove this if after coworkersFleet step is set to have type "Users"
      if (Object.keys(state.formAnswer).find(key => key === 'performers')) {
        const coworkers = state.formAnswer['performers'] as Array<number>;
        coworkers?.forEach(id_user => {
          const match = localParticipantsSignatureList?.find(
            ps => ps.id === id_user
          );
          if (!match) {
            localParticipantsSignatureList.push({
              id: id_user,
              hasSigned: false,
              date: undefined,
              isLeader: id_user === action.payload,
              signatureImage: undefined,
              photo: undefined,
              personalId: undefined
            });
          }
        });
      }
      state.participantSignatureList = [...localParticipantsSignatureList];
    },
    initParticipantSignatureListOld: (state, action: PayloadAction<number>) => {
      if (
        !state.participantSignatureList?.length &&
        state.formAnswer.performers
      )
        state.participantSignatureList = state.formAnswer.performers.map(
          (id_user: number) => {
            return {
              id: id_user,
              hasSigned: false,
              date: undefined,
              isLeader: id_user === action.payload
            };
          }
        );
    },
    updateParticipantSignatureList: (state, action: PayloadAction<number>) => {
      const formTemplate = state.formTemplate;
      const template = formTemplate?.template;
      let userFields = template?.steps.flatMap(step =>
        step.fields.filter(
          field =>
            ['User', 'Users'].includes(field?.type ?? '') &&
            field?.properties?.nonSigning !== true
        )
      );
      let userListBloated = userFields?.flatMap(
        field => state.formAnswer[field.field_id]
      );

      const coworkersFieldExists = Object.keys(state.formAnswer).find(
        key => key === 'performers'
      );
      const coworkersIsTypeUsers = userFields?.some(
        field => field.field_id === 'performers'
      );
      if (coworkersFieldExists && !coworkersIsTypeUsers)
        userListBloated = [
          ...(userListBloated ?? []),
          ...state.formAnswer['performers']
        ];

      const userList = userListBloated
        ?.filter((usr, idx) => !!usr && userListBloated?.indexOf(usr) === idx)
        .sort((a, b) => a - b) as Array<number>;

      let signingList = state.participantSignatureList
        ?.map(ps => ps.id)
        .sort((a, b) => a - b);

      if (JSON.stringify(userList) !== JSON.stringify(signingList)) {
        let localCoworkersSignatureList:
          | Array<ParticipantSignature>
          | undefined = [];
        localCoworkersSignatureList = userList?.map(
          id_user =>
            Object({
              id: id_user,
              hasSigned: false,
              date: undefined,
              isLeader: id_user === action.payload,
              signatureImage: undefined,
              photo: undefined,
              personalId: undefined
            }) as ParticipantSignature
        );
        state.participantSignatureList = [
          ...(localCoworkersSignatureList ?? [])
        ];
      }
      // else {
      //   let updatedSignatureList = state.participantSignatureList?.map(
      //     ps => Object({ ...ps, hasSigned: false }
      //   ));
      //   state.participantSignatureList = [...(updatedSignatureList ?? [])];
      // }
    },
    resetParticipantsSignaturesStates: state => {
      let updatedList = [...(state.participantSignatureList ?? [])]?.map(p =>
        Object({
          ...p,
          hasSigned: false
        })
      );
      state.participantSignatureList = [...(updatedList ?? [])];
    },
    resetParticipantSignatureList: state => {
      state.participantSignatureList = [];
      state.groupAuthPhotos = undefined;
    },
    setFormanswer: (state, action: PayloadAction<any>) => {
      state.formAnswer = action.payload;
    },
    setFormAnswerComplete: (state, action: PayloadAction<any>) => {
      state.formAnswerComplete = action.payload;
    },
    setFormTemplate: (state, action: PayloadAction<FormTemplate>) => {
      state.formTemplate = action.payload;
    },
    setFormTemplateId: (state, action: PayloadAction<number | undefined>) => {
      state.formTemplateId = action.payload;
    },
    setGroupAuthPhotos: (state, action: PayloadAction<Photo[]>) => {
      state.groupAuthPhotos = action.payload;
    },
    setParticipantCurrentlySigning: (state, action: PayloadAction<any>) => {
      state.participantCurrentlySigning = action.payload;
    },
    setParticipantSignatureList: (
      state,
      action: PayloadAction<Array<ParticipantSignature>>
    ) => {
      state.participantSignatureList = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    updateFormanswer: (state, action: PayloadAction<any>) => {
      let tempForm = state.formAnswer;
      tempForm[action.payload.field_id] = action.payload.answer;
      state.formAnswer = tempForm;
    },
    removeFormanswer: (state, action: PayloadAction<any>) => {
      let tempForm = state.formAnswer;
      delete tempForm[action.payload.field_id];
      state.formAnswer = tempForm;
    },
    updateFormComplements: (state, action: PayloadAction<Complement>) => {
      let tempComplements = state.formComplements ?? {};
      tempComplements[action.payload.id] = action.payload.value;
      state.formComplements = tempComplements;
    },
    setImpeditiveConditions: (state, action: PayloadAction<any>) => {
      let tempForm = { ...state.formAnswer };
      tempForm[action.payload.field_id] = action.payload.answer;
      if (!!action.payload.desired_steps) {
        let auxForm: any = {};
        action.payload.desired_steps.forEach(
          (field: any) => (auxForm[field] = tempForm[field])
        );
        tempForm = auxForm;
      }
      state.formAnswer = tempForm;
    },
    updateSignatureStatus: (state, action: PayloadAction<any>) => {
      if (state.participantSignatureList?.length) {
        let currentSignatures = [...state.participantSignatureList];
        currentSignatures[action.payload.idx].hasSigned = action.payload.status;
        if (action.payload.status === true)
          currentSignatures[action.payload.idx].date = new Date().toISOString();
        else currentSignatures[action.payload.idx].date = undefined;
        state.participantSignatureList = currentSignatures;
      }
    },
    updateParticipantSignature: (
      state,
      action: PayloadAction<ParticipantSignature>
    ) => {
      const targetId = action.payload.id;
      var updatedList = [
        ...(state.participantSignatureList?.filter(
          sig => sig.id !== targetId
        ) || [])
      ];
      updatedList.push(action.payload);
      state.participantSignatureList = updatedList;
    },
    pushRoadBlocker: (state, action: PayloadAction<RoadBlocker>) => {
      const old =
        state?.roadBlockers?.filter(
          rb => rb.field_id !== action.payload.field_id
        ) ?? [];
      state.roadBlockers = [...old, action.payload];
    },
    popRoadBlocker: (state, action: PayloadAction<string>) => {
      const filtered =
        state.roadBlockers?.filter(rb => rb.field_id !== action.payload) ?? [];
      state.roadBlockers = [...filtered];
    },
    resetRoadBlockers: state => {
      state.roadBlockers = undefined;
    },
    pushRequiredCertification: (
      state,
      action: PayloadAction<RequiredCertification>
    ) => {
      state.requiredCertifications?.push(action.payload);
    },
    removeRequiredCertification: (state, action: PayloadAction<number>) => {
      state.requiredCertifications?.splice(action.payload, 1);
    },
    updateRequiredCertifications: state => {
      const template = state.formTemplate?.template as Template | undefined;
      const formAnswer = state.formAnswer;

      // all checkbox fields from template
      const allCheckBoxFields =
        template?.steps
          ?.flatMap(step => step.fields)
          ?.filter(f => !!f && f.type === 'CheckBox') ?? [];
      // all marked options
      const allMarkedOptions = (allCheckBoxFields
        ?.flatMap(field => {
          return field.properties.options?.filter(
            op => formAnswer[field.field_id]?.includes(op.option_id)
          );
        })
        ?.filter(op => !!op) ?? []) as FormFieldOption[];
      // all options with restrictions
      const allRestrictedOptions = allMarkedOptions.filter(
        op => !!op?.required_certifications?.length
      );
      // all not overwrirren required_certifications
      const notOverwritten = allRestrictedOptions.flatMap(op => {
        const overwritables = op.required_certifications?.filter(
          rc => !!rc?.overwritten_by
        );
        if (!overwritables?.length) {
          return op.required_certifications
            ? [...op.required_certifications]
            : [undefined];
        } else {
          let options_ids = allRestrictedOptions.map(aro => aro.option_id);
          return op.required_certifications?.filter(
            rc => !options_ids.includes(rc?.overwritten_by ?? '')
          );
        }
      });

      const restrictions: RequiredCertification[] = notOverwritten.filter(
        r => !!r
      ) as RequiredCertification[];
      state.requiredCertifications = restrictions;
    },
    setClearing: (state, action: PayloadAction<boolean>) => {
      state.clearing = action.payload;
    }
  }
});

export const {
  clearFormanswer,
  clearForms,
  incrStep,
  initParticipantSignatureList,
  initParticipantSignatureListOld,
  updateParticipantSignatureList,
  resetParticipantSignatureList,
  setFormanswer,
  setFormAnswerComplete,
  setFormTemplate,
  setFormTemplateId,
  setGroupAuthPhotos,
  setParticipantCurrentlySigning,
  setParticipantSignatureList,
  setStep,
  updateFormanswer,
  removeFormanswer,
  updateFormComplements,
  updateParticipantSignature,
  setImpeditiveConditions,
  updateSignatureStatus,
  resetParticipantsSignaturesStates,
  pushRoadBlocker,
  popRoadBlocker,
  resetRoadBlockers,
  updateRequiredCertifications,
  setClearing
} = formSlice.actions;
export default formSlice.reducer;
