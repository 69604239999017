import React from 'react';
import Typography from '@mui/material/Typography';
import PhotosInput from '../../../../components/PhotosInput';
import { DetachedPhotosAnswer } from '../../../../types/form';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import theme from '../../../../theme';
import { FormField } from '../../../../../vorotypes/types/formTemplate';

type FieldPhotoProps = {
  field: FormField;
  setAnswers: React.Dispatch<
    React.SetStateAction<{ field_id: string; answer: any }[]>
  >;
  small?: boolean;
};

export default function FieldPhoto(props: FieldPhotoProps) {
  const { field, setAnswers } = props;
  /* Hooks */
  const formAnswer = useAppSelector(state => state.form.formAnswer);
  /* States */
  let initValue = formAnswer[field.field_id] || [];
  const [photos, setPhotos] = React.useState<DetachedPhotosAnswer>(initValue);
  /* Functions */
  const updateAnswer = () =>
    setAnswers((prev: any) => {
      const payload = { field_id: field?.field_id, answer: photos };
      if (!prev) return [payload];
      const prevAnswer = prev?.findIndex(
        (ans: any) => ans.field_id === field.field_id
      );
      if (prevAnswer > -1) {
        prev[prevAnswer] = payload;
        return [...prev];
      } else {
        return [...prev, payload];
      }
    });
  /* useEffects */
  React.useEffect(() => {
    updateAnswer();
    //eslint-disable-next-line
  }, [photos]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 12
      }}
    >
      <div>
        <Typography
          marginTop={1}
          marginBottom={1}
          variant={props?.small ? 'body1' : 'h3'}
          display="flex"
          alignItems="center"
          gap={1}
          fontWeight={props?.small ? 500 : 600}
        >
          {field.properties.question}{' '}
          {!field?.properties?.required && (
            <span
              style={{
                fontSize: 12,
                color: theme.palette.grey[600],
                fontWeight: 400
              }}
            >
              (Opcional)
            </span>
          )}
        </Typography>
        {field?.properties?.question !== field?.properties?.description && (
          <Typography>{field?.properties?.description}</Typography>
        )}
      </div>
      <PhotosInput
        photos={photos}
        onChange={e => setPhotos(e)}
        height={100}
        preferredInput={field?.properties?.preferredInput}
      />
    </div>
  );
}
