import { GPSPosition } from '../../vorotypes/types/utils';
import { timeoutPromise } from '../utils/promises';

export async function getCurrentPosition(): Promise<GPSPosition> {
  const gpspos = new Promise<GPSPosition>(resolve => {
    try {
      navigator.geolocation.getCurrentPosition(
        pos => {
          console.log(pos.coords);
          const response: GPSPosition = {
            lat: pos.coords.latitude,
            long: pos.coords.longitude,
            acc: pos.coords.accuracy
          };
          resolve(response);
        },
        err => {
          console.warn(err);
          const errorResponse: GPSPosition = {
            lat: 0,
            long: 0,
            acc: 0,
            err: err.message
          };
          resolve(errorResponse);
        }
      );
    } catch (err) {
      console.warn(err);
      const errorResponse: GPSPosition = {
        lat: 0,
        long: 0,
        acc: 0,
        err: 'Caught error within getCurrentPosition'
      };
      resolve(errorResponse);
    }
  });
  try {
    const result = (await timeoutPromise(gpspos, 6000, 'GPS')) as GPSPosition;
    return result;
  } catch (error) {
    console.warn(error);
    const zeroPosition = {
      lat: 0,
      long: 0,
      acc: 0
    };
    if (error instanceof Error && error?.message === 'Promise timed out') {
      const errorResponse: GPSPosition = {
        ...zeroPosition,
        err: 'GPS request timed out'
      };
      return errorResponse;
    } else {
      const errorResponse: GPSPosition = {
        ...zeroPosition,
        err: 'Unknown error in getCurrentPosition'
      };
      return errorResponse;
    }
  }
}
