import z from 'zod';
import { ParticipantJobSchema } from '../schemas/participant.zod';

export type ParticipantJob = z.infer<typeof ParticipantJobSchema>;

type ParticipantJobDict = {
  [K in ParticipantJob]: string;
};

export const PARTICIPANT_JOB_DICTIONARY: ParticipantJobDict = {
  'performer': 'executante',
  'approver': 'aprovador',
  'co-approver': 'co-aprovador',
  'watchman': 'vigia',
  'requester': 'requisitante',
  'coordinator': 'coordenador',
  'inspector': 'inspetor',
  'inspected': 'inspecionado',
  'instructed': 'participante',
  'instructor': 'instrutor'
} as const;
