export function svg2uri(element:Node) : string {
  var svgXML = new XMLSerializer().serializeToString(element);
  return "data:image/svg+xml," + encodeURIComponent(svgXML);
}

export function svg2png(svg:any, scale=1) : Promise<string> {
  return new Promise((resolve) => {
    /* Creating canvas element */
    var canvas = document.createElement("canvas");
    var svgSize = svg.getBoundingClientRect();
    const img_width = (canvas.width = svgSize.width * scale);
    const img_height = (canvas.height = svgSize.height * scale);
    var ctx = canvas.getContext("2d");
    /* Creating img element */
    var img = document.createElement("img");
    img.setAttribute("src", svg2uri(svg));
    /* Drawing image into the canvas */
    img.onload = () => {
      if (!!ctx) ctx.drawImage(img, 0, 0, img_width, img_height);
      resolve(canvas.toDataURL("image/png"));
    };
  });
}

export function downloadURI(uri:string, name:string) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}