/* Global imports */
import React, { useMemo, useRef, useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
/* Local imports */
import { useAppSelector } from '../../hooks/redux.hooks';
import FormCamera from '../FormCamera';
import { CameraResponse } from '../Camera';
import { useStateRef } from '../../hooks/custom.hooks';

export type FileInputSource = 'camera' | 'gallery';

interface PhotoInputProps extends Omit<ButtonProps, 'onChange'> {
  onChange?: (x: CameraResponse) => void;
  onClose?: () => void;
  startInSelfieMode?: boolean;
  silhouette?: boolean;
  testingMode?: boolean;
  preferredInput?: FileInputSource;
}

const HiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export default function PhotoInput(props: PhotoInputProps) {
  /* Props */
  const {
    onChange: onChangeProp,
    onClose,
    startInSelfieMode,
    silhouette,
    testingMode,
    preferredInput,
    ...buttonProps
  } = props;
  /* Hooks */
  const advancedCamera = useAppSelector(state => state.config.advancedCamera);
  const handleFileInput = useRef<any>(null);
  /* States */
  const [cameraDialogOpen, setCameraDialogOpen] = useState<boolean>(false);
  const mode = useMemo<'user' | 'environment'>(
    () => (startInSelfieMode ? 'user' : 'environment'),
    [startInSelfieMode]
  );
  const [changedFlag, setChangedFlag] = useStateRef<boolean>(false);
  /* Functions */
  const handleClick = () => {
    if (!!handleFileInput.current) handleFileInput.current.click();
    setTimeout(() => {
      if (!changedFlag.current) {
        onChangeCallback({
          base64: null,
          isSelfie: false,
          permissionState: undefined
        });
      } else setChangedFlag(false);
    }, 1000);
  };
  const onChangeCallback = (x: CameraResponse) => {
    setChangedFlag(true);
    !!onChangeProp && onChangeProp(x);
  };
  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const base64 = e.target?.result as string;
        onChangeCallback({
          base64: base64,
          isSelfie: mode === 'user',
          permissionState: 'granted'
        });
      };
      reader.readAsDataURL(file);
    }
  };
  /* Return */
  return advancedCamera || preferredInput === 'gallery' ? (
    <Button {...buttonProps} onClick={handleClick}>
      {buttonProps.children}
      <label>
        <HiddenInput
          type="file"
          accept="image/jpeg"
          capture={preferredInput !== 'gallery' ? mode : undefined}
          onChange={onChangeFile}
          ref={handleFileInput}
        />
      </label>
    </Button>
  ) : (
    <React.Fragment>
      <FormCamera
        open={cameraDialogOpen}
        onChange={val => onChangeCallback(val)}
        onClose={() => setCameraDialogOpen(false)}
        startInSelfieMode={props?.startInSelfieMode}
        silhouette={props?.silhouette}
        testingMode={props?.testingMode}
      />
      <Button {...buttonProps} onClick={() => setCameraDialogOpen(true)} />
    </React.Fragment>
  );
}
