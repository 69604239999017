import axios from 'axios';
import { version } from '../version.json';

/**
 * Tries to check for updates and updates if a new version is found
 * @deprecated the update flow now uses 'updatefound' event listener in the service worker
 * @returns void
 */
export async function updateAppVersion() {
  // Only tries to update if it is online
  if (!navigator.onLine) return;
  // Checking version
  try {
    /* Getting latest version */
    const apires = await axios.request({
      method: 'GET',
      url: `${window.location.origin}/version.json`
    });
    const latest_version = apires.data.version;
    // Getting current version
    const installed_version = version;

    if (installed_version !== latest_version) {
      console.log('Different versions, the app will be automatically updated');
      /* Checking if the app is installed */
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        console.log('The app is not installed');
      }
    }
  } catch (error: any) {
    console.error(error?.message);
  }
}

export function forceAppUpdate() {
  if (!navigator.onLine) {
    alert('Não é possível atualizar no momento');
  } else if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister().then(() => {
        window.location.reload();
      });
    });
  } else {
    alert('Esse aplicativo não está instalado');
  }
}
