import { Fragment } from 'react';
import { Button, Divider, List, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
/* Icon imports */
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
/* Local imports */
import fieldUserStyles from '../styles';
import { FilterState } from '../../../../../hooks/useFilter';
/* Type imports */
import { SelectableCertification } from '../../PerformersFleet';
import { VORO_ROLE_DISPLAY_NAME } from '../../../../../../vorotypes/internalCodes/authorization';
import { VoroRoleDisplay } from '../../../../../../vorotypes/types/authorization';

interface FilterButtonListProps {
  filters: FilterState;
  onClickItem: (id: string) => void;
}

export default function FilterButtonList(props: FilterButtonListProps) {
  /* Props */
  const { filters, onClickItem } = props;
  /* Styles */
  const theme = useTheme();
  const localClasses = fieldUserStyles(theme);
  /* Returning button component */
  return (
    <List>
      {/* Certifications */}
      <Button
        fullWidth
        disableRipple
        className={localClasses.buttonFilterSelect}
        onClick={() => onClickItem('certifications')}
      >
        <Typography fontWeight={600}>Certificações</Typography>
        {!!filters['certifications'] &&
        Array.isArray(filters['certifications'].value) &&
        filters['certifications'].value.length > 0 ? (
          <div
            className={localClasses.certificationsContainer}
            style={{ justifyContent: 'flex-end' }}
          >
            {filters['certifications'].value.map(
              (certification: SelectableCertification) => (
                <div
                  id={`tag-certification-${certification.id}`}
                  key={`cert-${certification.id}`}
                  className={clsx(
                    localClasses.certificationTag,
                    localClasses.certificationTagPreview
                  )}
                >
                  <div style={{ cursor: 'pointer' }}>{certification.name}</div>
                  <CheckRoundedIcon style={{ fontSize: 16 }} />
                </div>
              )
            )}
          </div>
        ) : (
          <Typography variant="body2" color={theme.palette.grey[600]}>
            Todos
          </Typography>
        )}
      </Button>
      <Divider style={{ borderColor: 'rgba(0,0,0,0.05)' }} />
      <Button
        fullWidth
        disableRipple
        className={localClasses.buttonFilterSelect}
        onClick={() => onClickItem('roles')}
      >
        <Typography fontWeight={600}>Cargos</Typography>
        {!!filters['roles'] &&
        Array.isArray(filters['roles'].value) &&
        filters['roles'].value.length > 0 ? (
          <div
            className={localClasses.certificationsContainer}
            style={{ justifyContent: 'flex-end' }}
          >
            {filters['roles'].value.map((voroRole: VoroRoleDisplay) => (
              <div
                id={`tag-voroRole-${voroRole.ulid}`}
                key={`role-${voroRole.ulid}`}
                className={clsx(
                  localClasses.certificationTag,
                  localClasses.certificationTagPreview
                )}
              >
                <div style={{ cursor: 'pointer' }}>
                  {
                    VORO_ROLE_DISPLAY_NAME[
                      voroRole.ulid as keyof typeof VORO_ROLE_DISPLAY_NAME
                    ]
                  }
                </div>
                <CheckRoundedIcon style={{ fontSize: 16 }} />
              </div>
            ))}
          </div>
        ) : (
          <Typography variant="body2" color={theme.palette.grey[600]}>
            Todos
          </Typography>
        )}
      </Button>
    </List>
  );
}
