import z from 'zod';

export const stateEnum = z.enum([
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
]);

export const BucketDataSchema = z.object({
  /** directory/filename.ext */
  key: z.string(),
  /** bucket's name */
  name: z.string()
});

export const MimeTypeSchema = z.enum([
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',
  'application/pdf',
  'application/octet-stream',
  'application/xml',
  'application/json',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.rar',
  'application/zip',
  'application/x-zip-compressed',
  'application/x-7z-compressed',
  'multipart/form-data',
  'multipart/byteranges',
  'text/plain'
]);

export const LocalFileWrapperSchema = z.object({
  id: z.string(),
  type: MimeTypeSchema,
  base64: z.string()
});

export const S3FileWrapperSchema = z.object({
  id: z.string(),
  type: MimeTypeSchema,
  displayName: z.string().optional(),
  bucketData: BucketDataSchema
});

export const GeneralFileWrapperSchema = LocalFileWrapperSchema.partial().merge(
  S3FileWrapperSchema.partial()
);

export const GPSPositionSchema = z.object({
  acc: z.number().optional(),
  lat: z.number(),
  long: z.number(),
  err: z.string().optional()
});

export const DetachedPhotoSchema = LocalFileWrapperSchema.merge(
  z.object({
    selfie: z.boolean().optional()
  })
);

export const GeneralDetachedPhotoSchema = DetachedPhotoSchema.partial().merge(
  S3FileWrapperSchema.partial()
);

export const SignatureFontSchema = z.enum([
  'Allison',
  'Gwendolyn',
  'Island Moments',
  'Mr De Haviland',
  'Reenie Beanie'
]);
