import { makeStyles } from '@mui/styles';
import theme from '../../../../theme';

const fieldCheckboxUseStyles = makeStyles(() => ({
  validAdditionalOptionContainer: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    padding: '9px 16px',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main
  },
  emptyAdditionalOptionContainer: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    padding: '9px 16px',
    border: '1px dashed',
    borderColor: theme.palette.primary.main,
    borderRadius: 5
  },
  disabledAdditionalOptionContainer: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    padding: '5px 14px 5px 14px',
    border: '1px dashed',
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 5
  },
  addConditionButton: {
    fontWeight: 400,
    display: 'flex',
    gap: 16,
    fontSize: 14,
    justifyContent: 'flex-start',
    width: '100%',
    borderStyle: 'dashed',
    opacity: '50%',
    padding: '14px 16px',
    height: 'fit-content'
  },
  interruptButton: {
    backgroundColor: '#AB4444',
    marginTop: 10,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#AB4444'
    }
  },
  inputBase: {
    flexGrow: 1,
    fontSize: 14,
    fontWeight: 500,
    height: 30
  }
}));

export default fieldCheckboxUseStyles;
