import { makeStyles } from '@mui/styles';

const useAlertSuccessStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: 'transparent',
    padding: 0,
    boxShadow: 'none',
    overflow: 'visible'
  },
  contentDiv: {
    borderRadius: '100%',
    boxShadow: '0px 0px 16px rgba(0,0,0,0.1)',
    padding: 0,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 128,
    height: 128
  },
  checkmark: {
    animation: '$turn 500ms ease-in-out both',
    display: 'block'
  },
  '@keyframes turn': {
    '0%': {
      transform: 'rotate(90deg) scale(0.5)'
    }
  },
  checkPath: {
    strokeDasharray: 100,
    strokeDashoffset: 100,
    animation: '$draw 1500ms ease-out forwards'
  },
  '@keyframes draw': {
    '100%': {
      strokeDashoffset: 0
    }
  }
}));

export default useAlertSuccessStyles;
