/* Global imports */
import { Fragment, useMemo } from 'react';
import {
  Divider,
  IconButton,
  Skeleton,
  Typography,
  useTheme
} from '@mui/material';
/* Icon imports */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
/* Local imports */
import fieldUserStyles from '../styles';
import { useAppSelector } from '../../../../../hooks/redux.hooks';
import {
  useCertifications,
  useCompanyName
} from '../../../../../hooks/query.hooks';
import CertificationChip from '../CertificationChip';
/* Type imports */
import {
  Certification,
  CoworkerCertification
} from '../../../../../../vorotypes/types/certification';
import { Coworker } from '../../../../../../vorotypes/types/user';
import { RequiredCertification } from '../../../../../../vorotypes/types/formTemplate';

/**
 * Renders coworkers items
 * @param onClickItem function to trigger when a coworker is clicked
 * @returns a render of all selected coworkers
 */
interface RenderCoworkersProps {
  coworkers?: Array<Coworker>;
  onClickItem?: (id: number) => void;
}
export default function RenderCoworkers(props: RenderCoworkersProps) {
  /* Props */
  const { coworkers, onClickItem } = props;
  /* Styles */
  const theme = useTheme();
  const localClasses = fieldUserStyles(theme);
  /* Constants */
  const voroQueryConfig = { fetchOnlyOnEmptyCache: true };
  /* Hooks */
  const { data: companyName } = useCompanyName(voroQueryConfig);
  const { data: certifications } = useCertifications(voroQueryConfig);
  const requiredCertifications = useAppSelector(
    state => state.form.requiredCertifications
  );
  /* Return component */
  if (!coworkers) {
    // Show loading skeleton
    return (
      <div
        className={localClasses.coworkerDialogItem}
        style={{ padding: '6px 0px' }}
      >
        <div className={localClasses.coworkerDetails}>
          <Skeleton width={128} />
          <Skeleton width={78} />
          <Skeleton width={112} />
        </div>
      </div>
    );
  } else if (!coworkers?.length) {
    // show empty message
    return (
      <Typography
        fontWeight={400}
        color={theme.palette.grey[600]}
        marginTop={2}
        marginBottom={2}
        fontStyle="italic"
      >
        Nenhum usuário selecionado
      </Typography>
    );
  }
  /* rendering coworkers */
  return (
    <Fragment>
      {coworkers.map((coworker, idx) => (
        <Fragment key={coworker.id}>
          <RenderCoworker
            coworker={coworker}
            certifications={certifications}
            companyName={companyName?.company_name}
            onClick={onClickItem}
            requiredCertifications={requiredCertifications}
          />
          {idx < coworkers.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Fragment>
  );
}

interface RenderCoworkerProps {
  coworker: Coworker;
  companyName?: string;
  certifications?: Certification[];
  requiredCertifications?: RequiredCertification[];
  onClick?: (id: number) => void;
}

function RenderCoworker(props: RenderCoworkerProps) {
  /* Props */
  const {
    coworker,
    companyName,
    certifications,
    requiredCertifications,
    onClick
  } = props;
  /* Styles */
  const theme = useTheme();
  const localClasses = fieldUserStyles(theme);
  /* Functions */
  function certificationFilter(certification: CoworkerCertification) {
    return requiredCertifications?.some(
      required => certification.id === required.certification_id
    );
  }
  /* Return component */
  return (
    <div
      key={coworker.id}
      className={localClasses.coworkerDialogItem}
      style={{
        padding: '6px 0px',
        filter: !onClick ? 'opacity(70%)' : 'none'
      }}
    >
      <div className={localClasses.coworkerDetails}>
        <Typography fontWeight={600} fontSize={16}>
          {coworker.name}
        </Typography>
        {coworker?.aliasId && (
          <Typography variant="body2" color={theme.palette.grey[600]}>
            {`ID ${companyName}: ${coworker.aliasId}`}
          </Typography>
        )}
        {!!coworker?.certifications?.length && (
          <div className={localClasses.certificationsContainer}>
            {coworker.certifications
              .filter(certificationFilter)
              .map(certification => (
                <CertificationChip
                  key={`user${coworker.id}-cert${certification.id}`}
                  validThrough={certification.validThrough}
                  certification={certifications?.find(
                    c => c.id === certification.id
                  )}
                />
              ))}
          </div>
        )}
      </div>
      {!!onClick ? (
        <IconButton
          id={`btn-remove-coworker-${coworker.id}`}
          onClick={() => onClick(coworker.id)}
        >
          <CloseRoundedIcon style={{ fontSize: 15 }} color="primary" />
        </IconButton>
      ) : (
        <LockRoundedIcon color="primary" fontSize="medium" />
      )}
    </div>
  );
}
