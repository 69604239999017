/* Lib imports */
import React, { useState } from 'react';
import { Typography } from '@mui/material';
/* Icon imports */
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
/* Local imports */
import { FormAudit } from '../../../vorotypes/types/formAudit';
import { Coworker } from '../../../vorotypes/types/user';
import { useCoworkers } from '../../hooks/query.hooks';
import dayjs from 'dayjs';
import formAuditStyles from './styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

interface FormAuditBannerProps {
  ulid_formAnswer: string;
  formAudits: Array<FormAudit>;
}

export default function FormAuditBanner(props: FormAuditBannerProps) {
  const { ulid_formAnswer, formAudits } = props;
  const localClasses = formAuditStyles();
  // Sorting the array by date
  formAudits.sort((a, b) => (a.dateOfAudit < b.dateOfAudit ? -1 : 1));
  const mostRecentFormAudit = !!formAudits?.length
    ? formAudits[formAudits.length - 1]
    : undefined;
  /* Hooks */
  const history = useHistory();
  const { data } = useCoworkers({ fetchOnlyOnEmptyCache: true });
  const users = data?.coworkers;
  /* States */
  const [approval, setApproval] = useState<'approved' | 'rejected'>();
  const [auditor, setAuditor] = useState<Coworker>();
  /* Use effects */
  React.useEffect(() => {
    setApproval(mostRecentFormAudit?.verdict?.approval);
    setAuditor(users?.find(p => p.id === mostRecentFormAudit?.id_auditor));
  }, [mostRecentFormAudit, users]);
  /* Returning banners */
  return (
    <div
      className={clsx(
        localClasses.banner,
        approval === 'approved'
          ? localClasses.approvedScheme
          : localClasses.rejectedScheme
      )}
      onClick={() =>
        history.push(
          `/singleAudit/${ulid_formAnswer}/${mostRecentFormAudit?.id}`
        )
      }
    >
      {approval === 'approved' ? (
        <VerifiedRoundedIcon />
      ) : approval === 'rejected' ? (
        <ReportRoundedIcon />
      ) : (
        <PriorityHighRoundedIcon />
      )}
      <div>
        <Typography fontWeight={700}>
          {approval === 'approved'
            ? 'Aprovada'
            : approval === 'rejected'
            ? 'Rejeitada'
            : ''}{' '}
          por {auditor?.name ?? 'desconhecido'}
        </Typography>
        <Typography variant="body2">
          Auditada em{' '}
          {dayjs(mostRecentFormAudit?.dateOfAudit).format('DD/MM/YYYY - HH:mm')}
        </Typography>
      </div>
    </div>
  );
}
