import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegisterModule, RegisterModuleInternalCode } from '../types/user';
import { UserDataId, UserFormData } from '../../vorotypes/types/user';

interface registerState {
  hash?: string;
  modules: Array<RegisterModule>;
  activeModule: RegisterModuleInternalCode | null;
  registerData: UserFormData;
  authenticationRequired?: boolean;
}

interface FieldSet {
  field: UserDataId;
  value: any;
}

interface PageCompletionSet {
  pageIndex: number;
  completion: boolean;
}

const initialRegisterState = {
  modules: [],
  activeModule: null,
  registerData: {
    name: '',
    email: '',
    password: ''
  }
} as registerState;

export const registerSlice = createSlice({
  name: 'register',
  initialState: initialRegisterState,
  reducers: {
    setRegisterHash: (state: registerState, action: PayloadAction<string>) => {
      state.hash = action.payload;
    },
    resetRegisterState: (state: registerState) => {
      state = initialRegisterState;
    },
    setActiveModule: (
      state: registerState,
      action: PayloadAction<RegisterModuleInternalCode | null>
    ) => {
      if (
        state.modules.filter(module => module.internalCode === action.payload)
          .length ||
        action.payload === null
      )
        state.activeModule = action.payload;
    },
    completeModule: (state: registerState, action: PayloadAction<string>) => {
      const module = state.modules.find(
        module => module.internalCode === action.payload
      );
      if (!!module) {
        const isCompleted = module.pages.every(
          page => page.completion === true
        );
        module.isCompleted = isCompleted;
      }
    },
    setName: (state: registerState, action: PayloadAction<string>) => {
      state.registerData.name = action.payload;
    },
    setEmail: (state: registerState, action: PayloadAction<string>) => {
      state.registerData.email = action.payload;
    },
    setPassword: (state: registerState, action: PayloadAction<string>) => {
      state.registerData.password = action.payload;
    },
    setField: (state: registerState, action: PayloadAction<FieldSet>) => {
      state.registerData[action.payload.field] = action.payload.value;
    },
    setModules: (
      state: registerState,
      action: PayloadAction<RegisterModule[]>
    ) => {
      state.modules = action.payload;
    },
    setPageCompletion: (
      state: registerState,
      action: PayloadAction<PageCompletionSet>
    ) => {
      if (state.activeModule) {
        const smodule = state.modules.find(
          module => module.internalCode === state.activeModule
        );
        if (!!smodule) {
          const modifier: number = !!smodule?.landingPage ? 1 : 0;
          const index = action.payload.pageIndex - modifier;
          if (index >= 0)
            smodule.pages[index].completion = action.payload.completion;
        }
      }
    },
    setGlobalPageCompletion: (
      state: registerState,
      action: PayloadAction<{ pageId: UserDataId; completion: boolean }>
    ) => {
      const tmodule = state.modules.find(module =>
        module.pages.some(page => page.id === action.payload.pageId)
      );
      if (!!tmodule) {
        const tpage = tmodule.pages.find(
          page => page.id === action.payload.pageId
        );
        if (!!tpage) tpage.completion = action.payload.completion;
        tmodule.isCompleted = tmodule.pages.every(
          page => page.completion === true
        );
      }
    },
    initializeModules: (
      state: registerState,
      action: PayloadAction<RegisterModule[]>
    ) => {
      if (!state.modules || state.modules.length < 1)
        state.modules = action.payload;
    },
    setAuthenticationRequired: (
      state: registerState,
      action: PayloadAction<boolean>
    ) => {
      state.authenticationRequired = action.payload;
    }
  }
});

export const {
  setRegisterHash,
  resetRegisterState,
  completeModule,
  setName,
  setEmail,
  setPassword,
  setField,
  setModules,
  initializeModules,
  setActiveModule,
  setPageCompletion,
  setGlobalPageCompletion,
  setAuthenticationRequired
} = registerSlice.actions;
export default registerSlice.reducer;
