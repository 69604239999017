import React from 'react';

import { LoadingButton } from '@mui/lab';

type DialogPairedButtonsProps = {
  desiredButtonText: string;
  secondaryButtonText?: string | null;
  desiredButtonCallback: () => void;
  secondaryButtonCallback?: () => void;
  desiredButtonColor?: string;
  desiredButtonBgColor?: string;
  desiredButtonIcon?: any;
  desiredButtonIsLoading?: boolean;
  secondaryButtonIsLoading?: boolean;
  secondaryButtonIcon?: any;
};

export default function DialogPairedButtons(props: DialogPairedButtonsProps) {
  const {
    desiredButtonText,
    secondaryButtonText,
    desiredButtonCallback,
    secondaryButtonCallback,
    desiredButtonColor,
    desiredButtonBgColor,
    desiredButtonIcon,
    secondaryButtonIcon
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: 8,
        paddingBottom: 8,
        gap: 8
      }}
    >
      <LoadingButton
        variant="contained"
        color="primary"
        style={{
          borderRadius: 5,
          minHeight: 40,
          width: '100%',
          maxWidth: 370,
          backgroundColor: desiredButtonBgColor,
          color: desiredButtonColor
        }}
        onClick={() => {
          desiredButtonCallback();
        }}
        loading={!!props.desiredButtonIsLoading}
        startIcon={desiredButtonIcon}
      >
        {desiredButtonText}
      </LoadingButton>
      {!!secondaryButtonText && (
        <LoadingButton
          id="btn-dialog-secondary"
          variant="outlined"
          color="primary"
          style={{
            borderRadius: 5,
            minHeight: 40,
            width: '100%',
            maxWidth: 370,
            fontSize: 13
          }}
          onClick={() => {
            secondaryButtonCallback && secondaryButtonCallback();
          }}
          loading={!!props.secondaryButtonIsLoading}
          startIcon={secondaryButtonIcon}
        >
          {secondaryButtonText}
        </LoadingButton>
      )}
    </div>
  );
}
