export function subscribe<EventNameSpace extends string>(
  eventName: EventNameSpace,
  listener: any
) {
  document.addEventListener(eventName, listener);
}

export function unsubscribe<EventNameSpace extends string>(
  eventName: EventNameSpace,
  listener: any
) {
  document.removeEventListener(eventName, listener);
}

export function publish<EventNameSpace extends string>(
  eventName: EventNameSpace,
  data?: any
) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}
