import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useBioSignatureStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexwrap: 'wrap',
    width: '100%'
  },
  orientationMessage: {
    margin: 16,
    display: 'flex',
    gap: 16,
    padding: 16,
    backgroundColor: theme.palette.info.main,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.info.dark,
    color: theme.palette.info.contrastText,
    '@media (orientation: landscape)': {
      margin: 0,
      opacity: 0,
      height: 0,
      width: 0
    }
  },
  signContainer: {
    display: 'flex',
    pointerEvents: 'auto'
  },
  buttonContainer: {
    display: 'flex',
    marginTop: 8
  }
}));

export default useBioSignatureStyles;
