import React from 'react';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import checkboxUseStyles from './styles';
import Typography from '@mui/material/Typography';

type CheckBoxOptionProps = {
  id: string;
  text: string;
  checkedBoxes: string[];
  setCheckedBoxes: (a: string[]) => void;
  singleChoice?: boolean;
  disabled?: boolean;
  mini?: boolean;
};

export default function CheckBoxOption(props: CheckBoxOptionProps) {
  const { id, checkedBoxes, setCheckedBoxes, singleChoice } = props;
  /* Styles */
  const localClasses = checkboxUseStyles();
  const optionClass = !!props?.mini
    ? localClasses.optionMini
    : localClasses.option;
  const buttonClass = clsx(
    optionClass,
    props.checkedBoxes.includes(props.id) && localClasses.optionSelected
  );
  /* Functions */
  const handleClick = () => {
    if (checkedBoxes.includes(props.id)) {
      setCheckedBoxes(checkedBoxes.filter(item => item !== id));
    } else {
      if (singleChoice) setCheckedBoxes([props.id]);
      else setCheckedBoxes(props.checkedBoxes.concat(id));
    }
  };

  return (
    <Button
      id={`btn-cboption-${props.id}`}
      data-cy={checkedBoxes?.includes(id) ? `${id}-selected` : 'none'}
      className={buttonClass}
      variant={props.checkedBoxes.includes(props.id) ? 'contained' : 'outlined'}
      color="secondary"
      onClick={handleClick}
      disabled={props?.disabled}
      classes={{ startIcon: !props?.mini ? localClasses.startIcon : undefined }}
      startIcon={
        props.checkedBoxes.includes(props.id) ? (
          singleChoice ? (
            <RadioButtonCheckedIcon
              style={{ fontSize: !!props?.mini ? 15 : 22 }}
            />
          ) : (
            <CheckRoundedIcon
              style={{ fontSize: !!props?.mini ? 15 : 18 }}
              className={localClasses.iconSelected}
            />
          )
        ) : singleChoice ? (
          <CircleRoundedIcon
            style={{ fontSize: !!props?.mini ? 15 : 22 }}
            className={localClasses.iconNotSelected}
          />
        ) : (
          <div
            className={
              !!props?.mini
                ? localClasses.notSelectedOptionMini
                : localClasses.notSelectedOption
            }
          />
        )
      }
    >
      <Typography
        fontWeight={400}
        paddingTop={'1px'}
        align="left"
        lineHeight={1.35}
        variant={props?.mini ? 'body2' : 'body1'}
      >
        {props.text}
      </Typography>
    </Button>
  );
}
