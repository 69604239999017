import { makeStyles } from '@mui/styles';

const authenticatedUseStyles = makeStyles(theme => ({
  basePerformerButton: {
    height: '100%',
    minHeight: 80,
    width: '100%',
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    borderRadius: 5,
    '&.Mui-disabled': {
      opacity: 0.5
    }
  },
  buttonList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    gap: 15
  },
  sigBtnMainDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    alignItems: 'flex-start'
  }
}));

export default authenticatedUseStyles;
