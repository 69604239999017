import { makeStyles } from '@mui/styles';

const signaturesStyles = makeStyles(() => ({
  container: {
    padding: '16px',
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#0b0b470D',
    borderRadius: '4px',
    width: '100%'
  },
  figure: {
    overflow: 'hidden',
    margin: 0,
    flex: '0 0 64px',
    height: 'auto',
    marginRight: '16px'
  },
  avatar: {
    width: '100%',
    borderRadius: '4px'
  },
  signatureContent: {},
  signatureImage: {
    marginTop: '16px',
    width: '100%'
  }
}));

export default signaturesStyles;
