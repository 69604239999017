import React, { useState } from 'react';
import { Button, IconButton, InputBase, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import theme from '../../../../theme';
import { FormField } from '../../../../../vorotypes/types/formTemplate';

type FieldMiscMeasurementProps = {
  field: FormField;
  setAnswers: React.Dispatch<
    React.SetStateAction<{ field_id: string; answer: any }[]>
  >;
};

type Measurement = {
  uuid: string;
  title: string | undefined;
  val: string | undefined;
};

export default function FieldMiscMeasurement(props: FieldMiscMeasurementProps) {
  const { field, setAnswers } = props;
  /* Hooks */
  const formAnswer = useAppSelector(state => state.form.formAnswer);
  /* States */
  const [measurements, setMeasurements] = useState<Measurement[]>(
    formAnswer[field.field_id]?.map((ans: any) =>
      Object({ uuid: ans, ...formAnswer[ans] })
    ) || []
  );
  /* Functions */
  const addMeasurement = () => {
    setMeasurements(prev => [
      ...prev,
      { uuid: uuidv4(), title: undefined, val: undefined }
    ]);
  };
  const removeMeasurement = (uuid: string) => {
    setMeasurements(prev => [...prev.filter(m => m.uuid !== uuid)]);
  };
  const onValueChange = (newMeasurement: Measurement) => {
    setMeasurements(prev => [
      ...prev.filter(m => m.uuid !== newMeasurement.uuid),
      { ...newMeasurement }
    ]);
  };
  const updateAnswer = () =>
    setAnswers((prev: any) => {
      if (!formAnswer || !prev) return prev;
      const payload = {
        field_id: field?.field_id,
        answer: !!measurements?.length ? measurements?.map(m => m?.uuid) : []
      };
      const measuresPayload = measurements.map(m =>
        Object({ field_id: m.uuid, answer: { title: m.title, val: m.val } })
      );
      if (!prev) return [payload, ...measuresPayload];
      const prevAnswer = prev?.findIndex(
        (ans: any) => ans.field_id === field.field_id
      );
      if (prevAnswer > -1) {
        prev[prevAnswer] = payload;
        return [
          ...prev.filter(
            (ans: any) => !measurements.map(m => m.uuid).includes(ans.field_id)
          ),
          ...measuresPayload
        ];
      } else {
        return [...prev, payload, ...measuresPayload];
      }
    });
  /* useEffects */
  React.useEffect(() => {
    updateAnswer();
    //eslint-disable-next-line
  }, [measurements]);
  /* --- Return --- */
  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h3" marginTop={1}>
        {field?.properties?.question}
      </Typography>
      {field?.properties?.question !== field?.properties?.description && (
        <Typography variant="body2">
          {field?.properties?.description}
        </Typography>
      )}
      {!!measurements.length &&
        measurements.map((measurement: Measurement, idx: number) => (
          <div key={idx} style={{ marginTop: 6 }}>
            <InputBase
              fullWidth
              placeholder="Descrição da medida"
              value={measurement?.title || ''}
              onChange={e =>
                onValueChange({ ...measurement, title: e.target.value })
              }
              style={{ fontWeight: 600 }}
            />
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 1 }}
            >
              <div
                style={{
                  border: '1px solid',
                  borderColor: theme.palette.primary.main,
                  background: '#C1CBD0',
                  borderRadius: '5px 0px 0px 5px',
                  padding: '7px 8px',
                  fontSize: 14
                }}
              >
                Inicial
              </div>
              <InputBase
                id="txt-number-input-answer"
                fullWidth
                placeholder={'0'}
                style={{
                  fontSize: 14,
                  border: '1px solid black',
                  borderLeft: 'none',
                  borderRadius: 0,
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 6
                }}
                value={measurement?.val || ''}
                onChange={e =>
                  onValueChange({ ...measurement, val: e.target.value })
                }
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
              <div
                style={{
                  border: '1px solid',
                  borderRadius: '0px 5px 5px 0px',
                  borderLeft: 'none',
                  background: '#C1CBD0'
                }}
              >
                <IconButton
                  style={{ padding: 8 }}
                  onClick={() => removeMeasurement(measurement.uuid)}
                >
                  <DeleteRoundedIcon
                    style={{ fontSize: 19, color: theme.palette.primary.main }}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        ))}
      <Button
        fullWidth
        startIcon={<AddchartRoundedIcon />}
        onClick={addMeasurement}
      >
        Adicionar medida
      </Button>
    </div>
  );
}
