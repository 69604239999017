import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { useAppDispatch } from '../hooks/redux.hooks';
import { resetNonConformingAttempts } from '../slices/answerMetadataSlice';
import { clearForms, setFormTemplateId, setStep } from '../slices/formSlice';
import { clearSession } from '../slices/sessionSlice';
import api from './api';
import logAppEvent from './logAppEvent';
import { clearDB } from './syncDB';

export async function useClearAllAndSignOut(callback?: () => void) {
  const dispatch = useAppDispatch();
  if (!!Cookies.get('token')) {
    dispatch(clearForms());
    dispatch(clearSession());
    dispatch(setStep(0));
    dispatch(setFormTemplateId(undefined));
    dispatch(resetNonConformingAttempts());
    api.abortAllRequests();
    Cookies.remove('token');
    Cookies.remove('name');
    Cookies.remove('username');
    Cookies.remove('email');
    await clearDB();
  }
  return callback ? callback : null;
}

export async function authUser(email: string, password: string) {
  try {
    let res = await api.post('/authUser', {
      email: email,
      password: password
    });
    Cookies.set('email', email, { expires: 365 });
    Cookies.set('token', res.data.token, { expires: 365 });
    Cookies.set('name', res.data.name, { expires: 365 });
    logAppEvent(`Log in`);
    return 'success';
  } catch (err: any) {
    if (err.response && err.response.status === 401)
      return 'invalid_credentials';
    else return 'server_unavailable';
  }
}

export const isAuthenticated = () => {
  let isAuthenticated = false;
  const token = Cookies.get('token');

  if (token) {
    let decoded: any = jwtDecode(token);
    const { id_company, id_user } = decoded;
    if (!id_company || !id_user) isAuthenticated = false;
    else isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }

  return isAuthenticated;
};
