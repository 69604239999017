/* Global imports */
import { Fragment } from 'react';
import { Button, Divider, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
/* Icon imports */
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
/* Local imports */
import { FilterStateValue } from '../../../../../hooks/useFilter';
import fieldUserStyles from '../styles';
import { Certification } from '../../../../../../vorotypes/types/certification';

interface CertificationTabProps {
  certifications?: Array<Certification>;
  filter: FilterStateValue;
  onClickItem: (certification: Certification) => void;
}

export default function CertificationTab(props: CertificationTabProps) {
  /* Props */
  const { certifications, filter, onClickItem } = props;
  /* Styles */
  const theme = useTheme();
  const localClasses = fieldUserStyles(theme);
  /* Return component */
  return (
    <div className={localClasses.filterList}>
      {!!certifications?.length ? (
        certifications.map(certification => (
          <Fragment key={`frag-cert-${certification.id}`}>
            <Button
              id={`tag-certification-${certification.id}`}
              key={`cert-${certification.id}`}
              disableRipple
              fullWidth
              className={clsx(localClasses.buttonCheckBox)}
              onClick={() => onClickItem(certification)}
            >
              <Typography color="inherit" fontWeight={500}>
                {certification?.name}
              </Typography>
              {!!filter &&
              filter.value.some((v: any) => v.id === certification.id) ? (
                <CheckBoxRoundedIcon style={{ fontSize: 19 }} />
              ) : (
                <CheckBoxOutlineBlankRoundedIcon style={{ fontSize: 19 }} />
              )}
            </Button>
            <Divider style={{ borderColor: 'rgba(0,0,0,0.05)' }} />
          </Fragment>
        ))
      ) : (
        <Typography
          fontStyle="italic"
          sx={{ color: theme.palette.secondary.dark }}
        >
          Nenhuma certificação encontrada
        </Typography>
      )}
    </div>
  );
}
