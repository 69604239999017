import { CircularProgress, Typography, useTheme } from '@mui/material';
import useDiscreteLoadingStyles from './styles';

export default function DiscreteLoading() {
  const theme = useTheme();
  const localClasses = useDiscreteLoadingStyles(theme);
  return (
    <div className={localClasses.mainDiv}>
      <CircularProgress size={14} thickness={4} />
      <Typography
        style={{ marginTop: '3px' }}
        fontStyle="italic"
        fontWeight={500}
      >
        Carregando...
      </Typography>
    </div>
  );
}
