import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateStatus } from '../types/utils';

interface sessionState {
  online: boolean;
  drawerOpen: boolean;
  userCertifications: any[];
  hasOfflineFirstIdService: boolean;
  debugMode: boolean;
  updateStatus: UpdateStatus;
  updateLater: boolean;
}

const initialSessionState = {
  online: window.navigator.onLine,
  drawerOpen: false,
  userCertifications: [],
  hasOfflineFirstIdService: false,
  debugMode: false,
  updateStatus: 'sleeping',
  updateLater: false
} as sessionState;

export const sessionSlice = createSlice({
  name: 'session',
  initialState: initialSessionState,
  reducers: {
    isOnline: state => {
      state.online = true;
    },
    isOffline: state => {
      state.online = false;
    },
    openDrawer: state => {
      state.drawerOpen = true;
    },
    closeDrawer: state => {
      state.drawerOpen = false;
    },
    setDebugMode: (state, action: PayloadAction<boolean>) => {
      state.debugMode = action.payload;
    },
    setUpdateStatus: (state, action: PayloadAction<UpdateStatus>) => {
      state.updateStatus = action.payload;
    },
    setUpdateLater: (state, action: PayloadAction<boolean>) => {
      state.updateLater = action.payload;
    },
    toggleDrawer: state => {
      state.drawerOpen = !state.drawerOpen;
    },
    clearSession: state => {
      state.debugMode = false;
      state = initialSessionState;
    },
    setHasOfflineFirstIdService: (state, action: PayloadAction<boolean>) => {
      state.hasOfflineFirstIdService = action.payload;
    }
  }
});

export const {
  isOnline,
  isOffline,
  openDrawer,
  closeDrawer,
  toggleDrawer,
  clearSession,
  setHasOfflineFirstIdService,
  setDebugMode,
  setUpdateStatus,
  setUpdateLater
} = sessionSlice.actions;
export default sessionSlice.reducer;
