import React, { useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography
} from '@mui/material';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import pullToRefreshStyles from './styles';
import { useStateRef } from '../../hooks/custom.hooks';
import { useMutationCache } from '../../hooks/voroMutation';
import useStyles from '../../useStyles';
import DialogPairedButtons from '../DialogPairedButtons';

const animation = 'all 0.2s ease';
interface PullToRefreshProps {
  options?: {
    /* height which will activate reload */
    threshold?: number;
  };
  children?: React.ReactNode;
}

type Status = 'start' | 'ready' | 'refresh';

export default function PullToRefresh(props: PullToRefreshProps) {
  /* Styles */
  const classes = useStyles();
  const localClasses = pullToRefreshStyles();
  /* Hooks */
  const { data: mutations } = useMutationCache();
  /* States */
  const [height, setHeight] = useState<number>(0);
  const [status, setStatus] = useStateRef<Status>('start');
  const [canPull, setCanPull] = useStateRef<boolean>(false);
  const [isSynching, setIsSynching] = useStateRef<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  /* Refs */
  const pullDownContainerRef = useRef<HTMLDivElement>(null);
  const pullDownHeaderRef = useRef<HTMLDivElement>(null);
  const pullDownLayerRef = useRef<HTMLDivElement>(null);
  const iconPullDownRef = useRef<HTMLDivElement>(null);
  /* Initializing component after all refs are defined */
  useEffect(() => {
    /* Getting ref values */
    const pullDownContainer = pullDownContainerRef.current;
    const pullDownHeader = pullDownHeaderRef.current;
    const pullDownLayer = pullDownLayerRef.current;
    const icon = iconPullDownRef.current;
    /* Checks if it's ready to initialize component (all refs setted) */
    if (!pullDownContainer || !pullDownHeader || !pullDownLayer || !icon)
      return undefined;
    /* Define threshold to activate refresh */
    const refreshThreshold = props?.options?.threshold ?? 60;
    /* Function to reset pullDown widget */
    const reset = () => {
      // Sets a transition to play during reset
      pullDownHeader.style.transition = animation;
      /* Reseting values */
      setStatus('start');
      setHeight(0);
      pullDownLayer.style.zIndex = '-128'; // puts layer behind all app's content
      pullDownLayer.style.opacity = '0'; // hides layer to not overwrite app's background
      pullDownContainer.style.height = '100%'; // safeguard to container filling all screen
    };

    let touchTracker = {
      start: 0, // where the user touched the screen for the first time
      distance: 0 // current touch position - start position
    };

    /* Adding event listeners */
    pullDownContainer.addEventListener(
      'touchstart',
      async event => {
        if (status?.current !== 'refresh') {
          let isTop = window.scrollY <= 0;
          setCanPull(isTop && navigator.onLine);
          touchTracker.start = event.touches[0].pageY;
        }
      },
      { passive: false }
    );

    pullDownContainer.addEventListener(
      'touchmove',
      async e => {
        if (status?.current !== 'refresh' && canPull.current) {
          /* updating distance */
          const distance = e.touches[0].pageY - touchTracker.start;
          touchTracker.distance = distance;

          /* Checking if the user started a pull to refresh movement */
          if (distance > 0) {
            pullDownLayer.style.zIndex = '2049';
            pullDownLayer.style.opacity = '0.5';
            pullDownContainer.style.height = '100vh';
            setHeight(ease(distance, 1.1));
          }

          /* Updating status */
          if (distance > refreshThreshold) setStatus('ready');
          else setStatus('start');

          /* Updating icon */
          const degrees = ease((distance / refreshThreshold) * 360, 2.2);
          icon.style.transform = 'rotate(' + degrees + 'deg)';
          const opacity =
            distance >= refreshThreshold
              ? 1
              : distance / (refreshThreshold * 1.5);
          icon.style.opacity = `${opacity.toFixed(2)}`;
        }
      },
      { passive: false }
    );

    pullDownContainer.addEventListener('touchend', async () => {
      if (status?.current !== 'refresh' && canPull.current) {
        setCanPull(false); // user can only pull again if scrolled to the top
        pullDownHeader.style.transition = animation;

        if (
          touchTracker.distance - pullDownContainer.scrollTop >
          refreshThreshold
        ) {
          if (!isSynching.current) {
            pullDownContainer.scrollTop = 0;
            setHeight(ease(refreshThreshold, 1.1));
            setStatus('refresh');
            if (pullDownLayer) {
              pullDownLayer.style.zIndex = '2049';
              pullDownLayer.style.opacity = '0';
            }
            setTimeout(() => {
              window.location.reload();
            }, 200);
          } else {
            reset();
            setOpenConfirm(true);
          }
        } else {
          reset();
        }
        /* reseting tracker */
        touchTracker.distance = 0;
        touchTracker.start = 0;
      }
    });

    /* Reseting pull down transition to avoid lag when the user is pulling to refresh */
    pullDownHeader.addEventListener('transitionend', () => {
      if (pullDownHeader) pullDownHeader.style.transition = '';
    });
    pullDownHeader.addEventListener('webkitTransitionEnd', () => {
      if (pullDownHeader) pullDownHeader.style.transition = '';
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // The array must not be empty because in the first render all refs are null
    pullDownContainerRef,
    pullDownHeaderRef,
    pullDownLayerRef,
    iconPullDownRef
  ]);
  useEffect(() => {
    if (!!mutations) {
      const flag = !!mutations?.find(mutation => mutation.status === 'pushing');
      setIsSynching(flag);
    }
    return () => setIsSynching(false);
  }, [mutations]);

  return (
    <div className={localClasses.pullDownContainer} ref={pullDownContainerRef}>
      <div
        className={localClasses.pullDownHeader}
        style={{ height: height + 'px' }}
        ref={pullDownHeaderRef}
      >
        <div className={localClasses.pullDownContent}>
          {status.current === 'refresh' ? (
            <CircularProgress size={24} thickness={4} />
          ) : (
            <div
              className={localClasses.pullDownContentIcon}
              ref={iconPullDownRef}
            >
              <RefreshRoundedIcon className={localClasses.refreshIcon} />
            </div>
          )}
        </div>
      </div>
      <div className={localClasses.pullDownLayer} ref={pullDownLayerRef} />
      {props?.children}
      <Dialog
        PaperProps={{ style: { borderRadius: 5 } }}
        BackdropProps={{ className: classes.dialogBackdrop }}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        fullWidth={true}
      >
        <DialogContent
          style={{ display: 'flex', gap: 4, flexDirection: 'column' }}
        >
          <Typography
            variant="h3"
            marginBottom={2}
            display="flex"
            gap={2}
            alignItems="center"
          >
            <WarningRoundedIcon style={{ marginBottom: 1 }} />
            Você tem certeza disso?
          </Typography>
          <DialogContentText>
            Alguns formulários ainda estão sendo enviados, recarregar a página
            poderá causar problemas de sincronização.
          </DialogContentText>
          <DialogPairedButtons
            desiredButtonText={'Cancelar'}
            secondaryButtonText={'Recarregar mesmo assim'}
            desiredButtonCallback={() => setOpenConfirm(false)}
            secondaryButtonCallback={() => window.location.reload()}
            secondaryButtonIcon={<RefreshRoundedIcon />}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

/**
 * Makes a logaritmic function to ease increments in a linear variable
 * @param x a linear incrementing variable to be turned into one logaritmic one
 * @param lambda higher values results in faster animations, while lower values does the opposite
 * @returns an eased out value
 * @author Douglas Flores
 */
function ease(x: number, lambda?: number) {
  const alpha = 2;
  const beta = 0.1;
  const k = 10;
  const l = lambda ?? 1;
  return x * (l * beta) + l * k * Math.log2(x * (l * alpha));
}
