import React from 'react';
import { makeStyles } from '@mui/styles';
import Silhouette from '../../images/silhouette_mask.png';

/**
 * @param
 */
type MaskWrapperProps = {
  children?: React.ReactNode,
  /** @description mask opacity from 0 to 1 */
  opacity?: number,
  /** @description height in percentage, i.e., '100%' */
  maskRelativeHeight?: string,
  /** @description enables/disables the mask */
  active?: boolean,
  maskImage?: string
}

const localUseStyles = makeStyles({
  mainWrapper: {
    height: 'fit-content',
    position: 'relative',
    lineHeight: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  maskWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
  },
  expand: {
    flexGrow: 1,
    width: '100%',
    height: '100%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 1,
    flexGrow: 1
  }
});

export default function MaskWrapper(props: MaskWrapperProps) {
  const { opacity, maskRelativeHeight, maskImage, active } = props;
  const localClasses = localUseStyles();

  if (!active) return <>{props?.children}</>;
  return (
    <div className={localClasses.mainWrapper}>
      <div className={localClasses.maskWrapper}>
        <div
          className={localClasses.expand}
          style={{ backgroundColor: `rgba(0,0,0,${opacity || 0.4})` }}
        />
        <div
          className={localClasses.rowContainer}
          style={{ height: `${maskRelativeHeight || '100%'}` }}
        >
          <div
            className={localClasses.expand}
            style={{ backgroundColor: `rgba(0,0,0,${opacity || 0.4})` }}
          />
          <img
            src={maskImage || Silhouette}
            alt="silhouette"
            height={'100%'}
            style={{ flexShrink: 1, opacity: `${opacity || 0.4}` }}
          />
          <div
            className={localClasses.expand}
            style={{ backgroundColor: `rgba(0,0,0,${opacity || 0.4})` }}
          />
        </div>
        <div
          className={localClasses.expand}
          style={{ backgroundColor: `rgba(0,0,0,${opacity || 0.4})` }}
        />
      </div>
      {props?.children}
    </div>
  )
}