export const FORM_TYPE_INTERNAL_CODE = {
  'apr': 1,
  'apr_paper': 2,
  'dqs': 3,
  'ptr': 4,
  'pet': 5,
  'riss': 6
} as const;

export type FormTypeInternalCode = keyof typeof FORM_TYPE_INTERNAL_CODE;
