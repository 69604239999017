import { makeStyles } from '@mui/styles';
import theme from '../../../../theme';

const fieldUserStyles = makeStyles(() => ({
  addButton: {
    backgroundColor: theme.palette.background.default,
    padding: '0px 14px 0px 14px',
    gap: 8,
    margin: 0,
    marginTop: 6,
    width: '100%'
  },
  buttonCheckBox: {
    diplay: 'flex',
    justifyContent: 'space-between',
    padding: '16px 20px',
    borderRadius: 0
  },
  buttonCheckBoxSelected: {
    backgroundColor: theme.palette.primary.main
  },
  buttonFilterSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 20px'
  },
  container: {
    width: '100%'
  },
  certificationsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 4,
    flexGrow: 1
  },
  certificationTag: {
    borderRadius: 30,
    backgroundColor: 'rgba(20, 20, 60, 0.1)',
    fontWeight: 600,
    fontSize: 11,
    lineHeight: '11px',
    padding: '5px 8px 5px 8px',
    display: 'flex',
    alignItems: 'center',
    gap: 3
  },
  certificationTagPreview: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.main,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 6
  },
  dialogTitle: {
    padding: 16,
    fontSize: 18,
    lineHeight: '18px',
    display: 'flex',
    gap: 8,
    alignItems: 'center'
  },
  divider: {
    margin: '0px 12px 0px 12px'
  },
  dividerMedium: {
    borderTop: '1px solid rgba(0,0,0,0.2)',
    borderLeft: '0px',
    margin: 0,
    '&:last-child': {
      borderTop: '0px'
    }
  },
  fieldTitle: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 2
  },
  filterList: {
    // boxShadow: '0px -4px 4px rgba(0,0,0,0.03) inset'
  },
  filtersContainer: {
    padding: '12px 16px',
    backgroundColor: '#35354C'
  },
  filterMenuContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterTitle: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 2
  },
  header: {
    display: 'flex',
    borderBottom: '1px solid rgba(0,0,0,0.05)',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  numberBadge: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 18,
    padding: '1px 6px 0px',
    marginRight: 4
  },
  coworkerDialogItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 9,
    paddingBottom: 9,
    gap: 8,
    alignItems: 'center'
  },
  coworkerDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px'
  },
  coworkerDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1
  },
  searchBox: {
    flexGrow: 1,
    backgroundColor: '#f0f0f0',
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
    alignItems: 'center',
    padding: '2px 10px 2px 10px',
    gap: 8
  },
  searchIcon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchInput: {
    color: theme.palette.primary.main,
    width: '100%',
    fontSize: 15,
    whiteSpace: 'nowrap',
    textAlign: 'justify'
  },
  searchRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 16px 8px',
    gap: 8,
    boxShadow: '0px 2px 2px rgba(0,0,0,0.05)'
  },
  selectedFilter: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  unselectedFilter: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)'
    }
  },
  userList: {
    height: 200,
    flexGrow: 1,
    overflow: 'auto',
    boxShadow: '0px -4px 4px rgba(0,0,0,0.03) inset'
  },
  userListSubheader: {
    textTransform: 'uppercase',
    fontWeight: 400,
    lineHeight: '12.89px',
    fontSize: 11,
    marginTop: 16,
    marginBottom: 2
  },
  warningBall: {
    position: 'absolute',
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.warning.light,
    top: 5,
    right: 5,
    border: '2px solid',
    borderColor: theme.palette.primary.main
  }
}));

export default fieldUserStyles;
