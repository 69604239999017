import api from '../services/api';
import { Certification } from '../../vorotypes/types/certification';
import Cookies from 'js-cookie';

/**
 * Fetches the certifications and pre-proccess them
 * @returns the certifications from the api
 */
export async function certificationsQueryFn(): Promise<Certification[]> {
  const apires = await api.get<Certification[]>('/app/certifications');
  return apires.data.map((item: any) =>
    Object({
      id: item?.id,
      name: item?.name,
      warningPeriodInDays: item?.warningPeriodInDays || 30, //TODO: calculate expiring date
      selected: false
    })
  );
}

/**
 * Fetches a route and return it's data
 * @param route the route to query
 * @returns the data from the query result
 */
export async function defaultQueryFn<TQueryFnData>(
  route: string
): Promise<TQueryFnData> {
  const token = Cookies.get('token');
  if (!token) throw Error('No token');
  const apires = await api.get<TQueryFnData>(route);
  return apires.data as TQueryFnData;
}
