import { useEffect, useState } from 'react';
import { AlertColor } from '@mui/material';

type UseAlertState = {
  open: boolean;
  message: string;
  severity: AlertColor;
  onClose?: () => void;
};

export interface UseAlertInterface {
  state: UseAlertState;
  close: () => void;
  error: (message: string) => void;
  info: (message: string) => void;
  success: (message: string) => void;
  warning: (message: string) => void;
}

const useAlertDefault: UseAlertState = {
  open: false,
  message: 'Message',
  severity: 'info'
};

/**
 * This hook manages openState, severity and message of snackbar alerts
 * @returns a hook to manage snackbar alerts
 */
export default function useAlert(): UseAlertInterface {
  const [state, setState] = useState<UseAlertState>(useAlertDefault);

  const close = () => {
    setState(prev => Object({ ...prev, open: false }));
  };

  const error = (message: string) =>
    setState(prev =>
      Object({ ...prev, open: true, message, severity: 'error' })
    );

  const info = (message: string) =>
    setState(prev =>
      Object({ ...prev, open: true, message, severity: 'info' })
    );

  const success = (message: string) =>
    setState(prev =>
      Object({ ...prev, open: true, message, severity: 'success' })
    );

  const warning = (message: string) =>
    setState(prev =>
      Object({ ...prev, open: true, message, severity: 'warning' })
    );

  useEffect(() => {
    setState({ ...useAlertDefault, onClose: close });
  }, []);

  return { state: state, close, error, info, success, warning };
}
