import React from "react";
import dotStyles from "./styles";

export default function DotDotDot() {
  const localClasses = dotStyles();
  return (
    <React.Fragment>
      <span className={localClasses.dot1}>.</span>
      <span className={localClasses.dot2}>.</span>
      <span className={localClasses.dot3}>.</span>
    </React.Fragment>
  )
}