import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LinePath } from '@visx/shape';
import { useDrag } from '@visx/drag';
import { curveBasis } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import useCanvasStyles from './styles';

type Point = {
  x: number;
  y: number;
};
type Line = Array<Point>;
type Lines = Array<Line>;
export type SVGCanvasProps = {
  width: number;
  height: number;
  onChange: (x: SVGSVGElement | null) => void;
  clearFlag?: boolean;
  setClearFlag?: (x: boolean) => void;
  setIsEmpty?: (x: boolean) => void;
};

export default function SVGCanvas({
  width,
  height,
  onChange,
  clearFlag,
  setClearFlag,
  setIsEmpty
}: SVGCanvasProps) {
  const localClasses = useCanvasStyles();
  /* States */
  const [lines, setLines] = useState<Lines>([]);
  /* Hooks */
  const svgContent = useRef<SVGSVGElement>(null);
  const onDragStart = useCallback(
    (currDrag: any) => {
      // Add the new line with the starting point
      const startingPoint = { x: currDrag.x, y: currDrag.y };
      setLines((currLines: Lines) => [...currLines, [startingPoint]]);
    },
    [setLines]
  );
  const onDragMove = useCallback(
    currDrag => {
      // Add the new point to the current line
      setLines((currLines: Lines) => {
        const nextLines: Lines = [...currLines];
        const newPoint: Point = {
          x: currDrag.x + currDrag.dx,
          y: currDrag.y + currDrag.dy
        };
        const lastIndex = nextLines.length - 1;
        nextLines[lastIndex] = [...(nextLines[lastIndex] || []), newPoint];
        return nextLines;
      });
      setIsEmpty && setIsEmpty(false);
    },
    [setLines, setIsEmpty]
  );
  const {
    x = 0,
    y = 0,
    dx,
    dy,
    isDragging,
    dragStart,
    dragEnd,
    dragMove
  } = useDrag({
    onDragStart,
    onDragMove,
    resetOnStart: true
  });
  //eslint-disable-next-line
  useEffect(() => onChange(svgContent.current), [lines]);
  useEffect(() => {
    if (clearFlag === true && !!setClearFlag) {
      setLines([]);
      setClearFlag(false);
      setIsEmpty && setIsEmpty(true);
    }
    //eslint-disable-next-line
  }, [clearFlag]);

  if (width < 10) return null;
  return (
    <div
      id="bio-signature-wrapper"
      className={localClasses.drag}
      style={{ touchAction: 'none', width: width + 1, zIndex: 100 }}
    >
      <svg
        width={width}
        height={height}
        className={localClasses.svg}
        ref={svgContent}
      >
        <LinearGradient id="stroke" from="#000" to="#000" />
        <rect fillOpacity={0} width={width} height={height} />
        {lines.map((line, i) => (
          <LinePath
            key={`line-${i}`}
            fill="transparent"
            stroke="url(#stroke)"
            strokeWidth={3}
            data={line}
            curve={curveBasis}
            x={d => d.x}
            y={d => d.y}
          />
        ))}
        <g>
          {isDragging && (
            /* Capture mouse events */
            <rect
              width={width}
              height={height}
              onMouseMove={dragMove}
              onMouseUp={dragEnd}
              fill="transparent"
            />
          )}
          {/* decorate the currently drawing line */}
          {isDragging && (
            <g>
              <rect
                fill="white"
                width={8}
                height={8}
                x={x + dx - 4}
                y={y + dy - 4}
                pointerEvents="none"
              />
              <circle
                cx={x}
                cy={y}
                r={4}
                fill="transparent"
                stroke="white"
                pointerEvents="none"
              />
            </g>
          )}
          {/* Create the drawing area */}
          <rect
            fill="transparent"
            width={width}
            height={height}
            onMouseDown={dragStart}
            onMouseUp={isDragging ? dragEnd : undefined}
            onMouseMove={isDragging ? dragMove : undefined}
            onTouchStart={dragStart}
            onTouchEnd={isDragging ? dragEnd : undefined}
            onTouchMove={isDragging ? dragMove : undefined}
          />
        </g>
      </svg>
    </div>
  );
}
