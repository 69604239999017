import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const formAuditStyles = makeStyles(() => ({
  banner: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    padding: '14px 10px 14px 10px',
    borderRadius: 5,
    backgroundColor: 'rgba(255,255,255,0.5)',
    cursor: 'pointer'
  },
  approvedScheme: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
    border: '1px solid',
    borderColor: theme.palette.success.main
  },
  rejectedScheme: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.warning.light
  }
}));

export default formAuditStyles;
