import React, { useEffect, useState } from 'react';
import { getS3File } from '../../services/files';
import BrokenImageRoundedIcon from '@mui/icons-material/BrokenImageRounded';
import { Dialog, Skeleton, Typography } from '@mui/material';
import theme from '../../theme';
import loadingImageStyles from './styles';
import useStyles from '../../useStyles';

type LoadingImageProps = {
  bucketData?: {
    key: string;
    name: string;
  };
  base64?: string;
  width?: number;
  height?: number;
  amplifiable?: boolean;
  onClick?: (base64Img?: string | null) => void;
  style?: React.CSSProperties | undefined;
  classes?: {
    skeleton?: string;
    img?: string;
  };
};

export default function LoadingImage(props: LoadingImageProps) {
  const { bucketData, height, style, classes, onClick } = props;
  const width = props?.width ?? 150;
  const globalClasses = useStyles();
  const localClasses = loadingImageStyles();
  const [base64Img, setBase64Img] = useState<string | null>();
  const [openZoom, setOpenZoom] = useState<boolean>(false);

  function handleClick(e: any) {
    if (!!onClick) onClick(base64Img);
    else setOpenZoom(!!props?.amplifiable);
  }

  useEffect(() => {
    if (!!bucketData) {
      getS3File(bucketData.key, bucketData.name)
        .then(res => {
          if (typeof res === 'string') setBase64Img(res);
          else setBase64Img(null);
        })
        .catch(err => {
          console.error(err);
          setBase64Img(null);
        });
    } else if (!!props?.base64) {
      setBase64Img(props.base64);
    }
  }, [bucketData]);

  return base64Img === undefined ? (
    <Skeleton
      variant="rectangular"
      width={width ?? 150}
      height={height ?? width * (4 / 3)}
      className={classes?.skeleton}
    />
  ) : base64Img === null ? (
    <div
      className={localClasses.notFoundDiv}
      style={{ width: width, height: height ?? width * (4 / 3) }}
    >
      <BrokenImageRoundedIcon fontSize="large" color="secondary" />
      <Typography textAlign="center" color={theme.palette.secondary.dark}>
        Erro ao carregar imagem
      </Typography>
    </div>
  ) : (
    <React.Fragment>
      <img
        src={base64Img ?? undefined}
        alt={base64Img ?? ''}
        width={width}
        height={height}
        className={classes?.img}
        style={style}
        onClick={handleClick}
      />
      <Dialog
        open={openZoom}
        onClose={() => setOpenZoom(false)}
        PaperProps={{
          style: { borderRadius: 0 }
        }}
        BackdropProps={{
          className: globalClasses.dialogBackdrop
        }}
      >
        <img
          src={base64Img ?? undefined}
          alt={base64Img ?? ''}
          className={classes?.img}
          style={{
            maxHeight: 0.9 * window.screen.height,
            maxWidth: 0.9 * window.screen.width,
            borderRadius: 0
          }}
        />
      </Dialog>
    </React.Fragment>
  );
}
