import { FormAnswerEdit } from '../types/form';
import {
  CancelPostData,
  EditPostData,
  EditPostDataLegacy,
  InspectPostData
} from '../../vorotypes/types/routes.app.requests';
import db from '../services/db';
import { useVoroMutation } from './voroMutation';
import voroQueryClient from '../services/voroQueryClient';
import {
  AppLocalFormAnswer,
  FAMutationSubmitPackage,
  FormAnswerStatus
} from '../../vorotypes/types/formAnswer';
import { getParticipantsFromAnswer } from '../utils/helpers';

/**
 * A hook to post a form-answer
 * @returns an useVoroMutation hook to post a form-answer
 */
export const useAddFormAnswer = (answer_ulid: string) => {
  return useVoroMutation({
    mutationKey: ['addFormAnswer', answer_ulid],
    mutationFn: async (variables: FAMutationSubmitPackage) => {
      /* POSTING answer in cache */
      const status = Object.keys(variables.answer.items).includes(
        'NonExecution'
      )
        ? 'nonExecution'
        : variables.template?.config?.persistent
        ? 'ongoing'
        : 'ok';
      const answerStatus = {
        status: status as FormAnswerStatus
      };
      const filledDate =
        variables?.answer?.metadata?.timer?.answerFinishedDate ??
        new Date().toISOString();
      // Building optimistic form answer
      const optimisticFormAnswer: AppLocalFormAnswer = {
        ulid: variables.ulid,
        answer: variables.answer,
        filledDate: filledDate,
        participants:
          getParticipantsFromAnswer(
            variables.template,
            variables.answer.items
          ) ?? [],
        template: variables.template,
        edits: null,
        onlyLocallyAvailable: true,
        answerStatus: answerStatus,
        idFormType: variables.id_formType
      };
      const cached = await db.queryCache
        .filter(x => x.key.includes('cachedFormAnswers'))
        .toArray();
      const old = (cached[0]?.data as Array<AppLocalFormAnswer>) ?? [];
      await db.queryCache.put(
        {
          key: ['cachedFormAnswers'],
          data: [optimisticFormAnswer, ...old]
        },
        ['cachedFormAnswers']
      );
    }
  });
};

/**
 * A hook to cancel a form-answer
 * @returns an useMutation hook to cancel a form-answer
 */
export const useCancelFormAnswer = (answer_ulid: string) => {
  return useVoroMutation({
    mutationKey: ['cancelFormAnswer', answer_ulid],
    mutationFn: async (variables: CancelPostData) => {
      // Getting cached form answers before mutation execution
      const previousData: Array<AppLocalFormAnswer> =
        (await voroQueryClient.getQueryData(['cachedFormAnswers'])) ?? [];
      // Getting target form answer
      const previousTargetFA = previousData?.find(
        ans => ans.answer.metadata.ulid === variables.formAnswerUlid
      );
      if (!!previousTargetFA) {
        const targetFA = { ...previousTargetFA };
        // Updating status
        targetFA.answerStatus = {
          ...targetFA.answerStatus,
          status: 'cancelled',
          cancelData: variables.submission_data
        };
        /* Updating cached form answers */
        const cached = await db.queryCache
          .filter(x => x.key.includes('cachedFormAnswers'))
          .toArray();
        const old = (cached[0].data as Array<AppLocalFormAnswer>) ?? [];
        await db.queryCache.update(['cachedFormAnswers'], {
          data: [
            ...old.map(formAnswer => {
              if (
                formAnswer?.answer?.metadata?.ulid !==
                targetFA?.answer?.metadata?.ulid
              )
                return formAnswer;
              else return targetFA;
            })
          ]
        });
      }
    }
  });
};

/**
 * A hook to edit a form-answer
 * @returns an useMutation hook to post an edit to a form-answer
 */
export const useEditFormAnswer = (answer_ulid: string) => {
  return useVoroMutation({
    mutationKey: ['editFormAnswer', answer_ulid],
    mutationFn: async (variables: FormAnswerEdit) => {
      // Getting cached form answers before mutation execution
      const previousData: Array<AppLocalFormAnswer> =
        (await voroQueryClient.getQueryData(['cachedFormAnswers'])) ?? [];
      // Getting target form answer
      const previousTargetFA = previousData?.find(
        row => row.answer.metadata.ulid === variables.postData.formAnswerUlid
      );
      if (!!previousTargetFA) {
        const targetFA = { ...previousTargetFA };
        /* Applying edit to local storage */
        if (['add&cancel', 'add'].includes(variables?.postData?.action)) {
          /* Getting fieldId */
          const fieldId = variables?.postData?.fieldId;
          if (!targetFA?.answer?.items[`${fieldId}`])
            throw new Error('Targeted field not found!');
          /* Updating field id */
          const updatedArray = [
            ...targetFA.answer.items[`${fieldId}`],
            ...variables.postData.payload
          ];
          targetFA.answer.items[`${fieldId}`] = updatedArray;
          /* Append edition to answerStatus */
          const updatedAnswerStatus = {
            ...targetFA.answerStatus,
            edits: targetFA.answerStatus?.edits || []
          };
          updatedAnswerStatus.edits.push({
            action: variables?.postData?.action,
            payload: variables?.postData?.payload,
            date:
              (variables?.postData as EditPostDataLegacy)?.date ??
              (variables?.postData as EditPostData)?.datetime,
            fieldId: variables?.postData?.fieldId
          });
          // Status change
          if (variables?.postData?.action === 'add&cancel')
            targetFA.answerStatus = {
              ...updatedAnswerStatus,
              status: 'cancelled'
            };
          else targetFA.answerStatus = { ...updatedAnswerStatus };
        } else if (variables?.postData?.action === 'complete') {
          /* Append completion to answerStatus */
          targetFA.answerStatus = {
            ...targetFA.answerStatus,
            completionData: {
              datetime:
                (variables?.postData as EditPostDataLegacy)?.date ??
                (variables?.postData as EditPostData)?.datetime,
              metadata: {
                title: targetFA?.answer?.metadata?.title ?? 'DOC',
                app_version: variables?.postData?.appVersion
              },
              position: variables?.postData?.position,
              answer: variables?.postData?.payload
            },
            status: 'ok'
          };
        } else if (['pause', 'resume'].includes(variables?.postData?.action)) {
          const action = variables.postData.action as 'pause' | 'resume';
          const faStatus: FormAnswerStatus =
            variables?.postData?.action === 'pause' ? 'paused' : 'ongoing';
          /* Creating updated pause history */
          const updatedAnswerStatus = {
            ...targetFA.answerStatus,
            status: faStatus as FormAnswerStatus,
            pauseResumeHistory: targetFA.answerStatus?.pauseResumeHistory || []
          };
          updatedAnswerStatus.pauseResumeHistory.push({
            action: action,
            datetime:
              (variables?.postData as EditPostDataLegacy)?.date ??
              (variables?.postData as EditPostData)?.datetime,
            position: variables?.postData?.position,
            metadata: {
              app_version: variables?.postData?.appVersion
            }
          });
          /* Append pause to answerStatus */
          targetFA.answerStatus = { ...updatedAnswerStatus };
        } else if (variables?.postData?.action === 'change') {
          /* Getting fieldId */
          const fieldId = variables?.postData?.fieldId;
          if (!targetFA?.answer?.items[`${fieldId}`])
            throw new Error('Targeted field not found!');
          /* Updating field id */
          targetFA.answer.items[`${fieldId}`] = variables?.postData?.payload;
          /* Append edition to answerStatus */
          const updatedAnswerStatus = {
            ...targetFA.answerStatus,
            edits: targetFA.answerStatus?.edits || []
          };
          updatedAnswerStatus.edits.push({
            action: variables?.postData?.action,
            payload: variables?.postData?.payload,
            date:
              (variables?.postData as EditPostDataLegacy)?.date ??
              (variables?.postData as EditPostData)?.datetime,
            fieldId: variables?.postData?.fieldId
          });
          // Status change
          targetFA.answerStatus = { ...updatedAnswerStatus };
        }
        /* Updating cached form answers */
        const cached = await db.queryCache
          .filter(x => x.key.includes('cachedFormAnswers'))
          .toArray();
        const old = (cached[0].data as Array<AppLocalFormAnswer>) ?? [];
        await db.queryCache.update(['cachedFormAnswers'], {
          data: [
            ...old.map(formAnswer => {
              if (
                formAnswer?.answer?.metadata?.ulid !==
                targetFA?.answer?.metadata?.ulid
              )
                return formAnswer;
              else return targetFA;
            })
          ]
        });
      }
    }
  });
};

export const useInspectFormAnswer = (answer_ulid: string) => {
  return useVoroMutation({
    mutationKey: ['inspectFormAnswer', answer_ulid],
    mutationFn: async (variables: InspectPostData) => {
      const cachedFormAnswers: Array<AppLocalFormAnswer> =
        (await voroQueryClient.getQueryData(['cachedFormAnswers'])) ?? [];
      const targetFA = cachedFormAnswers.find(
        fa => fa.answer.metadata.ulid === answer_ulid
      );
      /* Updating formInspections */
      const targetUlid = targetFA?.answer?.metadata?.ulid;
      if (!targetUlid) throw Error('Ulid not found');
      voroQueryClient
        .setQueryData(['formInspections', targetUlid], (old: any) => [
          ...old,
          variables
        ])
        .catch(err => {
          console.error(err);
        });
      /* If there is need to conclude the formAnswer */
      if (variables.action === 'inspect&conclude') {
        voroQueryClient.setQueryData(
          ['cachedFormAnswers'],
          (prev: Array<AppLocalFormAnswer>) => {
            const filtered = [
              ...prev.filter(fa => fa.answer.metadata.ulid !== answer_ulid)
            ];
            const savedFA = prev?.find(
              fa => fa.answer.metadata.ulid === answer_ulid
            );
            if (!!savedFA) {
              let updatedFA = { ...savedFA };
              updatedFA.answerStatus.status = 'ok';
              return [...filtered, updatedFA];
            } else {
              return [...prev];
            }
          }
        );
      }
    }
  });
};
