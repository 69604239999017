import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface configState {
  advancedCamera: boolean;
}

const initialConfigState = {
  advancedCamera: false
} as configState;

export const configSlice = createSlice({
  name: 'config',
  initialState: initialConfigState,
  reducers: {
    setAdvancedCamera: (state, action: PayloadAction<boolean>) => {
      state.advancedCamera = action.payload;
    },
    resetConfig: state => {
      state = initialConfigState;
    }
  }
});

export const { setAdvancedCamera, resetConfig } = configSlice.actions;
export default configSlice.reducer;
