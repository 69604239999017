/* Global imports */
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Divider,
  Skeleton,
  Typography,
  useTheme
} from '@mui/material';
import dayjs from 'dayjs';
/* Icon imports */
import AttachmentRoundedIcon from '@mui/icons-material/AttachmentRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
/* Local imports */
import api, { API_TIMEOUT } from '../../../../services/api';
import useDqsAttachStyles from './styles';
import useStyles from '../../../../useStyles';
import useAlert, { UseAlertInterface } from '../../../../hooks/useAlert';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import { useDqsTopics, useFormAnswer } from '../../../../hooks/query.hooks';
import { useEditFormAnswer } from '../../../../hooks/mutation.hooks';
import FormAnswerStatusBanner from '../../../../components/FormAnswerStatusBanner';
import MainContainer from '../../../../components/MainContainer/MainContainer';
import NavBar from '../../../../components/NavBar';
import SnackbarAlert from '../../../../components/SnackbarAlert';
/* Type imports */
import { AppLocalFormAnswer } from '../../../../../vorotypes/types/formAnswer';
import { FORM_TYPE_INTERNAL_CODE } from '../../../../../vorotypes/internalCodes/formType';
import {
  PresignedDownload,
  S3FileWrapper
} from '../../../../../vorotypes/types/utils';
import { Link } from '../../../../../vorotypes/types/dqsTopic';

export function DqsAttachments(props: any) {
  /* Props */
  const ulid = props.match.params.ulid;
  /* Styles */
  const theme = useTheme();
  const classes = useStyles(theme);
  const localClasses = useDqsAttachStyles(theme);
  /* Getting formAnswer data */
  const { data: selectedAnswer, isReady } =
    useFormAnswer<AppLocalFormAnswer>(ulid);
  const { data: dqsTopics } = useDqsTopics();
  const completeFormAnswer = useEditFormAnswer(ulid);
  const metadata = useMemo(
    () => selectedAnswer?.answer?.metadata,
    [selectedAnswer]
  );
  /* Hooks */
  const isOnline = useAppSelector(state => state.session.online);
  const history = useHistory();
  const alertHook = useAlert();
  /* States */
  const [links, setLinks] = useState<Link[] | null>();
  const [attachments, setAttachments] = useState<S3FileWrapper[] | null>();
  /* Functions */
  function getFilledDate() {
    return dayjs(metadata?.timer?.answerFinishedDate).format(
      'DD/MM/YYYY - HH:mm'
    );
  }
  function isPausable() {
    return (
      !completeFormAnswer.isMutating &&
      !!selectedAnswer &&
      FORM_TYPE_INTERNAL_CODE['dqs'] !== selectedAnswer.idFormType
    );
  }
  /* Effects */
  useEffect(() => {
    const formAnswer = selectedAnswer;
    if (!formAnswer || FORM_TYPE_INTERNAL_CODE['dqs'] !== formAnswer.idFormType)
      return;
    const fields = formAnswer.template.steps.flatMap(step => step.fields);
    const topicField = fields.find(field => field.type === 'DqsTopic');
    if (!topicField) return;
    const topicId = formAnswer.answer.items[topicField.field_id];
    if (!topicId) return;
    const topic = dqsTopics?.find(dqsTopic => dqsTopic.id === topicId);
    if (!topic) return;
    setAttachments(topic?.attachments ?? null);
    setLinks(topic?.links ?? null);
  }, [selectedAnswer, dqsTopics]);
  /* Return component */
  return (
    <Fragment>
      <NavBar
        isLogged
        returnButton
        returnFunction={() => history.go(-1)}
        headerTitle={`${metadata?.title ?? `DOC`} Digital`}
      />
      <MainContainer isOnline={isOnline} maxWidth={'xs'} bottomButtons={2}>
        <div className={classes.bodyList} style={{ gap: 12 }}>
          <Typography variant="h4" mt={0}>
            {`${metadata?.title ?? 'DOC'}-${ulid}`}
          </Typography>
          {isReady && !!selectedAnswer && (
            <FormAnswerStatusBanner
              formAnswerUlid={metadata?.ulid ?? ''}
              filledDate={getFilledDate()}
              author={selectedAnswer?.name ?? ''}
              isPausable={isPausable()}
              displayElapsedTime
            />
          )}
          <div className={localClasses.groupDiv}>
            <Typography variant="h3" display="flex">
              Anexos
            </Typography>
            {attachments === undefined ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={53}
                style={{ borderRadius: 5 }}
              />
            ) : attachments === null || attachments.length === 0 ? (
              <Typography
                fontStyle="italic"
                color={theme.palette.secondary.dark}
              >
                Nenhum anexo encontrado
              </Typography>
            ) : (
              <div className={localClasses.listDiv}>
                {attachments.map((attachment, idx) => (
                  <Fragment key={`attachment-${idx}`}>
                    {idx > 0 && <Divider />}
                    <AttachmentButton
                      attachment={attachment}
                      alertHook={alertHook}
                    />
                  </Fragment>
                ))}
              </div>
            )}
          </div>
          <div className={localClasses.groupDiv}>
            <Typography variant="h3" display="flex">
              Links
            </Typography>
            {links === undefined ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={53}
                style={{ borderRadius: 5 }}
              />
            ) : links === null || links.length === 0 ? (
              <Typography
                fontStyle="italic"
                color={theme.palette.secondary.dark}
              >
                Nenhum material encontrado
              </Typography>
            ) : (
              <div className={localClasses.listDiv}>
                {links.map((link, idx) => (
                  <Fragment key={`link-item-${idx}`}>
                    {idx > 0 && <Divider />}
                    <LinkButton dqsLink={link} />
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      </MainContainer>
      <SnackbarAlert {...alertHook.state} />
    </Fragment>
  );
}

interface AttachmentButtonProps {
  attachment: S3FileWrapper;
  alertHook: UseAlertInterface;
}

function AttachmentButton(props: AttachmentButtonProps) {
  const { attachment, alertHook } = props;
  const theme = useTheme();
  const localClasses = useDqsAttachStyles(theme);
  const [downloading, setDownloading] = useState<boolean>(false);
  async function downloadAttachment() {
    try {
      setDownloading(true);
      const apires = await api.get<PresignedDownload>(
        '/dash/presignedUrlDownload',
        {
          params: attachment.bucketData,
          timeout: API_TIMEOUT
        }
      );
      const url = apires.data.url as string;
      if (!url) return;
      const link = document.createElement('a');
      link.download = attachment.id;
      link.href = url;
      link.click();
    } catch (error) {
      console.error(error);
      alertHook.error('Não foi possível baixar o arquivo!');
    } finally {
      setDownloading(false);
    }
  }
  return (
    <Button
      variant="text"
      fullWidth
      className={localClasses.materialButton}
      startIcon={<AttachmentRoundedIcon />}
      onClick={downloadAttachment}
    >
      <Typography
        flexGrow={1}
        textAlign="left"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {attachment?.displayName ?? attachment.id}
      </Typography>
      {downloading ? (
        <CircularProgress size={20} />
      ) : (
        <FileDownloadRoundedIcon fontSize="small" />
      )}
    </Button>
  );
}

interface LinkButtonProps {
  dqsLink: Link;
}

function LinkButton(props: LinkButtonProps) {
  const { dqsLink } = props;
  const theme = useTheme();
  const localClasses = useDqsAttachStyles(theme);
  return (
    <Button
      variant="text"
      fullWidth
      className={localClasses.materialButton}
      startIcon={<LinkRoundedIcon />}
      onClick={() => {
        window.open(dqsLink.url);
      }}
    >
      <Typography flexGrow={1} textAlign="left">
        {dqsLink?.title ?? dqsLink.url}
      </Typography>
      <OpenInNewRoundedIcon fontSize="small" />
    </Button>
  );
}
