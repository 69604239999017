import ReactDOM from 'react-dom';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';

import { msalConfig } from './config/msalAuthConfig';

import './index.css';
import Routes from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import { isOnline, isOffline } from './slices/sessionSlice';
import store from './store';
import LaunchScreen from './components/LaunchScreen';
import PullToRefresh from './components/PullToRefresh';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

window.addEventListener('online', () => store.dispatch(isOnline()));
window.addEventListener('offline', () => store.dispatch(isOffline()));
window.onload = () => {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    window.resizeTo(500, 900);
  }
};

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LaunchScreen />
          <PullToRefresh options={{ threshold: 150 }}>
            <BrowserRouter
              getUserConfirmation={() => {
                /* Empty callback to block the default browser prompt */
              }}
            >
              <Routes />
            </BrowserRouter>
          </PullToRefresh>
        </ThemeProvider>
      </MsalProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
