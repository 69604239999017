import { makeStyles } from '@mui/styles';
import theme from './theme';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    flexDirection: 'column',
    flexGrow: 1,
    userSelect: 'none',
    overflow: 'hidden',
    padding: '16px'
  },
  mainContainer: {
    position: 'fixed',
    padding: '18px 6px 11px 11px',
    top: 60,
    overflow: 'scroll',
    paddingBottom: 125,
    width: 'calc(100% - 5px)'
  },
  mainContainerNoPad: {
    paddingBottom: 0
  },
  bodyList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%'
  },
  title: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 38,
    lineHeight: '38px',
    fontWeight: 700
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '18px',
    marginTop: 16
  },
  subsubtitle: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 700,
    marginTop: 16
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 16,
    paddingBottom: 24,
    boxShadow: '0 -2px 4px rgba(0,0,0,0.05)',
    backgroundColor: theme.palette.background.default,
    borderTop: '1px solid rgba(0,0,0,0.05)'
  },
  footerInner: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 0,
    maxWidth: 600
  },
  nextButton: {
    width: '100%',
    height: 48,
    maxWidth: '568px', // max-width 600px minus 16px each side = 568px
    alignSelf: 'center',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '14px',
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1000
  },
  secButton: {
    color: theme.palette.primary.main,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    width: '100%',
    maxWidth: '568px',
    border: 'solid',
    borderWidth: '1px',
    marginBottom: 10,
    height: 45,
    fontWeight: 400,
    fontSize: '15px',
    textDecoration: 'none'
  },
  dialogBackdrop: {
    background: 'rgba(20,20,60,0.75)',
    padding: 0,
    margin: 0,
    '& .MuiDialog-paper': {
      padding: 0,
      margin: 0
    }
  },
  dialogPaper: {
    borderRadius: 5
  },
  camViewport: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'fit-content',
    maxHeight: 'calc(100% - 150px)'
  },
  camBackground: {
    backgroundColor: 'black',
    height: '100%',
    width: '100vw',
    position: 'absolute',
    left: 0,
    top: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  scrollableContent: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '3px'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.30)',
      marginBottom: 75,
      marginTop: 30,
      border: '0px solid black',
      borderRadius: 20
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main
    }
  },
  scrollableContentLight: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 3
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(255,255,255,0.4)',
      marginBottom: 8,
      marginTop: 8
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.common.white
    }
  },
  scrollableStepDiv: {
    paddingBottom: 40,
    marginBottom: 16,
    overflowY: 'auto',
    flexShrink: 0,
    '&::-webkit-scrollbar': {
      width: '3px'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.30)',
      marginBottom: 75,
      border: '0px solid black',
      borderRadius: 20
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main
    }
  },
  scrollableCoworkers: {
    paddingBottom: 0,
    marginBottom: 0,
    overflowY: 'auto',
    flexShrink: 0,
    '&::-webkit-scrollbar': {
      width: '3px'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.30)',
      marginBottom: 140,
      border: '0px solid black',
      borderRadius: 20
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main
    }
  },
  onlineScrollableContent: {
    '&::-webkit-scrollbar-track': {
      marginBottom: 120,
      border: '0px solid black',
      borderRadius: 20
    }
  },
  offlineScrollableContent: {
    '&::-webkit-scrollbar-track': {
      marginBottom: 165,
      border: '0px solid black',
      borderRadius: 20
    }
  },
  buttonlessScrollableContent: {
    '&::-webkit-scrollbar-track': {
      marginBottom: 0,
      border: '0px solid black',
      borderRadius: 20
    }
  },
  keyboardQuestionContainer: {
    minHeight: 150,
    width: '100%'
  },
  fadein: {
    transition: 'opacity 1s'
  },
  arrowButton: {
    width: '100%',
    borderRadius: 5,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    gap: 20
  },
  titlebox: {
    display: 'flex',
    gap: 8,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  inputOutlined: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    backgroundColor: 'rgba(255,255,255,0.4)',
    padding: '6px 10px',
    '& input': {
      padding: 0
    }
  },
  inputOutlinedError: {
    borderColor: theme.palette.error.main,
    backgroundColor: '#FEF2F2' //theme.palette.error.light,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6
  },
  gap18: {
    gap: 18
  },
  selectRoot: {
    padding: 0,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    overflow: 'hidden',
    borderRadius: 5,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    color: '#777777',
    transition: 'border-color 500ms, box-shadow 500ms',
    fontSize: 14,
    justifyContent: 'center',
    minHeight: 38,
    '& .MuiInputBase-input': {
      padding: 0,
      paddingTop: 1,
      paddingLeft: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: '-1px 1px 4px rgba(0, 0, 0, 0.15)'
    },
    '&:focus-within': {
      backgroundColor: 'rgba(40,40,70,0.05)',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      boxShadow: '-1px 1px 4px rgba(0, 0, 0, 0.15)'
    },
    '& .MuiFilledInput-input:focus': {
      backgroundColor: 'transparent'
    },
    '& .MuiInputAdornment-root': {
      margin: 0,
      paddingLeft: 10,
      marginRight: -4
    }
  },
  paperMenu: {
    maxHeight: 250,
    marginTop: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.background.default,
    border: '1px solid',
    '&::-webkit-scrollbar': {
      width: '3px'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.30)',
      margin: '10px 0'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main
    }
  },
  menuItem: {
    fontSize: 12,
    minHeight: 10
  },
  selectMenuList: {
    padding: '0px 0px'
  },
  ssoButton: {
    textDecoration: 'none',
    fontWeight: 400,
    height: 40,
    backgroundColor: '#E9F2FC',
    color: theme.palette.primary.main,
    textTransform: 'unset',
    transition: 'opacity 500ms',
    '&:hover': {
      backgroundColor: '#E9F2FC'
    },
    '&.Mui-disabled': {
      backgroundColor: '#E9F2FC',
      color: theme.palette.primary.main,
      opacity: '70%'
    }
  },
  textInputBase: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    minHeight: 38,
    padding: '4px 10px'
  },
  termsAndConditionsNav: {
    borderBottom: '1px solid rgba(0,0,0,0.05)',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  termsAndConditionsWrapper: {
    height: '100%',
    paddingRight: 17,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 3
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      marginBottom: 15,
      marginTop: 15,
      border: '0px solid black',
      borderRadius: 20
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      border: '0px solid black',
      borderRadius: '25%'
    }
  },
  termsAndConditionsTitle: {
    fontSize: 26,
    fontWeight: 900,
    textAlign: 'center',
    paddingTop: 22
  },
  termsAndConditionsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    textAlign: 'justify',
    paddingBottom: 22
  },
  dialogBody: {
    padding: 0,
    paddingLeft: 22,
    paddingRight: 3,
    overflow: 'hidden'
  },
  link: {
    color: '#05bee3',
    textDecoration: 'underline',
    fontWeight: 500
  },
  loadingImage: {
    borderRadius: 5
  }
}));

export default useStyles;
