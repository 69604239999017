import axios, { AxiosResponse } from 'axios';
import { version } from '../version.json';
import { publish, subscribe, unsubscribe } from './events';
import { QueryKey, VoroPrefetchOptions } from '../types/voroquery';
import db, { MutationCache } from './db';
import { MutationKey } from '../types/voromutation';
import getSupportContacts from '../utils/supportContacts';
import { SupportContact } from '../types/utils';
import { TroubleshootResponse } from '../../vorotypes/types/routes.app.responses';
import api, { API_TIMEOUT } from './api';
import { downloadURI } from './svg';
import { fileToBase64 } from './files';
import {
  getCacheableAxiosError,
  uploadPhotosBeforePost,
  uploadPhotosFromObject
} from '../utils/helpers';
import {
  AddFormAnswerPostData,
  CancelPostData,
  InspectPostData,
  TroubleshootMutationReqBody
} from '../../vorotypes/types/routes.app.requests';
import {
  AppLocalFormAnswer,
  FAMutationSubmitPackage
} from '../../vorotypes/types/formAnswer';
import Cookies from 'js-cookie';
import { Template } from '../../vorotypes/types/formTemplate';
import { FormAnswerEdit } from '../types/form';

interface UseVoroQueryClient {
  downloadMutation: (mutationKey: MutationKey) => Promise<void>;
  getQueryData: <TQueryFnData>(
    queryKey: QueryKey
  ) => Promise<TQueryFnData | undefined>;
  setQueryData: <TQueryFnData>(
    queryKey: QueryKey,
    payload: TQueryFnData
  ) => Promise<void>;
  pushQueryData: <TQueryFnData>(
    queryKey: QueryKey,
    payload: TQueryFnData
  ) => Promise<void>;
  removeMutationFromCache: (mutationKey: MutationKey) => Promise<void>;
  resetInterruptedMutations: () => Promise<void>;
  resumePausedMutations: () => Promise<void>;
  shareMutation: (
    mutationKey: MutationKey,
    channel: 'whatsapp' | 'telegram'
  ) => Promise<AxiosResponse<any> | undefined>;
}
class VoroQueryClient implements UseVoroQueryClient {
  maxRetries = 12;
  constructor() {
    window.addEventListener('beforeunload', this.cleanup);
    // listening to changes in IDB
    this.pushMutations = this.pushMutations.bind(this);
    subscribe<MutationEvent>('mutationpost', this.pushMutations);
    subscribe<MutationEvent>('mutationerror', this.pushMutations);
  }
  /**
   * Backups a mutation
   * @description sets the status of a mutation to 'backup'
   * @param mutationKey target mutation's key
   * @author Douglas Flores
   */
  async backupMutation(mutationKey: MutationKey) {
    await db.mutationCache.update(mutationKey, { status: 'backup' });
    publish<MutationEvent>('mutationcacheupdated');
  }
  /**
   * @description Unsubscribes from all events
   * @author Douglas Flores
   */
  cleanup() {
    unsubscribe<MutationEvent>('mutationpost', this.pushMutations);
    unsubscribe<MutationEvent>('mutationerror', this.pushMutations);
    window.removeEventListener('beforeunload', this.cleanup);
  }
  /**
   * Downloads a mutation as a JSON file
   * @param mutationKey target mutation's key
   * @author Douglas Flores
   */
  async downloadMutation(mutationKey: MutationKey) {
    try {
      // Getting the mutation
      const mutation = (
        await db.mutationCache.where('key').equals(mutationKey).toArray()
      )[0];

      // Creating a JSON file
      const filename = `${mutation.key}.json`;
      const file = new File([JSON.stringify(mutation)], filename, {
        type: 'application/json'
      });

      // Getting base64 JSON file
      const base64file = await fileToBase64(file);
      downloadURI(base64file, filename);
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * Gets all pending mutations
   * @returns all mutations that are pending to send
   * @author Douglas Flores
   */
  getPendingMutations(): Promise<MutationCache[]> {
    return db.mutationCache
      .where('status')
      .noneOf(['pushed', 'backup'])
      .toArray();
  }
  /**
   * Gets the data of a query stored in cache
   * @param queryKey the key of the query to fetch
   * @returns the data of a query stored in cache
   * @author Douglas Flores
   */
  async getQueryData<TQueryFnData = unknown>(
    queryKey: QueryKey // filters?: QueryFilters
  ): Promise<TQueryFnData | undefined> {
    try {
      const dbRes = await db.queryCache
        .filter(x => queryKey.every(y => x.key.includes(y)))
        .toArray();
      const fetchedData = dbRes[0];
      const response = fetchedData.data;
      return response;
    } catch (error) {
      console.error(error);
      console.log(queryKey);
      return undefined;
    }
  }
  /**
   * Pushes a mutation to the api
   * @description selects the right push function based on the mutation key
   * @param mutation the mutation object stored in mutationCache table
   * @returns a Promise of an axios response from the api informing the result of the request
   * @author Douglas Flores
   */
  async pushMutation(mutation: MutationCache) {
    let response;
    // Updating mutation status to pushing
    await db.mutationCache.update(mutation.key, { status: 'pushing' });
    publish<MutationEvent>('mutationcacheupdated');
    /* Pushing the mutation */
    try {
      // Selecting the right function to push the mutation
      if (mutation.key.includes('addFormAnswer'))
        response = await addFormAnswerMutationFn(mutation.data);
      else if (mutation.key.includes('editFormAnswer'))
        response = await editFormAnswerMutationFn(mutation.data);
      else if (mutation.key.includes('cancelFormAnswer'))
        response = await cancelFormAnswerMutationFn(mutation.data);
      else if (mutation.key.includes('inspectFormAnswer'))
        response = await inspectFormAnswerMutationFn(mutation.data);
      else throw new Error('Unknown mutation type');

      await db.mutationCache.update(mutation.key, { status: 'pushed' });
      publish<MutationEvent>('mutationcacheupdated');
    } catch (error: any) {
      // Error subroutine before effectively throwing the error
      if (
        axios.isAxiosError(error) &&
        error.response?.status === 409 &&
        !!mutation.key
      ) {
        // Duplicate error, remove from mutationCache
        await db.mutationCache.delete(mutation.key);
      } else {
        // Unknown error
        let errorPkg = axios.isAxiosError(error)
          ? getCacheableAxiosError(error)
          : typeof error === 'string'
          ? { message: error }
          : { message: error?.message ?? undefined };
        const payload =
          error?.message === 'Network Error'
            ? {
                status: 'paused',
                retry: mutation.retry,
                errorInfo: errorPkg
              }
            : {
                status: 'pushError',
                retry: mutation.retry + 1,
                errorInfo: errorPkg
              };
        try {
          await db.mutationCache.update(mutation.key, payload);
        } catch (error) {
          console.log(error);
          db.mutationCache.update(mutation.key, {
            status: 'pushError'
          });
        }
      }
      publish<MutationEvent>('mutationcacheupdated');
      setTimeout(() => publish<MutationEvent>('mutationerror'), 5 * 60 * 1000);
      throw error; // throwing the error is important to break loop of pushMutations, avoiding that mutations are pushed out of order
    } finally {
      return response;
    }
  }
  /**
   * Prefetches a queryKey if there is no local data yet
   * @param params refers to VoroPrefetchOptions
   * @author Douglas Flores
   */
  async prefetchQuery<TQueryFnData = unknown>(
    params: VoroPrefetchOptions<TQueryFnData>
  ) {
    try {
      // Do not attempt to do anything if we're offline
      if (!navigator.onLine) throw new Error('Network Error: offline');
      // Checking local data
      const dbres = await db.queryCache
        .filter(x => params.queryKey.every(y => x.key.includes(y)))
        .toArray();
      /*
       * If there is data, do nothing
       * Otherwise, fetches data in the api
       */
      if (!dbres || !dbres?.length) {
        // Fetching... and updating local DB
        const queryResult = await params.queryFn(params.route);
        db.queryCache.put({
          key: params.queryKey,
          data: queryResult
        });
      }
    } catch (error) {
      console.error(params.route, error);
      if (!!params?.onError) params.onError(error);
    }
  }
  /**
   * Tries to sequentially push mutations in cache
   * If one push fails, the function stops
   * @author Douglas Flores
   */
  async pushMutations(): Promise<void> {
    console.log('[LOG] Pushing mutations');
    var pushedCount = 0;
    try {
      if (!navigator.onLine)
        throw new Error('Cannot push mutations while offline');
      // Getting mutations from cache
      const mutations = await db.mutationCache.toArray();
      // Run mutations sequentially
      for (let i = 0; i <= mutations.length - 1; i++) {
        const mutation = mutations[i];
        let readyToSync = ['localSuccess', 'pushError', 'paused'].includes(
          mutation.status
        );
        if (readyToSync) {
          if (mutation.retry < this.maxRetries) {
            await this.pushMutation(mutation);
            pushedCount += 1;
          } else {
            const ulid = mutation.key[0] as string;
            const hash = `${ulid[2]}${ulid[3]}${ulid[5]}${ulid[7]}${ulid[11]}${ulid[13]}`;
            const apires = await this.troubleshootMutation(mutation, hash);
            // If solved, deletes the failed mutation
            if (apires?.solved) await db.mutationCache.delete(mutation.key);
            // If not solved, backups the mutation
            else await this.backupMutation(mutation.key);
          }
        }
        // Ignores backups and mutations with local errors
        else if (['localError', 'backup'].includes(mutation.status)) continue;
        // A pushed mutation may be deleted from cache
        else if (mutation.status === 'pushed')
          await db.mutationCache.delete(mutation.key);
        // A mutation not ready to sync breaks the sync loop
        else if (!readyToSync) break;
      }
    } catch (error) {
      console.error('[PUSHING]' + error);
    } finally {
      /* Publising an event letting queries know that all pending mutations were cleared */
      if (pushedCount > 0) {
        this.getPendingMutations()
          .then(pendingMutations => {
            if (!pendingMutations || pendingMutations?.length === 0)
              publish<MutationEvent>('mutationssynched');
          })
          .catch(err => console.error(err));
      }
    }
  }
  /**
   * Pushes an item into an data's array from queryCache
   * @description retrieves the data indexed by the queryKey, checks if the data is an array and, if it is, pushes the payload into the array and saves the new array into queryCache
   * @param queryKey the target query's key
   * @param payload the data to be pushed to the queryCache
   * @author Douglas Flores
   */
  async pushQueryData<TQueryFnData = unknown>(
    queryKey: QueryKey,
    payload: TQueryFnData
  ) {
    try {
      const cached = await db.queryCache
        .filter(x => queryKey.every(y => x.key.includes(y)))
        .toArray();
      const old = cached[0].data;
      if (!Array.isArray(old))
        throw Error('Tried to push data into a non-array');
      await db.queryCache.put(
        { key: queryKey, data: [...old, payload] },
        queryKey
      );
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * Removes a mutation from mutationCache
   * @param mutationKey the target mutation's key
   * @returns a promise of Dexie delete
   * @author Douglas Flores
   */
  async removeMutationFromCache(mutationKey: MutationKey) {
    return db.mutationCache
      .delete(mutationKey)
      .then(_ => publish<MutationEvent>('mutationcacheupdated'));
  }
  /**
   * USE WITH CAUTION! Resets the "pushing" mutations to "pushError"
   * @description used mainly to restart mutations stuck in pushing status due to interruption of app's execution
   * @author Douglas Flores
   */
  async resetInterruptedMutations() {
    const mutations = await db.mutationCache
      .where('status')
      .anyOf(['pushing', 'error']) // TODO: Remove 'error' from the array once we can assure no one as a mutation with 'error'
      .toArray();
    for (let i = 0; i < mutations.length; i++) {
      const mutation = mutations[i];
      try {
        await db.mutationCache.update(mutation.key, { status: 'pushError' });
        publish<MutationEvent>('mutationerror');
      } catch (error) {
        console.error(error);
      }
    }
  }
  /**
   * @description Tries to push again mutations that are paused in cache
   * @author Douglas Flores
   */
  async resumePausedMutations() {
    return this.pushMutations();
    // try {
    //   if (!navigator.onLine) throw new Error('Network Error');
    //   // Getting mutations from cache
    //   const mutations = await db.mutationCache.toArray();
    //   // Run mutations sequentially
    //   for (let i = mutations.length - 1; i >= 0; i--) {
    //     const mutation = mutations[i];
    //     if (['paused', 'localSuccess'].includes(mutation.status))
    //       await this.pushMutation(mutation);
    //     // Ignores mutations with local errors
    //     else if (mutation.status === 'localError') continue;
    //     // A pushed mutation may be deleted from cache
    //     else if (mutation.status === 'pushed')
    //       await db.mutationCache.delete(mutation.key);
    //     // A mutation not ready to resume breaks the sync loop
    //     else break;
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   this.getPendingMutations()
    //     .then(pendingMutations => {
    //       /* Publising an event letting queries know that all pending mutations were cleared */
    //       if (!pendingMutations || pendingMutations?.length === 0)
    //         publish<MutationEvent>('mutationssynched');
    //     })
    //     .catch(err => console.error(err));
    // }
  }
  /**
   * Sets the data of a query
   * @param queryKey the query's key
   * @param payload what to put in the query's response
   * @author Douglas Flores
   */
  async setQueryData<TQueryFnData = unknown>(
    queryKey: QueryKey,
    payload: TQueryFnData
  ): Promise<void> {
    await db.queryCache.update(queryKey, payload as { [keyPath: string]: any });
    publish<MutationEvent>('mutationcacheupdated');
  }
  /**
   * Sends a mutation through a cannel
   * @param mutationKey target mutation's key
   * @param channel the channel to send the mutation through
   * @param message an optional message to send with the mutation's file
   */
  async shareMutation(
    mutationKey: MutationKey,
    channel: 'whatsapp' | 'telegram',
    message?: string
  ) {
    try {
      const ulid = mutationKey[0] as string;
      const hash = `${ulid[2]}${ulid[3]}${ulid[5]}${ulid[7]}${ulid[11]}${ulid[13]}`;
      // Getting the mutation
      const mutation = (
        await db.mutationCache.where('key').equals(mutationKey).toArray()
      )[0];

      if (channel === 'whatsapp') {
        // Getting support contact
        const supportContacts: SupportContact[] = getSupportContacts();
        const contact = supportContacts[0];

        // Redirect to WhatsApp
        var url = `https://api.whatsapp.com/send?phone=${contact.ddi}${
          contact.ddd
        }${contact.number.replace('-', '')}&text=${JSON.stringify({
          ...mutation,
          errorInfo: !!mutation?.errorInfo ? mutation.errorInfo : undefined,
          hash: hash,
          userAgent: navigator.userAgent
        })}`;
        window.location.href = url;
      } else if (channel === 'telegram') {
        /* Building the file */
        const stringKey = `${mutationKey}`;
        const file = new File(
          [
            JSON.stringify({
              ...mutation,
              errorInfo: !!mutation?.errorInfo ? mutation.errorInfo : undefined,
              hash: hash
            })
          ],
          `${stringKey.replace(',', '-')}.json`,
          {
            type: 'application/json'
          }
        );
        /* Building form-data */
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userAgent', navigator.userAgent);
        if (!!message) formData.append('caption', message);
        /* Posting file */
        return api.post('/sendTelegramDocument', formData);
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      await db.mutationCache.update(mutationKey, {
        'errorInfo.dateOfSendingToSupport': new Date().toISOString()
      });
    }
  }
  /**
   * Tries to solve a failed mutation automatically
   * and logs the result to the support team
   * @param mutation a failed mutation
   * @param hash to set mutation as resolved by the user (seen by support team only)
   */
  async troubleshootMutation(
    mutation: MutationCache,
    hash?: string
  ): Promise<TroubleshootResponse | undefined> {
    try {
      const apires = await api.patch<TroubleshootResponse>(
        '/app/troubleshootMutation',
        { mutation, hash } as TroubleshootMutationReqBody
      );
      return apires?.data;
    } catch (error) {
      console.error(error);
      return undefined;
    } finally {
      db.mutationCache
        .update(mutation.key, { retry: mutation.retry + 1 })
        .catch(err => console.error(err));
    }
  }
}

const voroQueryClient = new VoroQueryClient();

export default voroQueryClient;

export type MutationEvent =
  | 'mutationpost'
  | 'mutationssynched'
  | 'mutationcacheupdated'
  | 'mutationerror'; // possible future events: 'mutationpushed'

/**
 * Mutation function to addFormAnswer
 * @param variables a package with the necessary data to insert a FA into the DB
 * @returns api's response
 */
export async function addFormAnswerMutationFn(
  variables: FAMutationSubmitPackage
) {
  console.log('@ sendFormAnswer');
  /** Getting token data */
  const token = Cookies.get('token');
  const { id_company } =
    (token && JSON.parse(atob(token.split('.')[1]).toString())) || {};

  /** Interface to deal with the old variables type and the current */
  // const formAnswer= answer.formAnswer;
  const commonGround: any = variables;
  const template =
    variables?.template ?? (commonGround?.formTemplate as Template);
  const ulid = variables?.ulid ?? commonGround?.metadata?.ulid;
  const uuid =
    variables?.answer?.metadata?.uuid ?? commonGround?.metadata?.uuid;
  const answerItems = variables?.answer?.items ?? commonGround?.formAnswer;
  const nonExecAnswer = variables?.nonExecAnswer ?? commonGround?.nonExecAnswer;
  const signatures = variables?.answer?.signatures ?? commonGround?.signatures;
  const authentication =
    variables?.answer?.authentication ?? commonGround?.authentication;
  const id_formTemplate =
    variables?.id_formTemplate ?? commonGround?.template_id;
  /** Building json package to insert */
  // const answer = variables.answer;
  let jsonData: AddFormAnswerPostData = {
    ulid: ulid,
    id_formTemplate: id_formTemplate,
    answer: {
      metadata: variables?.answer?.metadata ??
        commonGround?.metadata ?? {
          title: 'DOC',
          app_version: version
        },
      items: answerItems,
      signatures: signatures,
      authentication: authentication
    },
    nonExecAnswer: nonExecAnswer
  };
  jsonData = await uploadPhotosBeforePost(jsonData, id_company);
  /* Defining submission url */
  const postURL =
    Object.keys(answerItems).includes('NonExecution') || !!nonExecAnswer
      ? '/app/nonExecution'
      : '/app/addFormAnswer';
  const postHeader = {
    'vorotech-required-version':
      Object.keys(answerItems).includes('NonExecution') || !!nonExecAnswer
        ? '^3.0.0'
        : '^3.0.0'
  };
  // Posting
  return api.post(postURL, jsonData, {
    timeout: API_TIMEOUT,
    headers: postHeader
  });
}

export async function addFormAnswerOnMutate(variables: any) {
  // Cancel any fetching operations regarding form answers
  // await queryClient.cancelQueries(['cachedFormAnswers']);
  // Getting cached form answers before mutation execution
  const previousData = await voroQueryClient.getQueryData<any[] | undefined>([
    'cachedFormAnswers'
  ]);
  /* Building local form answer (stored until the mutation succeds) */
  const status =
    Object.keys(variables.formAnswer).includes('NonExecution') ||
    !!variables?.nonExecAnswer
      ? 'nonExecution'
      : variables.formTemplate?.config?.persistent
      ? 'ongoing'
      : 'ok';
  let answerStatus = {
    status: status
  };
  if (status === 'nonExecution' && !!variables?.nonExecAnswer) {
    Object.assign(answerStatus, {
      nonExecutionData: { answer: variables.nonExecAnswer }
    });
  }
  const optimisticFormAnswer = {
    answer: {
      items: variables.formAnswer,
      metadata: variables.metadata,
      filledDate: variables.datetime,
      signatures: variables?.signatures
    },
    template: variables.formTemplate,
    onlyLocallyAvailable: true,
    answerStatus: answerStatus
  };
  // Inserting local form answer into cache
  voroQueryClient.setQueryData(['cachedFormAnswers'], (old: any) => [
    ...old,
    optimisticFormAnswer
  ]);
  /* returning data */
  return { previousData, optimisticFormAnswer };
}

/**
 * Mutation function to cancelFormAnswer
 * @param cancelData cancel data
 * @returns the api's response
 */
export async function cancelFormAnswerMutationFn(cancelData: CancelPostData) {
  console.log('@ sendFormCancellation');
  /** Getting token data */
  const token = Cookies.get('token');
  const { id_company } =
    (token && JSON.parse(atob(token.split('.')[1]).toString())) || {};
  /** Uploading Photos */
  const uploadDirectory = `company_${id_company}/${cancelData.formAnswerUlid}/cancellation`;
  const { object: updatedCancelData } = await uploadPhotosFromObject(
    cancelData,
    uploadDirectory
  );
  /* Posting */
  return await api.post('/app/cancelAnswer/v2', updatedCancelData);
}

/**
 * Mutation function to editFormAnswer
 * @param edit edit pack requisition
 * @returns the api's response
 */
export async function editFormAnswerMutationFn(edit: FormAnswerEdit) {
  console.log('@ sendFormEdit');
  /** Getting token data */
  const token = Cookies.get('token');
  const { id_company } =
    (token && JSON.parse(atob(token.split('.')[1]).toString())) || {};
  /* Getting cachedFormAnswers */
  const formAnswers =
    (await voroQueryClient?.getQueryData<AppLocalFormAnswer[]>([
      'cachedFormAnswers'
    ])) ?? [];
  if (!formAnswers?.length) throw new Error('Found no local form answer');
  /* Getting the form-answer */
  const selectedAnswer = formAnswers.find(
    fa => fa.answer.metadata.ulid === edit.postData.formAnswerUlid
  );
  if (!selectedAnswer) throw new Error('Target form-answer not found');
  /** Defining formanswer's directory */
  const uploadDirectory = `company_${id_company}/${edit.postData.formAnswerUlid}/completion`;
  /** Uploading Photos */
  const { object: updatedPostData } = await uploadPhotosFromObject(
    edit.postData,
    uploadDirectory
  );
  /* Posting */
  const res = await api.post(edit.route, updatedPostData, edit?.config);
  /* Returning post response */
  return res;
}

/**
 * Mutation function to inspectFormAnswer
 * @param edit edit pack requisition
 * @returns the api's response
 */
export async function inspectFormAnswerMutationFn(
  inspectPostData: InspectPostData
) {
  console.log('@ sendFormInspection');
  /* Getting token data */
  const token = Cookies.get('token');
  const { id_company } =
    (token && JSON.parse(atob(token.split('.')[1]).toString())) || {};
  /* Getting cachedFormAnswers */
  const formAnswers =
    (await voroQueryClient?.getQueryData<AppLocalFormAnswer[]>([
      'cachedFormAnswers'
    ])) ?? [];
  if (!formAnswers?.length) throw new Error('Found no local form answer');
  /* Getting the id of the form-answer */
  const fa = formAnswers.find(
    (fa: any) => fa.answer.metadata.ulid === inspectPostData.ulid_formAnswer
  );
  if (!fa)
    throw new Error(
      "Couldn't find form answer's id. Maybe the form answer was not submited to the api yet."
    );
  /** Defining formanswer's directory */
  const uploadDirectory = `company_${id_company}/${inspectPostData.uuid_formAnswer}/inspection`;
  /* ------------------------- */
  /** --- Uploading Photos --- */
  /* ------------------------- */
  const { object: updatedPostData } = await uploadPhotosFromObject(
    inspectPostData,
    uploadDirectory
  );
  /* Posting */
  const res = await api.post('/app/formAnswer/inspect', updatedPostData);
  /* Returning post response */
  return res;
}
