import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, ContainerProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../../theme';
import clsx from 'clsx';
import { navbarHeights } from '../NavBar/styles';

type MainContainerProps = {
  isOnline: boolean;
  scrollable?: boolean;
  bottomButtons?: number;
  noPadding?: boolean;
};

const localStyles = makeStyles(theme => ({
  scrollable: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '3px'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.30)',
      marginBottom: 75,
      marginTop: 25,
      border: '0px solid black',
      borderRadius: 20
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export default function MainContainer(
  props: ContainerProps & MainContainerProps
) {
  const location = useLocation();
  const localClasses = localStyles(theme);
  const { isOnline, scrollable, bottomButtons, className, style, ...other } =
    props;
  const containerDiv = useRef<HTMLDivElement>(null);
  const onlineHeight = navbarHeights.toolbar;
  const offlineHeight = navbarHeights.toolbar + navbarHeights.offlineAlert;

  useEffect(() => {
    if (scrollable) {
      containerDiv?.current?.scroll({
        top: 0,
        behavior: 'auto'
      });
    }
  }, [location, scrollable]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 8,
        minHeight: isOnline
          ? `calc(100dvh - ${onlineHeight}px)`
          : `calc(100dvh - ${offlineHeight}px)`
      }}
    >
      <Container
        {...other}
        className={
          !scrollable ? className : clsx(className, localClasses.scrollable)
        }
        ref={containerDiv}
        style={{
          paddingTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: !bottomButtons ? 24 : 64 + bottomButtons * 48,
          width: '100%',
          maxWidth: 600,
          overflowX: 'hidden',
          ...style
        }}
      >
        {props.children}
      </Container>
    </div>
  );
}
