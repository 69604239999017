/* Global imports */
import React, { useEffect, useState } from 'react';
import { Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';
/* Icon imports */
import GradingRoundedIcon from '@mui/icons-material/GradingRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
/* Local imports */
import {
  useFormTemplates,
  useFormAnswerInspections,
  useCoworkers
} from '../../hooks/query.hooks';
import { GeneralPhoto, ParticipantSignature } from '../../types/form';
import { Coworker } from '../../../vorotypes/types/user';
import RenderSteps from './components/RenderSteps';
import formAnswerStyles from './styles';
import theme from '../../theme';
import Signatures from '../Signatures';
import AnswerGeneralPhotos from './components/AnswerGeneralPhotos';
import { ParticipantJob } from '../../../vorotypes/internalCodes/participant';
import LoadingImage from '../LoadingImage';
import useStyles from '../../useStyles';
import {
  FormField,
  FormStep,
  FormTemplate,
  Template
} from '../../../vorotypes/types/formTemplate';
import {
  AnswerStatus,
  AuthenticationFormAnswer
} from '../../../vorotypes/types/formAnswer';

type AnswerProps = {
  template: number | Template;
  answer: { [key: string]: any };
  metadata?: any;
  status?: AnswerStatus;
  signatures?: ParticipantSignature[];
  authentication?: AuthenticationFormAnswer;
};

export default function Answer(props: AnswerProps) {
  const { template, answer, metadata, status, signatures, authentication } =
    props;
  const classes = useStyles();
  const localClasses = formAnswerStyles();
  /* Hooks */
  const { data: formTemplates } = useFormTemplates({
    fetchOnlyOnEmptyCache: true
  });
  const { data: coworkersData, isLoading: loadingCoworkers } = useCoworkers();
  const { data: inspections } = useFormAnswerInspections(metadata?.ulid);
  /* State */
  const [signaturesState, setSignaturesState] =
    useState<ParticipantSignature[]>();
  /* Functions */
  const isLegacyNonExec = () => {
    return (
      !!status &&
      status.status === 'nonExecution' &&
      !status?.nonExecutionData?.answer
    );
  };
  /* Const vars */
  const coworkers = coworkersData?.coworkers || [];
  const formTemplate: Template | undefined =
    typeof template === 'number'
      ? formTemplates?.find(
          (formTemplate: FormTemplate) => formTemplate.id === template
        )?.template
      : template;
  const [steps, setSteps] = useState<FormStep[]>(formTemplate?.steps ?? []);
  const completionSteps: Array<FormStep> = [
    {
      id: 0,
      type: 'FieldList',
      fields: formTemplate?.completionFields ?? []
    }
  ];
  const nonExecutionSteps: Array<FormStep> = [
    {
      id: 1,
      type: 'FieldList',
      fields: formTemplate?.nonExecutionFields ?? []
    }
  ];
  const edits = !!status?.edits
    ? [
        ...status.edits.filter((edit: any) =>
          ['add', 'add&cancel', 'change'].includes(edit.action)
        )
      ]
    : undefined;
  /* --- useEffects --- */
  useEffect(() => {
    setSteps(formTemplate?.steps || []);
  }, [formTemplate]);
  useEffect(() => {
    const userFields = steps?.flatMap(step =>
      step.type === 'FieldList'
        ? step.fields.filter(
            (field: FormField) =>
              field.type === 'User' || field.type === 'Users'
          )
        : step.fields.filter(field => field.field_id === 'performers')
    );

    let signaturesArr: ParticipantSignature[] = [];

    const addToSignatures = (
      signature: ParticipantSignature,
      job?: ParticipantJob
    ) => {
      signaturesArr.push({
        ...signature,
        job
      });
    };

    signatures?.forEach(signature => {
      const uf = userFields.find(field => {
        const fieldAnswer = answer[field.field_id];
        if (Array.isArray(fieldAnswer)) {
          if (typeof fieldAnswer[0] === 'number')
            return fieldAnswer.includes(signature.id);
          else return !!fieldAnswer.find(u => u.id === signature.id);
        } else if (typeof fieldAnswer === 'number') {
          return fieldAnswer === signature.id;
        } else if (typeof fieldAnswer === 'object') {
          return fieldAnswer.id === signature.id;
        } else return false;
      });
      const job = uf?.properties.job;
      addToSignatures(signature, job);
    });

    setSignaturesState(signaturesArr);
  }, [steps, answer, signatures]);
  useEffect(() => {
    setSteps(formTemplate?.steps ?? []);
  }, [formTemplate]);
  /* --- Return --- */
  return (
    <React.Fragment>
      {/* Legacy NonExecution Answer */}
      {isLegacyNonExec() && (
        <NonExecutionAnswerLegacy
          answer={answer['NonExecution']}
          photos={answer['nonExecutionPhotos']}
        />
      )}
      {/* Render Form Answer's Steps */}
      <RenderSteps
        steps={steps}
        answer={answer}
        coworkers={coworkers}
        loadingCoworkers={loadingCoworkers}
        edits={edits}
      />
      {/* Render Form Answer's Status */}
      {!!status && status.status === 'cancelled' ? (
        <CancelledAnswerData status={status} />
      ) : !!status &&
        !!formTemplate?.completionFields &&
        status.status === 'ok' ? (
        <CompletionFieldsAnswer
          steps={completionSteps}
          answer={status.completionData?.answer}
          users={coworkers}
          loadingUsers={loadingCoworkers}
          edits={edits}
        />
      ) : (
        !!status &&
        !!formTemplate?.nonExecutionFields &&
        !!status?.nonExecutionData &&
        status.status === 'nonExecution' && (
          <NonExecutionFieldsAnswer
            steps={nonExecutionSteps}
            answer={status.nonExecutionData.answer}
            users={coworkers}
            loadingUsers={loadingCoworkers}
            edits={edits}
          />
        )
      )}

      {/* Signatures */}
      {signaturesState && signaturesState?.length > 0 && (
        <React.Fragment>
          <Typography variant="h2">Assinaturas</Typography>
          <Signatures data={signaturesState} />
        </React.Fragment>
      )}

      {/* ThirdParty Signatures */}
      {authentication?.general?.thirdPartySignatures &&
        authentication.general.thirdPartySignatures?.length > 0 && (
          <React.Fragment>
            <Typography variant="h3">Assinaturas de Terceiros</Typography>
            {authentication.general.thirdPartySignatures.map((photo, idx) => {
              const photoData = photo;
              if (!!photoData?.bucketData) {
                return (
                  <LoadingImage
                    key={photoData.bucketData.key}
                    bucketData={photoData.bucketData}
                    classes={{
                      img: classes.loadingImage,
                      skeleton: classes.loadingImage
                    }}
                  />
                );
              } else if (!!photoData?.base64) {
                return (
                  <div
                    key={`photo-div-${photoData.id}`}
                    className={localClasses.rowContainer}
                    style={{
                      borderRadius: 5,
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      key={photoData.id}
                      src={photoData.base64}
                      alt="foto"
                      width={150}
                    />
                    <Divider />
                  </div>
                );
              } else {
                return (
                  <Typography
                    key={`missing-${idx}`}
                    color={theme.palette.grey[600]}
                  >
                    <i>Nenhuma foto encontrada.</i>
                  </Typography>
                );
              }
            })}
          </React.Fragment>
        )}

      {/* Authentication Data */}
      {authentication?.general?.photos &&
        authentication.general.photos?.length > 0 && (
          <React.Fragment>
            <Typography variant="h3">Fotos de Autenticação</Typography>
            {authentication.general.photos.map((photo, idx) => {
              const photoData = photo;
              if (!!photoData?.bucketData) {
                return (
                  <LoadingImage
                    key={photoData.bucketData.key}
                    bucketData={photoData.bucketData}
                    classes={{
                      img: classes.loadingImage,
                      skeleton: classes.loadingImage
                    }}
                  />
                );
              } else if (!!photoData?.base64) {
                return (
                  <div
                    key={`photo-div-${photoData.id}`}
                    className={localClasses.rowContainer}
                    style={{
                      borderRadius: 5,
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      key={photoData.id}
                      src={photoData.base64}
                      alt="foto"
                      width={150}
                    />
                    <Divider />
                  </div>
                );
              } else {
                return (
                  <Typography
                    key={`missing-${idx}`}
                    color={theme.palette.grey[600]}
                  >
                    <i>Nenhuma foto encontrada.</i>
                  </Typography>
                );
              }
            })}
          </React.Fragment>
        )}

      {/* Inspections */}
      {!!inspections && inspections?.length > 0 && (
        <React.Fragment>
          {inspections?.map((insp, idx) => (
            <div key={insp.dateOfInspection}>
              <Typography variant="h2">Inspeção {idx + 1}</Typography>
              <Typography variant="body1" marginBottom={2} marginTop={1}>
                Realizada em{' '}
                <strong>
                  {dayjs(insp.dateOfInspection).format('DD/MM/YYYY - HH:mm')}
                </strong>
              </Typography>
              <Typography className={localClasses.cellTitle}>Autor</Typography>
              <Typography variant="body1" marginBottom={2}>
                {coworkers.find(u => u.id === insp.id_inspector)?.name}
              </Typography>
              <RenderSteps
                steps={[
                  {
                    id: idx,
                    type: 'FieldList',
                    fields: formTemplate?.inspection?.fields ?? []
                  }
                ]}
                answer={insp.inspection}
                coworkers={coworkers}
                loadingCoworkers={loadingCoworkers}
                edits={edits}
              />
            </div>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

type NonExecutionAnswerLegacyProps = {
  answer?: string;
  photos?: Array<GeneralPhoto>;
};

function NonExecutionAnswerLegacy(props: NonExecutionAnswerLegacyProps) {
  const { answer, photos } = props;
  const localClasses = formAnswerStyles();
  return (
    <React.Fragment>
      <div className={localClasses.rowContainer}>
        <Typography className={localClasses.cellTitle}>
          Motivo da recusa
        </Typography>
        <Typography>{answer ?? 'Não informada.'}</Typography>
      </div>
      {!!photos && (
        <div className={localClasses.rowContainer}>
          <Typography className={localClasses.cellTitle}>
            Fotos da recusa
          </Typography>
          <AnswerGeneralPhotos photos={photos} />
        </div>
      )}
    </React.Fragment>
  );
}

type CancelledAnswerDataProps = {
  status: AnswerStatus;
};

function CancelledAnswerData(props: CancelledAnswerDataProps) {
  const { status } = props;
  const localClasses = formAnswerStyles();
  return (
    <React.Fragment>
      <Divider style={{ width: '100%' }} />
      <div className={localClasses.rowContainer}>
        <Typography
          variant="h3"
          display="flex"
          gap={1}
          alignItems="center"
          marginBottom={0}
          marginTop={2}
        >
          <RemoveCircleRoundedIcon color="error" />
          <span style={{ paddingTop: 2 }}>Interrupção</span>
        </Typography>
      </div>
      <Typography>
        <b>Motivo:</b> {status.cancelData?.cancel || 'Condições impeditivas.'}
      </Typography>
      {!!status.cancelData?.photos?.length ? (
        <AnswerGeneralPhotos photos={status.cancelData.photos} />
      ) : (
        <Typography color={theme.palette.grey[500]} fontStyle="italic">
          Nenhuma foto foi tirada durante a interrupção.
        </Typography>
      )}
    </React.Fragment>
  );
}

type StatusFieldsAnswerProps = {
  steps: Array<FormStep>;
  answer: {
    [key: string]: any;
  };
  users: Array<Coworker>;
  loadingUsers: boolean;
  edits?: Array<any>;
};

function CompletionFieldsAnswer(props: StatusFieldsAnswerProps) {
  const { steps, answer, users, loadingUsers, edits } = props;
  return (
    <React.Fragment>
      <Divider style={{ width: '100%', margin: '10px 0px' }} />
      <div>
        <Typography
          variant="h2"
          marginBottom={1}
          marginTop={1}
          display="flex"
          gap={1}
        >
          <GradingRoundedIcon />
          Formulário de Conclusão
        </Typography>
        <Typography variant="body2">
          As seguintes questões foram respondidas após a conlusão da atividade.
        </Typography>
      </div>
      <RenderSteps
        steps={steps}
        answer={answer}
        coworkers={users}
        loadingCoworkers={loadingUsers}
        edits={edits}
      />
    </React.Fragment>
  );
}

function NonExecutionFieldsAnswer(props: StatusFieldsAnswerProps) {
  const { steps, answer, users, loadingUsers, edits } = props;
  return (
    <React.Fragment>
      <Divider style={{ width: '100%', margin: '10px 0px' }} />
      <div>
        <Typography
          variant="h2"
          marginBottom={1}
          marginTop={1}
          display="flex"
          gap={1}
          fontSize={22}
        >
          <GradingRoundedIcon />
          Formulário de Não-execução
        </Typography>
        <Typography variant="body2" marginBottom={3}>
          As seguintes questões foram respondidas como justificativa para a
          não-execução da atividade.
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <RenderSteps
            steps={steps}
            answer={answer}
            coworkers={users}
            loadingCoworkers={loadingUsers}
            edits={edits}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
