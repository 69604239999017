import Dexie, { Table } from 'dexie';
import { QueryKey } from '../types/voroquery';
import { S3FileFormat } from '../types/utils';
import { VoroMutationStatus } from '../../vorotypes/types/voromutation';

/**
 * Documentation from DexieDB can be found here:
 * https://dexie.org/docs/
 **/

export interface QueryCache {
  key: QueryKey;
  data: any;
  responseStatusCode?: number;
}

export interface MutationCache {
  key: Array<string | number>;
  data: any;
  status: VoroMutationStatus;
  retry: number;
  errorInfo?: any;
}

export interface S3Cache {
  key: string;
  bucketName: string;
  format: S3FileFormat;
  type?: string;
  ext?: string;
  data: string | File | ArrayBuffer | null;
  dateOfLastUse: string;
}

export class VorotechDexie extends Dexie {
  queryCache!: Table<QueryCache>;
  mutationCache!: Table<MutationCache>;
  s3Cache!: Table<S3Cache>;

  constructor() {
    super('userDB');
    this.version(13).stores({
      answers: 'id++,datetime,formAnswer,template_id',
      cache: 'route&,response',
      queryCache: 'key&,data,responseStatusCode',
      cancellations: 'id++,cancellationData',
      edits: 'id++,route,postData',
      misc: 'key&,value',
      mutationCache: 'key&,data,status,retry,errorInfo',
      s3Cache: '[key+bucketName+format]&,type,ext,data,dateOfLastUse'
    });
  }
}

export const db = new VorotechDexie();

export default db;
