import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GPSPosition } from '../../vorotypes/types/utils';

interface answerMetadata {
  answerTimeSeed: number;
  answerInitDate?: string;
  answerInitPosition: GPSPosition;
  nonConformingSubmissionAttempts: number;
}

const initialAnswerMetadata = {
  answerTimeSeed: 42,
  answerInitDate: undefined,
  answerInitPosition: {
    lat: 0,
    long: 0,
    err: 'init position not set'
  },
  nonConformingSubmissionAttempts: 0
} as answerMetadata;

export const answerMetadataSlice = createSlice({
  name: 'answerMetadata',
  initialState: initialAnswerMetadata,
  reducers: {
    setAnswerTimeSeed: (state, action: PayloadAction<number>) => {
      state.answerTimeSeed = action.payload;
    },
    setAnswerInitDate: (state, action: PayloadAction<string>) => {
      state.answerInitDate = action.payload;
    },
    setAnswerInitPosition: (state, action: PayloadAction<GPSPosition>) => {
      state.answerInitPosition = action.payload;
    },
    incrNonConformingAttempts: state => {
      state.nonConformingSubmissionAttempts++;
    },
    resetNonConformingAttempts: state => {
      state.nonConformingSubmissionAttempts = 0;
    },
    resetAnswerMetadata: state => {
      state = initialAnswerMetadata;
    }
  }
});

export const {
  setAnswerTimeSeed,
  setAnswerInitDate,
  setAnswerInitPosition,
  incrNonConformingAttempts,
  resetNonConformingAttempts,
  resetAnswerMetadata
} = answerMetadataSlice.actions;
export default answerMetadataSlice.reducer;
