/* Global imports */
import { Fragment } from 'react';
import { Button, Divider, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
/* Icon imports */
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
/* Local imports */
import fieldUserStyles from '../styles';
import { FilterStateValue } from '../../../../../hooks/useFilter';
/* Type imports */
import { VoroRoleDisplay } from '../../../../../../vorotypes/types/authorization';
import { VORO_ROLE_DISPLAY_NAME } from '../../../../../../vorotypes/internalCodes/authorization';

interface RolesTabProps {
  voroRoles?: Array<VoroRoleDisplay>;
  onClickItem: (voroRole: VoroRoleDisplay) => void;
  filter: FilterStateValue;
}

export default function RolesTab(props: RolesTabProps) {
  const { voroRoles, filter, onClickItem } = props;
  const theme = useTheme();
  const localClasses = fieldUserStyles(theme);
  return !!voroRoles?.length ? (
    <div className={localClasses.filterList}>
      {voroRoles.map(voroRole => (
        <Fragment key={`frag-role-${voroRole.ulid}`}>
          <Button
            id={`tag-voroRole-${voroRole.ulid}`}
            key={`role-${voroRole.ulid}`}
            disableRipple
            fullWidth
            className={clsx(localClasses.buttonCheckBox)}
            onClick={() => onClickItem(voroRole)}
          >
            <Typography color="inherit" fontWeight={500}>
              {
                VORO_ROLE_DISPLAY_NAME[
                  voroRole.ulid as keyof typeof VORO_ROLE_DISPLAY_NAME
                ]
              }
            </Typography>
            {!!filter &&
            filter.value.some((v: any) => v.ulid === voroRole.ulid) ? (
              <CheckBoxRoundedIcon style={{ fontSize: 19 }} />
            ) : (
              <CheckBoxOutlineBlankRoundedIcon style={{ fontSize: 19 }} />
            )}
          </Button>
          <Divider style={{ borderColor: 'rgba(0,0,0,0.05)' }} />
        </Fragment>
      ))}
    </div>
  ) : (
    <Typography fontStyle="italic" sx={{ color: theme.palette.secondary.dark }}>
      Nenhuma cargo encontrado
    </Typography>
  );
}
