import { createTheme } from '@mui/material';

const palette = {
  primary: {
    main: '#0b0b47',
    light: '#3b3b6b',
    dark: '#070731',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#C5CCD8',
    light: '#d5dbe6',
    dark: '#898E97'
  },
  background: {
    paper: '#F0F2F4',
    default: '#E6EEF2'
  },
  common: {
    black: '#000',
    white: '#fff'
  },
  text: {
    primary: '#0b0b47'
  },
  error: {
    main: '#AB4444'
  },
  success: {
    main: '#049153',
    contrastText: 'white'
  },
  warning: {
    main: '#FABD78',
    contrastText: '#0B0B47'
  },
  info: {
    main: '#8051BB'
  }
};

const theme = createTheme({
  spacing: 4,
  shape: {
    borderRadius: 15
  },
  palette: palette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderRadius: 5,
          textTransform: 'unset',
          boxShadow: 'none'
        },
        outlined: {
          fontWeight: 400,
          '&:hover': {
            '@media (hover:none)': {
              border: `1px solid ${palette.primary.main}80`
            }
          }
        },
        outlinedSecondary: {
          color: palette.primary.main,
          borderColor: palette.secondary.main,
          '&:hover': {
            backgroundColor: `${palette.secondary.main}20`,
            '@media (hover:none)': {
              backgroundColor: 'transparent',
              borderColor: palette.secondary.main
            }
          }
        },
        contained: {
          '&:hover': {
            '@media (hover:none)': {
              boxShadow: 'none'
            }
          },
          '&:disabled': {
            backgroundColor: '#CAD1D5'
          }
        },
        containedSecondary: {
          color: palette.primary.main,
          fontWeight: 400,
          '&:hover': {
            color: palette.primary.main,
            backgroundColor: palette.secondary.main,
            '@media (hover:none)': {
              color: palette.primary.main,
              backgroundColor: palette.secondary.main,
              boxShadow: 'none'
            }
          }
        },
        textSecondary: {
          color: palette.primary.light,
          '&:hover': {
            backgroundColor: `${palette.secondary.main}40`,
            '@media (hover:none)': {
              color: palette.primary.light,
              backgroundColor: 'transparent'
            }
          }
        },
        startIcon: {
          marginLeft: 0
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: 0
        },
        h1: {
          fontWeight: 700,
          fontSize: 38
        },
        h2: {
          fontWeight: 700,
          fontSize: 24,
          lineHeight: '24px',
          marginTop: 15
        },
        h3: {
          fontWeight: 700,
          fontSize: 20,
          marginTop: 12
        },
        h4: {
          fontWeight: 600,
          fontSize: 18,
          marginTop: 10
        },
        h5: {
          fontWeight: 700,
          fontSize: 16,
          marginTop: 10
        },
        paragraph: {
          fontSize: 10
        },
        body1: {
          fontSize: 14
        },
        body2: {
          fontSize: 12
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          border: '1px solid',
          boxShadow: '0px 0px 12px 4px rgba(0,0,0,0.1)',
          gap: 8,
          padding: 16,
          alignItems: 'flex-start',
          fontWeight: 500
        },
        icon: {
          margin: 0,
          padding: 0
        },
        message: {
          padding: '1px 0px'
        },
        standardError: ({ theme }) => ({
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
          borderColor: theme.palette.error.dark,
          '& .MuiAlert-icon': {
            color: theme.palette.error.contrastText
          }
        }),
        standardSuccess: ({ theme }) => ({
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
          borderColor: theme.palette.success.dark,
          '& .MuiAlert-icon': {
            color: theme.palette.success.contrastText
          }
        }),
        standardInfo: ({ theme }) => ({
          backgroundColor: theme.palette.info.main,
          color: theme.palette.info.contrastText,
          borderColor: theme.palette.info.dark,
          '& .MuiAlert-icon': {
            color: theme.palette.info.contrastText
          }
        }),
        standardWarning: ({ theme }) => ({
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
          borderColor: theme.palette.warning.dark,
          '& .MuiAlert-icon': {
            color: theme.palette.warning.contrastText
          }
        })
      }
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 4000
      },
      styleOverrides: {
        root: {
          marginTop: 60
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          background: `linear-gradient(90deg, #9999FF 0%, #A580EF 50%, #3D8DA0 100%)`
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 5
        },
        notchedOutline: {
          borderColor: 'rgba(0,0,0,0.3)'
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: palette.primary.main
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 45,
          height: 24,
          padding: 0,
          margin: 0,
          borderRadius: 14
        },
        switchBase: {
          padding: 6,
          color: palette.primary.main,
          '&.Mui-checked+.MuiSwitch-track': {
            opacity: 1
          },
          '&.Mui-checked': {
            color: '#fff'
          }
        },
        thumb: {
          width: 12,
          height: 12
        },
        track: {
          padding: 4,
          backgroundColor: palette.secondary.main,
          opacity: 1
        }
      }
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true
      },
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 5,
          backgroundColor: palette.secondary.light,
          '&:hover': {
            borderRadius: 5,
            backgroundColor: palette.secondary.light
          },
          '&:focus-within': {
            boxShadow: `inset 0 0 0 1px ${palette.secondary.main}`
          }
        },
        input: {
          padding: '12px 16px',
          fontSize: 14,
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    }
  },
  typography: {
    fontFamily: 'Roboto'
  }
});

export default theme;
