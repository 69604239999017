export const COMPANY_ROLE_INTERNAL_CODE = {
  'technician': 1,
  'manager': 2,
  'admin': 3,
  'financial': 4,
  'operator': 5
} as const;

export type CompanyRoleInternalCode = keyof typeof COMPANY_ROLE_INTERNAL_CODE;

export const ACTION_INTERNAL_CODE = {
  'app:login': 1,
  'certification:archive': 2,
  'certification:archive:any': 3,
  'certification:create': 4,
  'certification:list': 5,
  'certification:list:all': 6,
  'certification:read': 7,
  'certification:update': 8,
  'dashboard:login': 9,
  'financial:read': 10,
  'financial:emit': 11,
  'formTemplate:archive': 12,
  'formTemplate:create': 13,
  'formTemplate:update': 14,
  'formTemplate:list:all': 15,
  'formTemplate:list': 16,
  'user:archive': 17,
  'user:archive:all': 18,
  'user:archive:self': 19,
  'user:archive:managed': 20,
  'user:create': 21,
  'user:create:any': 22,
  'user:create:manager:any': 23,
  'user:create:manager': 24,
  'user:certification:add': 25,
  'user:manage': 26,
  'user:management:set:all': 27,
  'user:update:managed': 28,
  'user:update:all': 29,
  'user:list': 30,
  'user:list:managed': 31,
  'user:list:all': 32,
  'user:read': 33,
  'user:read:all': 34,
  'user:read:self': 35,
  'user:read:managed': 36,
  'user:management:set': 37,
  'user:update': 38,
  'user:update:self': 39,
  'user:management:set:self': 40,
  'formAnswer:create': 41,
  'formAnswer:list': 42,
  'formAnswer:list:authored': 43,
  'formAnswer:list:managed': 44,
  'formAnswer:list:participant': 45,
  'formAnswer:read': 46,
  'formAnswer:read:authored': 47,
  'formAnswer:read:managed': 48,
  'formAnswer:read:participant': 49,
  'formAnswer:read:all': 50,
  'formAnswer:edit:authored': 51,
  'formAnswer:edit:all': 52,
  'formAnswer:statistics:read': 53,
  'formAnswer:statistics:read:managed': 54,
  'formAnswer:statistics:read:all': 55,
  'formAnswer:download:all': 56,
  'formAnswer:download:managed': 57,
  'formAnswer:download:authored': 58,
  'formAnswer:download': 59,
  'tutorial:archive': 60,
  'tutorial:create': 61,
  'tutorial:list': 62,
  'tutorial:list:all': 63,
  'tutorial:read': 64,
  'tutorial:read:all': 65,
  'tutorial:update': 66,
  'businessUnit:list': 67,
  'businessUnit:read': 68,
  'businessUnit:create': 69,
  'company:create': 70,
  'company:list': 71,
  'company:read': 72,
  'riss:statistics:read': 73,
  'absencePeriod:list': 74,
  'absencePeriod:list:managed': 75,
  'absencePeriod:read': 76,
  'absencePeriod:read:managed': 77,
  'absencePeriod:create': 78,
  'absencePeriod:create:managed': 79,
  'orphanhoodDismissalRequest:create': 80,
  'orphanhoodDismissalRequest:create:managed': 81,
  'orphanhoodDismissalRequest:list': 82,
  'orphanhoodDismissalRequest:list:managed': 83,
  'orphanhoodDismissalRequest:read': 84,
  'orphanhoodDismissalRequest:read:managed': 85,
  'orphanhoodDismissalRequest:verify': 86,
  'dqsTopic:list': 87,
  'dqsTopic:read': 88,
  'dqsTopic:create': 89
} as const;

export type ActionInternalCode = keyof typeof ACTION_INTERNAL_CODE;

export const VORO_ROLE_DISPLAY_NAME = {
  '01HG911YM6WPDCKY1VY12Y7715': 'Técnico de Campo',
  '01HG911YM6J32AH0J8P88BSJBQ': 'Coordenador',
  '01HG911YM65Y5YM686K25HQNMZ': 'Administrador',
  '01HG911YM6V53M3GS7299VZZRK': 'Financeiro',
  '01HG911YM6FY26RWCP86H2FWD1': 'Operador',
  '01J4A6ZXJJ9XR230RV61PTCYRD': 'Técnico de Segurança do Trabalho',
  '01J4A6ZXJK577ADKS08GXMBHHR': 'Cipeiro'
} as const;
